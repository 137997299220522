<div class="my-preferences">
  <ssi-alerts></ssi-alerts>

  <div class="flex-grid container">
    <form
      (ngSubmit)="saveUserPreferences()"
      [formGroup]="userPreferencesForm"
      *ngIf="userPreferencesForm"
      class="form-grid"
    >
      <div class="form-row" *ngIf="accountSummaryEmailEnabled">
        <div class="form-field">
          <ssi-toggle-switch
            parentNgForm="userPreferencesForm"
            formControlName="account_summary_email"
            toggleText="Daily Email {{ userPreferencesForm.controls.account_summary_email.value ? 'On' : 'Off'}}"
            label="{{ 'DAILY_EMAIL' | translate}}"
          >
          </ssi-toggle-switch>
        </div>
        <div class="form-field orlo-select">
          <label
            class="form-field-label"
            translate="DAILY_EMAIL_TIME"
          ></label>
          <select
            formControlName="account_summary_email_time"
            name="account_summary_email_time"
            [attr.disabled]="!userPreferencesForm.controls.account_summary_email.value ? true : null"
          >
            <option
              *ngIf="!userPreferencesForm.controls.account_summary_email.value"
              [selected]="!userPreferencesForm.controls.account_summary_email.value"
              disabled
            >Turn on your daily email first...</option>
            <option [ngValue]="9">Receive daily emails at 9 am</option>
            <option [ngValue]="10">Receive daily emails at 10 am</option>
            <option [ngValue]="11">Receive daily emails at 11 am</option>
            <option [ngValue]="12">Receive daily emails at 12 pm</option>
            <option [ngValue]="13">Receive daily emails at 1 pm</option>
            <option [ngValue]="14">Receive daily emails at 2 pm</option>
            <option [ngValue]="15">Receive daily emails at 3 pm</option>
            <option [ngValue]="16">Receive daily emails at 4 pm</option>
            <option [ngValue]="17">Receive daily emails at 5 pm</option>
            <option [ngValue]="18">Receive daily emails at 6 pm</option>
            <option [ngValue]="19">Receive daily emails at 7 pm</option>
            <option [ngValue]="20">Receive daily emails at 8 pm</option>
            <option [ngValue]="21">Receive daily emails at 9 pm</option>
            <option [ngValue]="22">Receive daily emails at 10 pm</option>
          </select>
          <i class="ssi ssi-arrow-down-correct"></i>
        </div>
      </div>
      <div class="form-row">
        <div class="form-field">
          <ssi-toggle-switch
            parentNgForm="userPreferencesForm"
            formControlName="outbox_passed_validation_email"
            [saveAsString]="true"
            toggleText="Post Validated Notification {{ userPreferencesForm.controls.outbox_passed_validation_email.value ? 'On' : 'Off'}}"
            label="Post validation notification"
          >
          </ssi-toggle-switch>
        </div>
        <div class="form-field orlo-select">
          <label
            class="form-field-label"
            translate="TIME_ZONE"
          ></label>
          <select
            formControlName="timezone"
            name="timezone"
          >
            <option
              *ngFor="let timezone of serverSettings?.timezones"
              [ngValue]="timezone.name"
            >
              {{timezone.name}}
            </option>

          </select>
          <i class="ssi ssi-arrow-down-correct"></i>
        </div>
      </div>
      <div class="form-row">
        <div class="form-field half">
          <ssi-toggle-switch
            toolTip="When enabled, this feature limits the amount of characters shown by default in Inbox messages. This makes it easier to navigate larger conversations and allows more messages to be viewed at once."
            parentNgForm="userPreferencesForm"
            formControlName="inbox_message_read_more"
            toggleText="Read more {{ userPreferencesForm.controls.inbox_message_read_more.value ? 'enabled' : 'disabled'}}"
            label="Inbox read more"
          >
          </ssi-toggle-switch>
        </div>
        <div class="form-field half">
          <ssi-toggle-switch
            toolTip=""
            parentNgForm="userPreferencesForm"
            formControlName="inbox_unread_count_hidden"
            toggleText="Unread count {{ userPreferencesForm.controls.inbox_unread_count_hidden.value ? 'hidden' : 'visible'}}"
            label="Hide inbox unread count"
          >
          </ssi-toggle-switch>
        </div>
      </div>
      <div class="form-row">
        <div class="form-field">
          <label
            class="form-field-label"
            translate="INBOX_SIGNATURE"
          ></label>
          <textarea
            class="text-area"
            formControlName="inbox_signature"
            name="inbox_signature"
            rows="4"
            placeholder="{{ 'NO_SIGNATURE_SET' | translate }}"
          >
          </textarea>
        </div>
      </div>
      <div class="form-row" *ngIf="hideLiveChatPreferences">
        <div class="form-field half">
          <ssi-toggle-switch
            toolTip="When enabled, an audio prompt will be played when new chats and new messages appear in the live chat inbox."
            parentNgForm="userPreferencesForm"
            formControlName="audio_chat_prompt"
            toggleText="Audio prompt {{ userPreferencesForm.controls.inbox_message_read_more.value ? 'enabled' : 'disabled'}}"
            label="Live chat audio prompt"
          >
          </ssi-toggle-switch>
        </div>
        <div class="form-field half">
          <ssi-toggle-switch
            toolTip="When enabled, this feature displays a visual prompt in the orlo application when new chats and new messages appear in the live chat inbox, and when new chats are assigned to you."
            parentNgForm="userPreferencesForm"
            formControlName="notification_chat_prompt"
            toggleText="Notification prompt {{ userPreferencesForm.controls.inbox_message_read_more.value ? 'enabled' : 'disabled'}}"
            label="Live chat notification prompt"
          >
          </ssi-toggle-switch>
        </div>
      </div>

      <div class="form-submit-fixed">
        <orlo-button
          [text]="'Save changes'"
          [state]="'primary'"
          [type]="'submit'"
          [disabled]="userPreferencesForm.invalid || userPreferencesForm.pristine"
        ></orlo-button>
      </div>
    </form>
  </div>
</div>