import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';

import { MonitoringComponent } from './monitoring.component';
import { MonitoringStreamsModule } from './monitoring-streams/monitoring-streams.module';
import { StreamBuilderModule } from './stream-builder/stream-builder.module';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'auth.monitoring',
          url: '/monitoring',
          component: MonitoringComponent
        }
      ]
    }),
    MonitoringStreamsModule,
    StreamBuilderModule
  ],
  declarations: [MonitoringComponent],
  entryComponents: [MonitoringComponent]
})
export class MonitoringModule {}
