import './widget-swot.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import {
  SWOTCatagory,
  SWOTData
} from '../../services/widget-swot/widget-swot.service';
import { InsightsService, Swot } from '../../../insights.service';
import { Filter } from '../../../reports/view/view-report.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Options } from 'highcharts';
import { DrilldownModalComponent } from '../drilldown-modal/drilldown-modal.component';
import { filtersFieldDefinitions } from '../../constants';
import { HighchartComponent } from '../../../../../../common/components/highchart/highchart.component';

@Component({
  selector: 'ssi-widget-swot',
  templateUrl: './widget-swot.component.html',
  styles: []
})
export class WidgetSwotComponent implements OnInit, OnChanges {
  @Input() widget: Swot;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() loading = false;
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;

  @Output() loaded = new EventEmitter<void>();

  @ViewChild('placeholderModalTemplate') placeholderModalTemplate;

  data: SWOTData[];
  categories: SWOTCatagory[];
  swotConfig: Options = {};
  updateFlag: boolean = false;
  activeCategory = '';

  constructor(
    private insightsService: InsightsService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    if (this.initRender) {
      this.requestData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        !changes.globalFilters.firstChange) ||
      (changes.globalFilters &&
        !changes.globalFilters.previousValue &&
        changes.globalFilters.currentValue.length !== 0 &&
        changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    this.loading = true;

    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data }) => {
        const formattedData = this._formatResponse(data);
        this.swotConfig = this._getSwotConfig(
          formattedData.data,
          formattedData.catagories
        );
        this.loading = false;
        this.loaded.emit();
      });
  }

  private _formatResponse(response: {
    [key: string]: number;
  }): { catagories: SWOTCatagory[]; data: SWOTData[] } {
    const catagories = Object.keys(response).map((key, i) => ({
      name: key,
      id: i.toString()
    }));
    const data = Object.entries(response).map((keyVal) => ({
      name: keyVal[0],
      value: keyVal[1],
      parent: catagories.find((category) => category.name === keyVal[0]).id
    }));
    return { catagories, data };
  }

  private _getSwotConfig(data, categories): any {
    const colours: { color: string; borderColor: string }[] = [
      { color: '#DEE6FF', borderColor: '#B4C8FF' },
      { color: '#BDE8E6', borderColor: '#12ACA4' },
      { color: '#FBE1C0', borderColor: '#F2981F' },
      { color: '#F9B7B7', borderColor: '#EA0000' }
    ];
    categories.forEach((cat, i) =>
      Object.assign(
        cat,
        i > colours.length ? colours[i % colours.length] : colours[i]
      )
    );
    data.forEach((val) => {
      const valParent = categories.find((cat) => cat.id === val.parent);
      val['borderColor'] = valParent ? valParent.borderColor : undefined;
    });
    return {
      chart: {
        backgroundColor: null,
        spacing: [0, 0, 0, 0],
        events: {
          drilldown: (event) => {
            console.log('drilldown event:', event);
          }
        }
      },
      plotOptions: {
        treemap: {
          x: 1,
          animation: {
            duration: 0
          },
          states: {
            hover: {
              enabled: false
            }
          },
          events: {
            click: (event) => {
              console.log('datapoint click:', event);
              const modal = this.modal.open(DrilldownModalComponent, {
                windowClass: 'xxl-modal'
              });
              modal.componentInstance.streamIds = this.streamIds;
              modal.componentInstance.globalFilters = this.globalFilters;
              modal.componentInstance.widgetFilters = [
                {
                  field: filtersFieldDefinitions.Classification.name,
                  [filtersFieldDefinitions.Classification.preferedFilterType]:
                    event.point.name
                }
              ];
            }
          }
        }
      },
      series: [
        {
          type: 'treemap',
          allowDrillToNode: false,
          alternateStartingDirection: true,
          dataLabels: {
            enabled: false
          },
          levels: [
            {
              level: 1,
              levelIsConstant: false,
              dataLabels: {
                enabled: true,
                borderColor: '#000',
                style: {
                  fontSize: '20px',
                  fontWeight: '900',
                  textOutline: '0',
                  textRendering: 'geometricPrecision',
                  fontFamily: 'Lato, Arial, sans-serif',
                  color: '#101525'
                }
              }
            }
          ],
          data: data.concat(categories)
        }
      ],
      loading: false,
      title: {
        text: null
      },
      tooltip: {
        useHTML: true,
        formatter(tooltip) {
          if (this.point && this.point.custom) {
            return `<div class="swot-tooltip">
              <p>Some themes I found:</p>
              <p>${this.point.custom}</p>
            </div>`;
          }
          return tooltip.defaultFormatter.call(this, tooltip);
        },
        shared: true,
        animation: false
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    };
  }
}
