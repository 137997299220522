import './meta-post-options-select.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { OutboxPublisher } from '@ui-resources-angular';
import { groupBy } from '../../../../common/utils';
import { Option } from '../../../../common/components/dropdown-select-2/dropdown-select-2.component';
import { MediaCategory } from '../../../../../../library/ui-resources/modules/publish/services/media-restrictions';

export const metaPostOptions = {
  [MediaCategory.Post]: {
    key: MediaCategory.Post,
    label: 'Post / Carousel',
    icon: 'ssi-ig-carousel'
  },
  [MediaCategory.Reel]: {
    key: MediaCategory.Reel,
    label: 'Reel',
    icon: 'ssi-ig-reels'
  },
  [MediaCategory.Story]: {
    key: MediaCategory.Story,
    label: 'Story',
    icon: 'ssi-ig-stories'
  }
};

@Component({
  selector: 'ssi-meta-post-options-select',
  templateUrl: './meta-post-options-select.component.html',
  styles: []
})
export class MetaPostOptionsSelectComponent implements OnInit, OnChanges {
  @Input() composer: OutboxPublisher;
  @Input() mediaCategory: MediaCategory;
  @Input() hideStoryOption = false;

  options: { [key: string]: Option } = metaPostOptions;
  optionsIterable: Option[] = Object.values(this.options);
  selectedOption: Option;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['mediaCategory'] && changes['mediaCategory'].currentValue) {
      this.selectedOption = this.options[this.mediaCategory];
    }

    if (changes['hideStoryOption']) {
      this.optionsIterable = this.hideStoryOption
        ? Object.values(this.options).filter(
            (option) => option.key !== MediaCategory.Story
          )
        : Object.values(this.options);
    }
  }

  ngOnInit() {
    this.selectedOption = this.options[this.composer.mediaCategory];
  }

  selectedOptionChanged(selectedOption: Option): void {
    this.selectedOption = selectedOption;
    this.composer.mediaCategory = selectedOption.key;

    // TODO: warning before removing files, and delete file from filestack too?
    this.composer.files.forEach((file) => {
      this.composer.removeFile(file);
    });
  }
}
