<div class="stream-builder-nav">
  <div class="back">
    <a uiSref="auth.monitoring.monitoringStreamsNew"><i class="ssi ssi-line-arrow-small-up left"></i> Back</a>
    <span *ngIf="streamQuery.id" (click)="deleteMenu.hide(); deleteMenu.show($event, {streamQuery: streamQuery});" class="stream-builder-nav-action">
      <i class="ssi ssi-delete"></i><span>Delete stream</span>
    </span>
  </div>
  <div class="nav-list">
    <h3 class="active">Description</h3>
    <div class="line active"></div>
    <h3>Build Query</h3>
    <div class="line"></div>
    <h3>Sources & Save</h3>
  </div>
  <div [class.disabled]="!name.valid || !description.valid" class="next">
    <a
      (click)="saveStep()"
      [uiSref]="name.valid && description.valid ? '^.query' : '.'"
    >Next <i class="ssi ssi-line-arrow-small-up right"></i></a>
  </div>
</div>
<div class="description-step">
  <div class="step-content">
    <div class="page-description">
      <div class="text">
        <h2>Building your stream</h2>
        <p>Build streams to listen across a variety of external media channels, such as blogs, forums and review sites! Remember to name them clearly and include a concise description to make them easy to identify when managing your streams.</p>
      </div>
      <img
        src="https://www.orlo.app/app/web/production/85712b36dc7279a983440a3ee8c4da8c.png"
        alt="person wearing headphones"
      >
    </div>
    <div class="stream-details">
      <div class="input-container">
        <label translate="STREAM_NAME"></label>
        <input
          type="text"
          #name="ngModel"
          class="orlo-text-input"
          [placeholder]="'NAME_YOUR_STREAM' | translate"
          [(ngModel)]="streamQuery.name"
          name="name"
          maxlength="200"
          required
        />
        <span
          class="input-counter"
          [class.has-value]="streamQuery.name.length > 0"
          [class.error]="streamQuery.name.length > 200"
        >{{200 - streamQuery.name.length}}</span>
      </div>
      <div class="input-container">
        <label translate="DESCRIPTION"></label>
        <input
          type="text"
          #description="ngModel"
          class="orlo-text-input"
          [placeholder]="'What this is for...'"
          [(ngModel)]="streamQuery.description"
          name="description"
          maxlength="85"
        />
        <span
          class="input-counter"
          [class.has-value]="streamQuery.description.length > 0"
          [class.error]="streamQuery.description.length > 85"
        >{{85 - streamQuery.description.length}}</span>
      </div>
    </div>
  </div>
</div>

<ssi-context-menu #deleteMenu
                  (ssiOffClick)="deleteMenu.hide();">
  <ng-template let-ctx>
    <ssi-confirm-action title="Are you sure you want to delete?"
                        dismissText="Cancel"
                        confirmText="Delete"
                        dismissTextColour="#43537f"
                        confirmTextColour="#F88C68"
                        (onDismiss)="deleteMenu.hide();"
                        (onConfirm)="deleteMenu.hide(); deleteStream(ctx.streamQuery);"></ssi-confirm-action>
  </ng-template>
</ssi-context-menu>