import angular from 'angular';
import { default as uiRouter, UrlService } from '@uirouter/angularjs';
import { apiAuthHeader } from '@ui-resources-angular';
import { API_ENDPOINT } from '../../angular/environment';

export default angular
  .module('common.config', [uiRouter, 'ui.router.upgrade'])
  .config(($urlServiceProvider: UrlService) => {
    'ngInject';
    $urlServiceProvider.deferIntercept();
  })
  .config(($urlRouterProvider, $urlMatcherFactoryProvider) => {
    'ngInject';
    $urlRouterProvider.otherwise(($injector) => {
      const auth = $injector.get('authService');
      const redirect = $injector.get('redirect');

      if (auth.isAuthenticated()) {
        redirect.login();
      } else {
        redirect.logout();
      }
    });

    $urlMatcherFactoryProvider.strictMode(false); // allow trailing slashes in urls
  })
  .config(($locationProvider) => {
    'ngInject';
    $locationProvider.hashPrefix('');
  });
