import './widget-chart-bar.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Options } from 'highcharts';
import Highcharts from 'highcharts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import rounded from '../../plugins/rounded-corner.js';

import { BarChartData } from '../../services/widget-chart-bar/widget-chart-bar.service';
import {
  InsightsService,
  BarChart,
  WidgetFilter
} from '../../../insights.service';
import { Filter } from '../../../reports/view/view-report.component';

import { DrilldownModalComponent } from '../drilldown-modal/drilldown-modal.component';
import {
  FieldName,
  filtersFieldDefinitions,
  formatFilterTypeData
} from '../../constants/filters-field-definitions';
import { HighchartComponent } from '../../../../../../common/components/highchart/highchart.component';
import moment from 'moment';
import {
  findSentimentConst,
  sentimentsIterable,
  AgeOptions,
  findAgeConst
} from '../../../../../../common/constants';

rounded(Highcharts);

@Component({
  selector: 'ssi-widget-chart-bar',
  templateUrl: './widget-chart-bar.component.html',
  styles: []
})
export class WidgetChartBarComponent implements OnInit, OnChanges {
  @Input() widget: BarChart;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;

  @Output() loaded = new EventEmitter<void>();
  @Output() onApplyFilters = new EventEmitter();

  @ViewChild(HighchartComponent) highchart: HighchartComponent;

  barConfig: Options = {};
  data;
  categories: string[];
  noResults: boolean = false;

  constructor(
    private insightsService: InsightsService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    if (this.initRender) {
      this.requestData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        !changes.globalFilters.firstChange) ||
      (changes.globalFilters &&
        !changes.globalFilters.previousValue &&
        changes.globalFilters.currentValue.length !== 0 &&
        changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data }) => {
        // console.log('data: ', data);
        if (data.length === 0) {
          this.loaded.emit();
          this.noResults = true;
          return;
        }
        this.data = this.insightsService.relabelDataModelForUi(
          this.widget,
          data
        );
        // console.log('relabelled data: ', this.data);
        const formattedData = this._formatResponse(this.data, this.widget);
        // console.log('formattedData: ', formattedData);
        if (
          formattedData.series.length === 0 &&
          formattedData.categories.length > 0
        ) {
          const noData = [];
          for (const category of formattedData.categories) {
            noData.push(0);
          }
          formattedData.series.push({ name: 'No Results', data: noData });
        }
        this.barConfig = this._getChartConfig(
          formattedData.series,
          formattedData.categories,
          this.widget
        );
        // Adding 'rerender' method to highcharts component since refresh/updateFlag mechanism wasn't working in some cases (e.g. if some of the values provided to highcharts don't change?)
        // Seems like a highcharts bug or bug in the highcharts wrapper component for Angular we're using?
        // See CT-1940 - issue #4 from the ticket description, or CT-1943
        // TODO: do we need to replace all 'updateFlag' implementations with this one?
        this.highchart.rerender();
        this.loaded.emit();
      });
  }

  private _formatResponse(response, widgetParams) {
    const categories = Object.keys(response);
    let formattedCatagories;
    let seriesData = [];

    if (widgetParams.break_down_by) {
      const breakDownBy_keys = [];
      Object.values(response).forEach((v) => {
        Object.keys(v).forEach((k) => {
          if (breakDownBy_keys.indexOf(k) === -1) {
            breakDownBy_keys.push(k);
          }
        });
      });

      breakDownBy_keys.sort((a: string, b: string) =>
        a < b ? -1 : a > b ? 1 : 0
      );

      if (widgetParams.break_down_by.field === FieldName.Sentiment) {
        breakDownBy_keys.sort(this.sentimentsSort);
      }

      if (widgetParams.break_down_by.field === FieldName.Age) {
        breakDownBy_keys.sort(this.ageSort);
      }

      const series = breakDownBy_keys.map((breakDownBy_key) => {
        return {
          name: breakDownBy_key,
          data: categories.map((c) => {
            return response[c][breakDownBy_key]
              ? response[c][breakDownBy_key]
              : 0;
          })
        } as BarChartData;
      });

      if (
        widgetParams.x_axis &&
        widgetParams.x_axis.field === FieldName.CreatedAt
      ) {
        formattedCatagories = Object.keys(response).map((key) => {
          const momDate = moment(key);
          const dateFormat = momDate.hour() ? 'DD/MM/YYYYTHH' : 'DD/MM/YYYY';
          return momDate.format(dateFormat);
        });
      }

      return {
        categories: formattedCatagories ? formattedCatagories : categories,
        series
      };
    }

    if (
      widgetParams.x_axis &&
      widgetParams.x_axis.field === FieldName.CreatedAt
    ) {
      formattedCatagories = Object.keys(response).map((key) => {
        const momDate = moment(key);
        const dateFormat = momDate.hour() ? 'DD/MM/YYYYTHH' : 'DD/MM/YYYY';
        return momDate.format(dateFormat);
      });
    }

    if (
      widgetParams.x_axis &&
      widgetParams.x_axis.field === FieldName.Sentiment
    ) {
      categories.sort(this.sentimentsSort);
      seriesData = categories.map((c, i) => {
        return {
          name: c,
          y: response[c],
          color: findSentimentConst('key3', c).color2,
          borderColor: 'transparent'
        };
      });
    } else {
      seriesData = Object.values(response);
    }

    return {
      categories: formattedCatagories ? formattedCatagories : categories,
      series: [
        {
          name: widgetParams.x_axis && widgetParams.x_axis.field,
          data: seriesData
        } as BarChartData
      ]
    };
  }

  sentimentsSort(a: string, b: string) {
    const aConst = findSentimentConst('key3', a);
    const bConst = findSentimentConst('key3', b);
    return bConst.numericKey - aConst.numericKey;
  }

  ageSort(a: string, b: string) {
    const aConst = findAgeConst('label', a);
    const bConst = findAgeConst('label', b);
    return +aConst.id - +bConst.id;
  }

  private _addRadiusToColumns(series: BarChartData[]) {
    if (!series.length) {
      return;
    }

    const radiusConfig = {
      borderRadiusTopLeft: '5px',
      borderRadiusTopRight: '5px'
    };
    if (series) {
      Object.assign(series[0], radiusConfig);
    }
  }

  private _getChartConfig(
    series: BarChartData[],
    categories: string[],
    widget: BarChart
  ): any {
    this._addRadiusToColumns(series);

    let colours;
    if (
      widget.break_down_by &&
      widget.break_down_by.field === FieldName.Sentiment &&
      series[0].name !== 'No Results'
    ) {
      colours = series.map((s) => findSentimentConst('key3', s.name).color2);
    } else {
      // this gets ignored for x_axis field, color is taken from seriesData.color instead
      colours = [
        '#425DEC',
        '#D41D68',
        '#F0B427',
        '#89A4EA',
        '#00bde9',
        '#98efff',
        '#d4e0fa',
        '#ffe6e0',
        '#ff865e'
      ];
    }

    Highcharts.setOptions({
      colors: colours
    });
    return {
      chart: {
        type: 'column',
        spacing: [50, 0, 0, 0],
        marginRight: 10
      },
      title: {
        text: null
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      loading: false,
      xAxis: {
        categories,
        max: categories.length - 1,
        title: {
          text: null,
          style: {
            color: '#101525',
            fontSize: '12px',
            fontWeight: '900'
          },
          offset: 62
        },
        labels: {
          style: {
            color: '#101525',
            fontSize: '10px',
            fontWeight: '700'
          },
          y: 30,
          x: 0,
          align: 'center'
        },
        lineWidth: 0
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null,
          style: {
            color: '#101525',
            fontSize: '12px',
            fontWeight: '900'
          },
          offset: 55
        },
        tickAmount: 8,
        labels: {
          style: {
            color: '#101525',
            fontSize: '10px',
            fontWeight: '700'
          },
          x: -18
        },
        stackLabels: {
          enabled: false
        },
        min: 0,
        alternateGridColor: '#F8F9FD'
      },
      legend: {
        align: 'left',
        useHTML: true,
        x: 18,
        verticalAlign: 'top',
        y: -55,
        symbolHeight: 4,
        symbolWidth: 20,
        squareSymbol: false,
        symbolRadius: 2,
        floating: true,
        symbolPadding: 10,
        itemDistance: 30,
        itemStyle: {
          fontSize: '10px',
          fontWeight: '900',
          color: '#101525',
          fontFamily: 'Lato, Arial, sans-serif',
          textRendering: 'geometricPercision',
          lineHeight: '16px'
        }
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          borderColor: null,
          dataLabels: {
            enabled: false
          },
          lineWidth: 2,
          events: {
            click: (event) => {
              console.log('datapoint click:', event);
              const modal = this.modal.open(DrilldownModalComponent, {
                windowClass: 'xxl-modal'
              });

              const filterType =
                filtersFieldDefinitions[this.widget.x_axis.field]
                  .preferedFilterType;
              const breakdownFilterType =
                this.widget.break_down_by &&
                filtersFieldDefinitions[this.widget.break_down_by.field]
                  .preferedFilterType;
              const selectedFilters = [];
              const rangeFilterDates = {
                pointClicked: event.point.category,
                allPoints: categories
              };

              console.log('RANGE', rangeFilterDates);

              selectedFilters.push({
                field: this.widget.x_axis.field,
                [filterType]: formatFilterTypeData(
                  this.widget.x_axis.field === FieldName.CreatedAt
                    ? rangeFilterDates
                    : event.point.category,
                  filterType
                )
              } as WidgetFilter);

              modal.componentInstance.streamIds = this.streamIds;
              modal.componentInstance.displayApplyFilters = true;
              modal.componentInstance.globalFilters = this.globalFilters;
              modal.componentInstance.widgetFilters = this.widget.filters;

              const breakdownFilter = breakdownFilterType && {
                field: breakdownFilterType && this.widget.break_down_by.field,
                [breakdownFilterType]: formatFilterTypeData(
                  event.point.series.name,
                  breakdownFilterType
                )
              };
              if (breakdownFilter) {
                // if(breakdownFilter.field === "Created At"){
                //   console.log("Called");
                //   modal.componentInstance.globalFilters = this.globalFilters.filter(({field}) => field !== "Created At");
                //   modal.componentInstance.widgetFilters = this.widget.filters.filter(({field}) => field !== "Created At");
                // }
                selectedFilters.push(breakdownFilter as WidgetFilter);
              }
              modal.componentInstance.selectedFilters = selectedFilters;
              console.log(this.widget);
              console.log(selectedFilters);
              modal.componentInstance.onApplyFilters.subscribe((filters) => {
                this.onApplyFilters.emit(filters);
              });
            }
          }
        }
      },
      series
    };
  }
}
