export function joinUrl(baseUrl, url) {
  if (/^(?:[a-z]+:)?\/\//i.test(url)) {
    return url;
  }
  const joined = [baseUrl, url].join('/');
  const normalize = function (str) {
    return str
      .replace(/[\/]+/g, '/')
      .replace(/\/\?/g, '?')
      .replace(/\/\#/g, '#')
      .replace(/\:\//g, '://');
  };
  return normalize(joined);
}

export function getFullUrlPath(location) {
  const isHttps = location.protocol === 'https:';
  return (
    location.protocol +
    '//' +
    location.hostname +
    ':' +
    (location.port || (isHttps ? '443' : '80')) +
    (/^\//.test(location.pathname)
      ? location.pathname
      : '/' + location.pathname)
  );
}

export function parseQueryString(str) {
  const obj = {};
  let key;
  let value;

  (str || '').split('&').forEach((keyValue) => {
    if (keyValue) {
      value = keyValue.split('=');
      key = decodeURIComponent(value[0]);
      obj[key] =
        typeof value[1] !== 'undefined' ? decodeURIComponent(value[1]) : true;
    }
  });
  return obj;
}

export function decodeBase64(str) {
  let buffer;
  if (typeof module !== 'undefined' && module.exports) {
    try {
      buffer = require('buffer').Buffer;
    } catch (err) {
      // noop
    }
  }

  const fromCharCode = String.fromCharCode;

  const re_btou = new RegExp(
    [
      '[\xC0-\xDF][\x80-\xBF]',
      '[\xE0-\xEF][\x80-\xBF]{2}',
      '[\xF0-\xF7][\x80-\xBF]{3}'
    ].join('|'),
    'g'
  );

  const cb_btou = function (cccc) {
    switch (cccc.length) {
      case 4:
        const cp =
          ((0x07 & cccc.charCodeAt(0)) << 18) |
          ((0x3f & cccc.charCodeAt(1)) << 12) |
          ((0x3f & cccc.charCodeAt(2)) << 6) |
          (0x3f & cccc.charCodeAt(3));
        const offset = cp - 0x10000;
        return (
          fromCharCode((offset >>> 10) + 0xd800) +
          fromCharCode((offset & 0x3ff) + 0xdc00)
        );
      case 3:
        return fromCharCode(
          ((0x0f & cccc.charCodeAt(0)) << 12) |
            ((0x3f & cccc.charCodeAt(1)) << 6) |
            (0x3f & cccc.charCodeAt(2))
        );
      default:
        return fromCharCode(
          ((0x1f & cccc.charCodeAt(0)) << 6) | (0x3f & cccc.charCodeAt(1))
        );
    }
  };

  const btou = function (b) {
    return b.replace(re_btou, cb_btou);
  };

  const _decode = buffer
    ? function (a) {
        return (a.constructor === buffer.constructor
          ? a
          : new buffer(a, 'base64')
        ).toString();
      }
    : function (a) {
        return btou(atob(a));
      };

  return _decode(
    String(str)
      .replace(/[-_]/g, function (m0) {
        return m0 === '-' ? '+' : '/';
      })
      .replace(/[^A-Za-z0-9\+\/]/g, '')
  );
}
