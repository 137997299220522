<div class="report-filter-bar">
  <div class="report-filter-bar-container">
    <div
      class="report-filter-bar-row"
      [ngClass]="{'report-filter-bar-row-expanded': filtersExpanded}"
    >
      <ul
        #filterItems
        class="report-filter-items"
        [ngClass]="{'report-filter-items-expanded': filtersExpanded}"
      >
        <li class="report-filter-button report-filter-item" *ngIf="allAccountsSelected">
          <span class="report-filter-item-label">All Accounts</span>
          <i (click)="deleteFilterSet('accounts')" class="ssi ssi-delete-close report-filter-item-action"></i>
        </li>
        <li class="report-filter-button report-filter-item" *ngIf="allStreamsSelected">
          <span class="report-filter-item-label">All Streams</span>
          <i (click)="deleteFilterSet('savedStreams')" class="ssi ssi-delete-close report-filter-item-action"></i>
        </li>
        <li
          *ngFor="let filter of activeFilters | filter: {key: 'accounts'} : allAccountsSelected : true | filter: {key: 'savedStreams'} : allStreamsSelected : true; let i = index"
          class="report-filter-button report-filter-item"
          [ngClass]="{
            'report-filter-item-disabled': filter.disabled,
            'report-filter-item-stream': filter.type === 'stream',
            'report-filter-item-warning': filter.all === 'deleted' || filter.all === 'permission'
          }"
          (click)="onFiltersChange.emit(activeFilters);"
        >
          <i *ngIf="filter.type === 'stream'" class="ssi ssi-orlo-insights"></i>
          <span class="report-filter-item-label">{{filter.label}}</span>
          <i (click)="deleteFilterItem(i, filter)" class="ssi ssi-delete-close report-filter-item-action"></i>
        </li>
      </ul>
      <div class="report-filter-actions">
        <button
          *ngIf="filtersWrapped && !filtersExpanded"
          class="report-filter-button"
          (click)="filtersExpanded = true"
        >
          <span class="report-filter-item-label">
            {{collapsedFiltersAmount}} More Filter{{collapsedFiltersAmount === 1 ? '' : 's'}}
          </span>
          <i class="ssi ssi-arrow-down-new report-filter-item-action"></i>
        </button>
        <button class="report-filter-button report-filter-action" (click)="deleteAllFilterItems()">
          <i class="ssi ssi-delete-close report-filter-item-action"></i>
          <span class="report-filter-action-text">
            Clear filters
          </span>
        </button>
      </div>
      <div
        *ngIf="filtersExpanded"
        class="report-filter-bar-toggle"
        (click)="filtersExpanded = false"
      >
        <i class="ssi ssi-arrow-down-new"></i>
      </div>
    </div>
  </div>
</div>
