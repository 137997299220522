<div class="meta-post-options-select-container">
  <ssi-dropdown-select-2 size="md"
                         name="options"
                         [required]="true"
                         [multiple]="false"
                         [filterable]="false"
                         [options]="optionsIterable"
                         [(ngModel)]="selectedOption"
                         (ngModelChange)="selectedOptionChanged($event)">

    <ng-template ssiTemplateSelector="headButtonLabel"
                 let-toggle="toggle"
                 let-expanded="expanded">
      <div class="custom-head-button-label">
        <i class="ssi {{selectedOption.icon}}"></i>
        <span>{{selectedOption.label}}</span>
      </div>
    </ng-template>
  </ssi-dropdown-select-2>
</div>