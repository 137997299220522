import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import OAuth2 from './oauth2';
import Popup from './popup';
import { Auth, apiAuthHeader } from '@ui-resources-angular';
import { API_ENDPOINT } from '../../../../angular/environment';
import {
  routes,
  customerSupportWidgetNamespace,
  symbols
} from '../../../../../library/constants/live-chat';

declare var window: any;

@Injectable({ providedIn: 'root' })
// @Injectable()
export class AuthService {
  // most of the code below ported from: https://github.com/sahat/satellizer
  auth: Auth;
  config: { [key: string]: any };
  prefixedTokenName: string;

  constructor(protected http: HttpClient) {
    this.config = this.getConfig();

    const { tokenName, tokenPrefix } = this.config;

    this.prefixedTokenName = tokenPrefix
      ? [tokenPrefix, tokenName].join('_')
      : tokenName;

    this.setJWT();
  }

  isAuthenticated(checkCookies = false): boolean {
    // console.log('isAuthenticated, checkCookies: ', checkCookies);
    if (checkCookies) {
      const cookie = document.cookie
        .split(';')
        .filter((c) => c.includes(this.prefixedTokenName))[0];

      if (cookie && localStorage.getItem(this.prefixedTokenName) === null) {
        localStorage.setItem(
          this.prefixedTokenName,
          cookie.trim().split('=')[1]
        );
      }
    }

    const token = this.storageGet(this.prefixedTokenName);
    if (!token) {
      return false; // Fail: No token at all
    }

    if (token.split('.').length === 3) {
      // Token with a valid JWT format XXX.YYY.ZZZ
      try {
        // Could be a valid JWT or an access token with the same format
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const exp = JSON.parse(window.atob(base64)).exp;
        if (typeof exp === 'number') {
          // JWT with an optonal expiration claims
          return Math.round(new Date().getTime() / 1000) < exp;
        }
      } catch (e) {
        return true; // Pass: Non-JWT token that looks like JWT
      }
    }

    return true; // Pass: All other tokens
  }

  authenticate(name: string, userData?: any): Promise<any> {
    // console.log('authenticate.....');
    return new Promise((resolve, reject) => {
      const provider = this.config.providers[name];

      const oauth = new OAuth2(
        this.http,
        this.config,
        this.storageGet,
        this.storageSet,
        new Popup()
      );

      return oauth
        .init(provider, userData)
        .then((response) => {
          if (provider.url) {
            this.setToken(response);
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  clearJWT() {
    if (!window[customerSupportWidgetNamespace]) {
      window[customerSupportWidgetNamespace] = {};
    }
    window[customerSupportWidgetNamespace][symbols.widgetNamespaceForUser] = {};
  }

  setJWT() {
    if (!this.isAuthenticated(true)) {
      return;
    }

    const opts: any = {};
    opts.headers = new HttpHeaders();
    opts.headers = opts.headers.append(
      apiAuthHeader,
      `${this.config.tokenType} ${this.getToken()}`
    );

    this.http
      .get(`${API_ENDPOINT}/${routes.jwt}`, opts)
      .pipe(
        map((response: any) => {
          // console.log('get JWT response: ', response);
          return response;
        })
      )
      .toPromise()
      .then((response) => {
        const jwt = response && response.jwt;
        if (jwt && jwt.length) {
          if (!window[customerSupportWidgetNamespace]) {
            window[customerSupportWidgetNamespace] = {};
          }

          window[customerSupportWidgetNamespace][
            symbols.widgetNamespaceForUser
          ] = jwt;
        }
      })
      .catch((error) => {
        console.error('get JWT error: ', error);
      });
  }

  getToken(): string {
    return this.storageGet(this.prefixedTokenName);
  }

  setToken(response): void {
    // console.log('setToken: ', response);
    const tokenRoot = this.config.tokenRoot;
    const tokenName = this.config.tokenName;
    const accessToken =
      response && (response.access_token || response.auth_token);

    let token;
    if (accessToken) {
      if (accessToken.data) {
        response = accessToken;
      } else if (typeof accessToken === 'string') {
        token = accessToken;
      }
    }

    if (!token && response) {
      const tokenRootData =
        tokenRoot && tokenRoot.split('.').reduce((o, x) => o[x], response.data);
      token = tokenRootData
        ? tokenRootData[tokenName]
        : response.data && response.data[tokenName];
    }

    if (token) {
      this.storageSet(this.prefixedTokenName, token);
    } else {
      console.error('No token found: ', token);
    }
  }

  removeToken(): void {
    this.storageRemove(this.prefixedTokenName);
  }

  logout(): void {
    this.removeToken();
  }

  // storage
  private memoryStore: any = {};
  storageGet(key: string): string {
    try {
      return window[this.config.storageType].getItem(key);
    } catch (e) {
      return this.memoryStore[key];
    }
  }
  storageSet(key: string, value: string): void {
    try {
      window[this.config.storageType].setItem(key, value);
    } catch (e) {
      this.memoryStore[key] = value;
    }
  }
  storageRemove(key: string): void {
    try {
      window[this.config.storageType].removeItem(key);
    } catch (e) {
      delete this.memoryStore[key];
    }
  }
  // storage end

  getConfig() {
    return {
      baseUrl: API_ENDPOINT,
      loginUrl: '/user/login',
      signupUrl: '/auth/signup',
      unlinkUrl: '/auth/unlink/',
      tokenName: 'auth_token',
      tokenPrefix: 'orlo',
      tokenHeader: apiAuthHeader,
      tokenType: 'Bearer',
      storageType: 'localStorage',
      tokenRoot: null,
      withCredentials: false,
      providers: {
        facebook: {
          name: 'facebook',
          url: '/auth/facebook',
          authorizationEndpoint: 'https://www.facebook.com/v2.5/dialog/oauth',
          redirectUri: 'http://localhost:7000/',
          requiredUrlParams: ['display', 'scope'],
          scope: ['email'],
          scopeDelimiter: ',',
          display: 'popup',
          oauthType: '2.0',
          popupOptions: { width: 580, height: 400 }
        },
        google: {
          name: 'google',
          url: '/user/login',
          authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
          redirectUri: 'https://www.orlo.app',
          requiredUrlParams: ['scope'],
          optionalUrlParams: ['display', 'state', 'prompt'],
          scope: ['email'],
          scopePrefix: 'openid',
          scopeDelimiter: ' ',
          display: 'popup',
          oauthType: '2.0',
          popupOptions: { width: 452, height: 633 },
          clientId: '368857935553.apps.googleusercontent.com',
          prompt: 'select_account'
        },
        github: {
          name: 'github',
          url: '/auth/github',
          authorizationEndpoint: 'https://github.com/login/oauth/authorize',
          redirectUri: 'http://localhost:7000',
          optionalUrlParams: ['scope'],
          scope: ['user:email'],
          scopeDelimiter: ' ',
          oauthType: '2.0',
          popupOptions: { width: 1020, height: 618 }
        },
        instagram: {
          name: 'instagram',
          url: '/auth/instagram',
          authorizationEndpoint: 'https://api.instagram.com/oauth/authorize',
          redirectUri: 'http://localhost:7000',
          requiredUrlParams: ['scope'],
          scope: ['basic'],
          scopeDelimiter: '+',
          oauthType: '2.0'
        },
        linkedin: {
          name: 'linkedin',
          url: '/auth/linkedin',
          authorizationEndpoint:
            'https://www.linkedin.com/uas/oauth2/authorization',
          redirectUri: 'http://localhost:7000',
          requiredUrlParams: ['state'],
          scope: ['r_emailaddress'],
          scopeDelimiter: ' ',
          state: 'STATE',
          oauthType: '2.0',
          popupOptions: { width: 527, height: 582 }
        },
        twitter: {
          name: 'twitter',
          url: '/auth/twitter',
          authorizationEndpoint: 'https://api.twitter.com/oauth/authenticate',
          redirectUri: 'http://localhost:7000',
          oauthType: '1.0', // https://github.com/sahat/satellizer/blob/master/src/oauth.ts
          popupOptions: { width: 495, height: 645 }
        },
        twitch: {
          name: 'twitch',
          url: '/auth/twitch',
          authorizationEndpoint:
            'https://api.twitch.tv/kraken/oauth2/authorize',
          redirectUri: 'http://localhost:7000',
          requiredUrlParams: ['scope'],
          scope: ['user_read'],
          scopeDelimiter: ' ',
          display: 'popup',
          oauthType: '2.0',
          popupOptions: { width: 500, height: 560 }
        },
        live: {
          name: 'live',
          url: '/auth/live',
          authorizationEndpoint: 'https://login.live.com/oauth20_authorize.srf',
          redirectUri: 'http://localhost:7000',
          requiredUrlParams: ['display', 'scope'],
          scope: ['wl.emails'],
          scopeDelimiter: ' ',
          display: 'popup',
          oauthType: '2.0',
          popupOptions: { width: 500, height: 560 }
        },
        yahoo: {
          name: 'yahoo',
          url: '/auth/yahoo',
          authorizationEndpoint:
            'https://api.login.yahoo.com/oauth2/request_auth',
          redirectUri: 'http://localhost:7000',
          scope: [],
          scopeDelimiter: ',',
          oauthType: '2.0',
          popupOptions: { width: 559, height: 519 }
        },
        bitbucket: {
          name: 'bitbucket',
          url: '/auth/bitbucket',
          authorizationEndpoint: 'https://bitbucket.org/site/oauth2/authorize',
          redirectUri: 'http://localhost:7000/',
          requiredUrlParams: ['scope'],
          scope: ['email'],
          scopeDelimiter: ' ',
          oauthType: '2.0',
          popupOptions: { width: 1028, height: 529 }
        },
        spotify: {
          name: 'spotify',
          url: '/auth/spotify',
          authorizationEndpoint: 'https://accounts.spotify.com/authorize',
          redirectUri: 'http://localhost:7000',
          optionalUrlParams: ['state'],
          requiredUrlParams: ['scope'],
          scope: ['user-read-email'],
          scopePrefix: '',
          scopeDelimiter: ',',
          oauthType: '2.0',
          popupOptions: { width: 500, height: 530 }
        }
      }
    };
  }
}
