import { OutboxMessageClass, OutboxMessageType } from '@ui-resources-angular';
import { mapToIterable } from '../utils';

export interface MessageType {
  key: OutboxMessageType;
  label: string;
  class: OutboxMessageClass;
}

export type MessageTypes = { [type in OutboxMessageType]: MessageType };

export const messageTypes: MessageTypes = {
  // post
  // ...
  [OutboxMessageType.StatusUpdate]: {
    key: OutboxMessageType.StatusUpdate,
    label: 'Status update',
    class: OutboxMessageClass.Post
  },
  [OutboxMessageType.Picture]: {
    key: OutboxMessageType.Picture,
    label: 'Picture',
    class: OutboxMessageClass.Post
  },
  [OutboxMessageType.Album]: {
    key: OutboxMessageType.Album,
    label: 'Album',
    class: OutboxMessageClass.Post
  },
  [OutboxMessageType.Video]: {
    key: OutboxMessageType.Video,
    label: 'Video',
    class: OutboxMessageClass.Post
  },
  [OutboxMessageType.MultiImage]: {
    key: OutboxMessageType.MultiImage,
    label: 'Multiple images',
    class: OutboxMessageClass.Post
  },
  [OutboxMessageType.Share]: {
    key: OutboxMessageType.Share,
    label: 'Share',
    class: OutboxMessageClass.Post
  },
  [OutboxMessageType.InstagramStory]: {
    key: OutboxMessageType.InstagramStory,
    label: 'Instagram Story',
    class: OutboxMessageClass.Post
  },
  [OutboxMessageType.InstagramReel]: {
    key: OutboxMessageType.InstagramReel,
    label: 'Instagram Reel',
    class: OutboxMessageClass.Post
  },
  [OutboxMessageType.FacebookReel]: {
    key: OutboxMessageType.FacebookReel,
    label: 'Facebook Reel',
    class: OutboxMessageClass.Post
  },
  // reply
  // ...
  [OutboxMessageType.Reply]: {
    key: OutboxMessageType.Reply,
    label: 'Reply',
    class: OutboxMessageClass.Reply
  },
  [OutboxMessageType.ReplyPicture]: {
    key: OutboxMessageType.ReplyPicture,
    label: 'Reply with picture',
    class: OutboxMessageClass.Reply
  },
  [OutboxMessageType.ReplyAlbum]: {
    key: OutboxMessageType.ReplyAlbum,
    label: 'Reply with album',
    class: OutboxMessageClass.Reply
  },
  [OutboxMessageType.ReplyVideo]: {
    key: OutboxMessageType.ReplyVideo,
    label: 'Reply with video',
    class: OutboxMessageClass.Reply
  },
  [OutboxMessageType.AutoComment]: {
    key: OutboxMessageType.AutoComment,
    label: 'Scheduled comment',
    class: OutboxMessageClass.Reply
  },
  // private message
  // ...
  [OutboxMessageType.PrivateMessage]: {
    key: OutboxMessageType.PrivateMessage,
    label: 'Private message',
    class: OutboxMessageClass.PrivateMessage
  },
  [OutboxMessageType.PrivatePictureMessage]: {
    key: OutboxMessageType.PrivatePictureMessage,
    label: 'Private message with picture',
    class: OutboxMessageClass.PrivateMessage
  }
};

export const messageTypesIterable: MessageType[] = mapToIterable(messageTypes);
