<div class="insights-reports-create-container">
  <div *ngIf="!hasWidgets"
       class="create-report-empty">
    <div class="insights-row">
      <div class="insights-column">
        <div class="create-report-empty-card">
          <h2 translate="GET_BUILDING"></h2>
          <p translate="START_ADDING_READYMADE_WIDGETS_OR_CREATE_YOUR_OWN"></p>
          <!-- <div class="create-report-empty-card-action">
            <i class="ssi ssi-play-button"></i>
            <a href="http://orlo.tech" target="_blank">How to build a Custom Report</a>
          </div> -->
        </div>
      </div>
    </div>
    <div class="insights-row">
      <div class="insights-column">
        <div class="create-report-empty-card">
          <i class="ssi ssi-orlo-widgets create-report-empty-card-icon"></i>
          <h2 translate="ADD_ORLO_WIDGETS"></h2>
          <p translate="CHOOSE_FROM_READYMADE_WIDGETS"></p>
          <button type="button"
                  class="create-report-empty-card-button"
                  (click)="openAddWidgetModal()">
            <i class="ssi ssi-addsmall"></i>
          </button>
        </div>
      </div>
      <div class="insights-column">
        <div class="create-report-empty-card">
          <i class="ssi ssi-custom-widgets create-report-empty-card-icon"></i>
          <h2 translate="MAKE_YOUR_OWN"></h2>
          <p translate="CREATE_CUSTOM_MADE_WIDGETS"></p>
          <button
            class="create-report-empty-card-button"
            (click)="openCustomWidgetModal()"
          >
            <i class="ssi ssi-addsmall"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="hasWidgets">
    <ssi-insights-navbar [reportName]="reportToCreate.name"
                         (reportNameChange)="reportToCreate.name = $event"
                         (widgetAdd)="openAddWidgetModal()"
                         (widgetCustomAdd)="openCustomWidgetModal()"></ssi-insights-navbar>

    <div class="report-content">
      <div class="actions-bar">
        <div class="left">
          <ssi-dropdown-select-2 headButtonStyleVariant="analytics"
                                 [options]="accessOptionsIterable"
                                 [(ngModel)]="selectedAccessOption"
                                 (ngModelChange)="onAccessChange($event)">
            <ng-template ssiTemplateSelector="headButtonLabel">
              <span class="head-button-label">Access: <span>{{selectedAccessOption.label}}</span></span>
            </ng-template>
          </ssi-dropdown-select-2>
        </div>
        <div class="right">
          <button type="button"
                  class="btn cancel-btn"
                  (click)="cancel()">Cancel</button>
          <button type="button"
                  [disabled]="!reportToCreate.name"
                  class="btn save-btn"
                  (click)="save()">Save</button>
        </div>
      </div>

      <ssi-widgets-grid #widgetsGrid [activeFilters]="activeFilters" [createMode]="true"></ssi-widgets-grid>
    </div>
  </ng-container>
</div>