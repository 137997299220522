import './widget-kpi.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { findSentimentConst } from '../../../../../../common/constants';
import { FieldName } from '../../constants/filters-field-definitions';
import { Filter } from '../../../reports/view/view-report.component';
import { InsightsService, KPI } from '../../../insights.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DrilldownModalComponent } from '../drilldown-modal/drilldown-modal.component';

export interface KpiData {
  label: string;
  val: string[];
}

@Component({
  selector: 'ssi-widget-kpi',
  templateUrl: './widget-kpi.component.html',
  styles: []
})
export class WidgetKpiComponent implements OnChanges, OnInit {
  @Input() label: string;
  @Input() widget: KPI;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;

  @Output() loaded = new EventEmitter<void>();
  @Output() requiredRenderSize = new EventEmitter<{ w: number; h: number }>();

  data;

  constructor(
    private insightsService: InsightsService,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    if (this.initRender) {
      this.requestData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        !changes.globalFilters.firstChange) ||
      (changes.globalFilters &&
        !changes.globalFilters.previousValue &&
        changes.globalFilters.currentValue.length !== 0 &&
        changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data }) => {
        this.data = this.insightsService.relabelDataModelForUi(
          this.widget,
          data
        );

        if (this.widget.group_by) {
          this.data = Object.entries(this.data).map((pair) => {
            const val = Array.isArray(pair[1])
              ? pair[1].map((item) => item.toString())
              : [pair[1].toString()];
            return { label: pair[0], val };
          });

          if (this.widget.group_by.field === FieldName.Sentiment) {
            this.data.sort((a, b) => {
              const aConst = findSentimentConst('key3', a.label);
              const bConst = findSentimentConst('key3', b.label);
              return bConst.numericKey - aConst.numericKey;
            });
          }
        } else {
          const dataStringified = Array.isArray(this.data)
            ? data.map((item) => item.toString())
            : [data.toString()];
          this.data = [{ label: this.label, val: dataStringified }];
        }
        this.requiredRenderSize.emit(this._getRequiredRenderSize(this.data));
        this.loaded.emit();
      });
  }

  openDrilldownModal() {
    console.log('datapoint click - KPI');
    const modal = this.modal.open(DrilldownModalComponent, {
      windowClass: 'xxl-modal'
    });
    modal.componentInstance.globalFilters = this.globalFilters;
    modal.componentInstance.streamIds = this.streamIds;
    modal.componentInstance.widgetFilters = this.widget.filters;
  }

  private _getRequiredRenderSize(data): { w: number; h: number } {
    const maxRowWidth = 4;
    const getHeight = (dataCount) =>
      dataCount > maxRowWidth ? Math.ceil(dataCount / maxRowWidth) : 1;
    const getWidth = (dataCount) =>
      dataCount > maxRowWidth ? maxRowWidth : dataCount;
    let height = 1;
    let width = 1;

    if (this.widget.group_by) {
      // const dataItemsCount: number = Object.values(data).reduce((counter: number, dataItem: number | number[]) => counter + (Array.isArray(dataItem) ? dataItem.length : 1), 0);
      const dataItemsCount: number = Object.values(data).length;
      height = getHeight(dataItemsCount);
      width = getWidth(dataItemsCount);
    } else {
      if (Array.isArray(data)) {
        height = getHeight(data.length);
        width = getWidth(data.length);
      }
    }
    return { w: width, h: height };
  }
}
