<div class="custom-widget cw-container modal-popup">
  <div class="cw-side">
    <div class="cw-side-tabs">
      <button
        class="cw-side-tab"
        [ngClass]="{'cw-side-tab-active': activeStageTab === stages.Build}"
        (click)="activeStageTab = stages.Build"
      >
        <p>Build</p>
      </button>
      <button
        class="cw-side-tab"
        [ngClass]="{'cw-side-tab-active': activeStageTab === stages.Filter}"
        (click)="activeStageTab = stages.Filter"
      >
        <p>Filter</p>
      </button>
    </div>

    <div [class]="'cw-side-form'" [class.active]="activeStageTab === stages.Build" >
      <div class="cw-side-form-section-stretch">
        <div class="cw-side-form-section">
          <p class="cw-side-form-heading">Chart type</p>
          <ul class="cw-grouped-radio">
            <li
              *ngFor="let chartOption of chartOptionsIterable; let i = index"
              class="cw-grouped-radio-field"
              title="{{chartOption.label}}"
            >
              <input
                type="radio"
                name="chart"
                [id]="'check_' + i"
                [value]="chartOption.key"
                (click)="onChartTypeClick($event, chartOption.key)"
                [(ngModel)]="chartType"
                (ngModelChange)="onChartTypeChange()"
              >
              <label [for]="'check_' + i">
                <i [ngClass]="chartOption.icon"></i>
                <span class="hidden">{{chartOption.label}}</span>
              </label>
            </li>
          </ul>
          <div *ngIf="showChartChangeConfirmation" class="cw-side-form-confirm">
            <p>Choosing a different chart will clear the field selection you have made</p>
            <div class="text-right">
              <button
                (click)="showChartChangeConfirmation = false"
                class="cw-side-form-confirm-btn"
              >Cancel</button>
              <button
                class="cw-side-form-confirm-btn cw-side-form-confirm-btn-primary"
                (click)="chartType = clickedChartOption; onChartTypeChange(); showChartChangeConfirmation = false"
              >
                Select Chart
              </button>
            </div>
          </div>
        </div>
        <div
          class="cw-side-form-section"
          *ngIf="finishedPopulatingWidgetFields"
        >
          <p class="cw-side-form-heading">Select your data</p>
          <ng-container *ngFor="let configOption of chartOptions[chartType].configOptions">
            <p class="cw-side-form-subheading">{{configOption.label}}</p>
            <ssi-custom-widget-field
              [fieldOption]="configOption"
              (onFieldOptionChange)="onFieldOptionChange($event, configOption.name)"
            ></ssi-custom-widget-field>
          </ng-container>
        </div>
      </div>
      <div class="cw-side-form-section cw-side-form-section-action">
        <button
          class="cw-side-action-button cw-side-action-button-primary"
          (click)="updateWidget()"
          [disabled]="updateDisabled"
        >
          Update
        </button>
      </div>
    </div>

    <div [class]="'cw-filters'" [class.active]="activeStageTab === stages.Filter">
      <ssi-global-filters
        *ngIf="globalFiltersModel"
        [colleagues]="colleagues"
        [teams]="teams"
        [accounts]="workflowAccounts"
        [filters]="globalFiltersModel"
        [monitoringStreams]="streams"
        [functionalityMode]="GlobalFiltersFunctionalityMode.Compact"
        [stylesMode]="GlobalFiltersStyleMode.Compact"
        (onApplyFilters)="onApplyWidgetFilters($event)"
        (onKeywordFilter)="onKeywordFilter($event)"
      ></ssi-global-filters>
    </div>
  </div>

  <div class="cw-content">
    <div class="cw-header">
      <div class="cw-header-form">
        <div class="cw-header-form-input cw-header-form-input-name">
          <label class="cw-header-form-input-label">
            <i class="ssi ssi-text"></i>
            <span class="hidden">Name:</span>
          </label>
          <input
            type="text"
            placeholder="Name your widget..."
            [(ngModel)]="formData.name"
            (ngModelChange)="onWidgetNameUpdate()"
          >
        </div>

        <!-- <div class="cw-header-form-input cw-header-form-input-desc">
          <label class="cw-header-form-input-label">
            <i class="ssi ssi-sort"></i>
            <span class="hidden">Description:</span>
          </label>
          <input
            type="text"
            placeholder="Add widget description..."
            [(ngModel)]="formData.description"
            (ngModelChange)="widgetData.description = formData.description"
          >
        </div> -->
      </div>

      <div class="cw-header-actions">
        <button class="cw-header-action-button" (click)="activeModal.close(null)">
          Cancel
        </button>
        <button
          class="cw-header-action-button cw-header-action-button-primary"
          (click)="saveWidget()"
          [disabled]="!widgetData.type || formInvalid"
        >
          {{edit ? 'Save' : 'Add widget'}}
        </button>
      </div>
    </div>

    <div class="cw-body">
      <div *ngIf="isLoadingChart" class="cw-chart-loading">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABQCAMAAACEXWP5AAABMlBMVEVHcEwWuuEUu+QUuuMUuuMUueIAgP8Xu+MUuuMUuuMUuuIUuuMUueIUu+QUuuMUueIWueASvOQSyO0SvucUuuMUuuMUueMVueIUuuQVuuMUuuMUuuMTvOUVu+MVu+Aav+YUuuMUueQUuuQUuuMVvOMUueIUu+MUuuQUu+QVvOQUuuIUuuIUvOETuOMAzMwUxOsPtOEVv+oAqv8WvuQTu+EUu+MTu+MazOYUuuIUuOAkttsUu+IA//8Yttsgv98Tu+MVv98as8wVuOMUueMTueMStuQWtuIUueEVut8Qv98UuOQSu+QUvOQbvOQUsdgUuuQYt+cWveIVuuQSueUTt+IVv+oStuEUtuMUueUUt+UWueUSueUVuuQUu+Mq1f8Tu+MSudwXvOEas+YVvOYTvOcTuOXYpY57AAAAZnRSTlMAO7P35I4CLfv/lz/NcNB0OjkOK8nm2FdzrbzIaocxFNqWtWRIu2WC2FRzcmY2BQ0RDAMvrLNsChoZB48BFQisGApIgHg4IzMwEGg4JhMNaCAjMDo1GCo/TSc6HWBABngdIgpINUT/bcBAAAABsklEQVR42u3U51LCUBCG4c/eGzFERcVesWHDhmDUiBWxYgEVvf9b0BhnVucksk72jzN5L+CZUxesKiqrqmtqIVJdfcNHlRCpseGzJkjU7GAtkKjVwdoC7F9h7R0hKUzr1PWwIYOFwrqdIYIZupMhgXXppPnGNJ003xi6SfOPhXpIc8cktIiD9UJC63Owfkho0QHbGgRktL7I0DDgRxuB70gbhaQGQa0HgtoYZLRxXZ8Yg1gagoIEi05OTf8oNgN+s3PzXy3EgcWlZaUVcFtNUGvr2CCD4q5tcy3xrS0suxUDr2TiR6KY7DYXt+UuQPRpBAX9tRR+K54Gu+SOae7ueVP7lnWQ4VqHpt0RPDq27E7Ylt0h3Du1nE6YlhPcy1iksa0zuJe2SONaZhYenZPGtXKgQXN8cXkFus1rVeNbN7cfaWU0noX87Wchb41v4c7B7sHUVEvF8gBfe/CwCPPQMlDKKpaCeWgHUHokqwymaAUFy5HFw0iDUrJIFgcj7QlqqRbTLObAwEizf9Z5HG5ls0mwMKpwyh+6KqYWYP8Re4ZEGg1H/72UbOsVkNFeS2+ME3sH95XoVmT8lkgAAAAASUVORK5CYII=" alt="loading wheel">
        <p>Building your widget now...</p>
      </div>
      <div *ngIf="!showWidget && !isLoadingChart" class="cw-chart-empty">
        <i [class]="chartOptions[chartType].icon"></i>
        <p class="cw-chart-empty-title">Add some fields!</p>
        <p>To get started, select the fields you want to report on</p>
      </div>

      <div *ngIf="showWidget" class="cw-chart-container">
        <div class="cw-chart-wrap">
          <ssi-widget-chart-bar
            *ngIf="widgetData.type === widgetTypes.bar.key"
            class="widget"
            [globalFilters]="widgetData.filters"
            [filters]="widgetData.widget?.filters"
            [widget]="widgetData"
            [streamIds]="[]"
            [initRender]="true"
            (loaded)="isLoadingChart = false"
          ></ssi-widget-chart-bar>

          <ssi-widget-chart-line
            *ngIf="widgetData.type === widgetTypes.line.key"
            class="widget"
            [globalFilters]="widgetData.filters"
            [filters]="widgetData.widget?.filters"
            [widget]="widgetData"
            [streamIds]="[]"
            [initRender]="true"
            (loaded)="isLoadingChart = false"
          ></ssi-widget-chart-line>

          <ssi-widget-kpi
            *ngIf="widgetData.type === widgetTypes.kpi.key"
            class="widget"
            [globalFilters]="widgetData.filters"
            [filters]="widgetData.widget?.filters"
            [widget]="widgetData"
            [streamIds]="[]"
            [label]="widgetData.name"
            [initRender]="true"
            (loaded)="isLoadingChart = false"
            (requiredRenderSize)="overrideChartSize = $event"
          ></ssi-widget-kpi>

          <ssi-widget-chart-pie
            class="widget"
            *ngIf="widgetData.type === widgetTypes.pie.key"
            [globalFilters]="widgetData.filters"
            [filters]="widgetData.widget?.filters"
            [widget]="widgetData"
            [streamIds]="[]"
            [isDonut]="false"
            [initRender]="true"
            (loaded)="isLoadingChart = false"
          ></ssi-widget-chart-pie>

          <ssi-widget-chart-pie
            *ngIf="widgetData.type === widgetTypes.donut.key"
            class="widget"
            [globalFilters]="widgetData.filters"
            [filters]="widgetData.widget?.filters"
            [widget]="widgetData"
            [streamIds]="[]"
            [isDonut]="true"
            [initRender]="true"
            (loaded)="isLoadingChart = false"
          ></ssi-widget-chart-pie>

          <ssi-widget-swot
            class="widget"
            *ngIf="widgetData.type === widgetTypes.swot.key"
            [globalFilters]="widgetData.filters"
            [filters]="widgetData.widget?.filters"
            [widget]="widgetData"
            [streamIds]="[]"
            [initRender]="true"
            (loaded)="isLoadingChart = false"
          ></ssi-widget-swot>

          <ssi-widget-big
            *ngIf="widgetData.type === widgetTypes.big.key"
            class="widget"
            [globalFilters]="widgetData.filters"
            [filters]="widgetData.widget?.filters"
            [widget]="widgetData"
            [schema]="schema"
            [viewMode]="false"
            [streamIds]="[]"
            [initRender]="true"
            (loaded)="isLoadingChart = false"
          ></ssi-widget-big>

          <ssi-sentiment-gauge
            *ngIf="widgetData.type === widgetTypes.sentiment.key"
            class="widget"
            [globalFilters]="widgetData.filters"
            [filters]="widgetData.widget?.filters"
            [widget]="widgetData"
            [streamIds]="[]"
            [initRender]="true"
            (loaded)="isLoadingChart = false"
          ></ssi-sentiment-gauge>
        </div>
      </div>
    </div>
  </div>
</div>