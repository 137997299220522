import './create.component.scss';

import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  AccessOptions,
  AccessOption,
  accessOptions,
  accessOptionsIterable
} from '../../../../../common/constants';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { NotificationService } from '../../../../../common/services/notification/notification.service';

import { AddWidgetComponent } from '../../widgets/add-widget/add-widget.component';
import { CustomWidgetComponent } from '../../widgets/custom-widget/custom-widget.component';
import { InsightsService, InsightsWidget } from '../../insights.service';
import { WidgetsGridComponent } from '../../common/components/widgets-grid/widgets-grid.component';
import { commonDateRanges, DateRanges } from '../../../../../common/constants';
import { FieldName } from '../../common/constants/filters-field-definitions';
import { Filter } from '../view/view-report.component';
import {
  Colleague,
  Team,
  MonitoringStreamsService
} from '../../../../../common/services/api';
import {
  colleagues,
  teams,
  authUser,
  workflowAccounts
} from '../../../../common-resolves';
import { Account, User } from '@ui-resources-angular';

@Component({
  selector: 'ssi-create',
  templateUrl: './create.component.html',
  styles: []
})
export class CreateComponent implements OnInit, AfterViewInit {
  static resolve = [authUser, colleagues, teams, workflowAccounts, authUser];

  @Input() colleagues: Colleague[];
  @Input() teams: Team[];
  @Input() workflowAccounts: Account[];
  @Input() authUser: User;

  @ViewChild('widgetsGrid') widgetsGrid: WidgetsGridComponent;

  accessOptions: AccessOptions = accessOptions;
  accessOptionsIterable: AccessOption[] = accessOptionsIterable;
  selectedAccessOption = this.accessOptions.private;
  dateRanges: DateRanges = commonDateRanges;

  reportToCreate = {
    name: '',
    widgets: [],
    filters: [],
    is_shared: false
  };
  hasWidgets = false;
  streams;
  activeFilters: Filter[] = []; // USED BY API

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected state: StateService,
    protected modal: NgbModal,
    protected popup: PopupService,
    protected insightsService: InsightsService,
    protected notificationService: NotificationService,
    protected monitoringStreamsService: MonitoringStreamsService
  ) {}

  async ngOnInit() {
    await this.monitoringStreamsService.getAll().then((streams) => {
      // this.streamIds = streams.map((s) => s.id);
      this.streams = streams;
    });
    // Make sure new widgets aren't pulling data from all time for performance
    this.activeFilters.push({
      key: 'listeningPeriod',
      label: this.dateRanges['month'].label,
      field: FieldName.CreatedAt,
      range: {
        gte: this.dateRanges['month'].startString,
        lte: this.dateRanges['month'].endString
      }
    });
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.state.params.baseName) {
      this.hasWidgets = true;
      this.cdRef.detectChanges();
      const templateReports = await this.insightsService.getTemplateReports();
      const templateReport = templateReports.find(
        (r: any) => r.template_key === this.state.params.baseName
      );
      this.reportToCreate.name = templateReport.name;
      this.widgetsGrid.addWidgets(templateReport.widgets);
    } else if (this.state.params.reportIdToCopy) {
      this.hasWidgets = true;
      this.cdRef.detectChanges();
      const reportToCopy = await this.insightsService.getReport(
        this.state.params.reportIdToCopy
      );
      this.reportToCreate.name = reportToCopy.name;
      this.reportToCreate.filters = reportToCopy.filters;
      this.widgetsGrid.addWidgets(reportToCopy.widgets);
    }
  }

  async openAddWidgetModal(): Promise<void> {
    const modal = this.modal.open(AddWidgetComponent, {
      windowClass: 'xxl-modal'
    });

    modal.componentInstance.widgetAdded.subscribe((widget: InsightsWidget) => {
      this.hasWidgets = true;
      this.cdRef.detectChanges();
      this.widgetsGrid.addNewWidget(widget);
    });

    // const result = await modal.result;
    // console.log('result: ', result);
  }

  openCustomWidgetModal() {
    const modal = this.modal.open(CustomWidgetComponent, {
      windowClass: 'xxl-modal custom-widget-modal'
    });

    modal.componentInstance.colleagues = this.colleagues.sort(
      this.sortByFullName
    );
    modal.componentInstance.teams = this.teams.sort(this.sortByTeamName);
    modal.componentInstance.workflowAccounts = this.workflowAccounts;
    modal.componentInstance.authUser = this.authUser;
    modal.componentInstance.streams = this.streams;
    modal.componentInstance.globalFilters = this.activeFilters;
    modal.componentInstance.widgetAdded.subscribe((widget: InsightsWidget) => {
      this.hasWidgets = true;
      this.cdRef.detectChanges();
      this.widgetsGrid.addNewWidget(widget);
    });
  }

  onAccessChange(selectedOption): void {
    // console.log('selectedAccessOption: ', selectedOption);
  }

  cancel(): void {
    this.state.go('auth.insights.reports.home');
  }

  async save(): Promise<void> {
    console.log('Saving...');

    const widgetsToSave = this.widgetsGrid.toApiWidgets(
      this.widgetsGrid.widgets
    );

    this.reportToCreate.widgets = widgetsToSave;
    this.reportToCreate.is_shared =
      this.selectedAccessOption.key === this.accessOptions.public.key;

    const savedReport = await this.insightsService.createReport(
      this.reportToCreate as any,
      this.workflowAccounts,
      this.streams
    );
    console.log('savedReport: ', savedReport);

    this.notificationService.open(
      `Your new report '${savedReport.name}' has been saved, success!`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      1000
    );

    this.state.go('auth.insights.reports.view', {
      id: savedReport.id,
      reportName: savedReport.name
    });
  }

  sortByFullName(itemA: Colleague, itemB: Colleague) {
    return itemA.fullName.localeCompare(itemB.fullName);
  }

  sortByTeamName(itemA: Team, itemB: Team) {
    return itemA.name.localeCompare(itemB.name);
  }
}
