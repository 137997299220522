<div class="top-post-list-container">
  <div class="row dropdown-select">
    <ssi-dropdown-select-2 headButtonStyleVariant="analytics"
                           [options]="sortTypesIterable"
                           [(ngModel)]="selectedSortType"
                           (ngModelChange)="onSortTypeChange($event)"></ssi-dropdown-select-2>
  </div>

  <div class="row"
       *ngFor="let post of posts; let index = index;">
    <div class="top-post-container">
      <ssi-outbox-post class="top-post"
                       [post]="post"></ssi-outbox-post>

      <div class="top-post-stats">
        <div class="top-post-stat-list">
          <div class="stat"
               *ngIf="isNumber(post.like_count) && post.account.socialNetwork.statistics.likes.outbox"
               [ssiTooltip]="'Likes'"
               tooltipWindowClass="tooltip-chunky">
            <i class="ssi ssi-emoji-heart"></i>
            <ssi-big-number [number]="post.like_count"></ssi-big-number>
          </div>
          <div class="stat"
               *ngIf="isNumber(post.share_count) && post.account.socialNetwork.statistics.shares.outbox"
               [ssiTooltip]="post.account.account_type_id == AccountTypeId.Twitter ? 'Reposts' : 'Shares'"
               tooltipWindowClass="tooltip-chunky">
            <i class="ssi"
               [class.ssi-correct-retweet]="post.account.account_type_id == AccountTypeId.Twitter"
               [class.ssi-shares]="post.account.account_type_id != AccountTypeId.Twitter"></i>
            <ssi-big-number [number]="post.share_count"></ssi-big-number>
          </div>
          <div class="stat"
               *ngIf="isNumber(post.comment_count) && post.account.socialNetwork.statistics.comments.outbox"
               [ssiTooltip]="'Comments'"
               tooltipWindowClass="tooltip-chunky">
            <i class="ssi ssi-total-engagement"></i>
            <ssi-big-number [number]="post.comment_count"></ssi-big-number>
          </div>
          <div class="stat"
               *ngIf="isNumber(post.clicks) && post.account.socialNetwork.statistics.clicks.outbox"
               [ssiTooltip]="'Clicks'"
               tooltipWindowClass="tooltip-chunky">
            <i class="ssi ssi-clicks"></i>
            <ssi-big-number [number]="post.clicks"></ssi-big-number>
          </div>
          <div class="stat"
               *ngIf="isNumber(post.reach) && post.account.socialNetwork.statistics.reach.outbox"
               [ssiTooltip]="'Reach'"
               tooltipWindowClass="tooltip-chunky">
            <i class="ssi ssi-reach"></i>
            <ssi-big-number [number]="post.reach"></ssi-big-number>
          </div>
          <div class="stat"
               *ngIf="isNumber(post.impressions) && post.account.socialNetwork.statistics.impressions.outbox"
               [ssiTooltip]="'Impressions'"
               tooltipWindowClass="tooltip-chunky">
            <i class="ssi ssi-eye"></i>
            <ssi-big-number [number]="post.impressions"></ssi-big-number>
          </div>
          <div class="stat"
               *ngIf="isNumber(post.engagement_rate_reactions) && post.account.socialNetwork.statistics.engagementRate.outbox"
               [ssiTooltip]="'Engagement Rate'"
               tooltipWindowClass="tooltip-chunky">
            <i class="ssi ssi-engagement-analytics"></i>
            <ssi-big-number [number]="post.engagement_rate_reactions"></ssi-big-number>
          </div>
          <div
            class="stat"
            *ngIf="post.reaction_count"
            ssiTooltip="Total Reactions"
            tooltipWindowClass="tooltip-chunky"
          >
            <i class="ssi ssi-reactions-small"></i>
            <ssi-big-number [number]="post.reaction_count"></ssi-big-number>
          </div>
          <div
            *ngIf="post.extra_metrics && (post.extra_metrics.saved || post.extra_metrics.saved === 0)"
            class="stat"
            [ssiTooltip]="'Saved'"
            tooltipWindowClass="tooltip-chunky"
          >
            <i class="ssi ssi-ig-saves"></i>
            <ssi-big-number [number]="post.extra_metrics?.saved"></ssi-big-number>
          </div>
        </div>
        <div class="top-post-top-score"
             *ngIf="index <= 2">
          <div class="rank"
               id="rank-{{index}}">
            <div>
              {{index + 1}}
            </div>
            <i class="ssi ssi-social-score"></i>
          </div>

          <span class="number">
            <ssi-big-number [number]="post[selectedSortType.outboxKey]"></ssi-big-number>
          </span>
          <span class="subtitle">{{selectedSortType.scoreLabel}}</span>
        </div>
      </div>

    </div>

    <div class="delimiter"
         *ngIf="index !== posts.length -1"></div>
  </div>
</div>