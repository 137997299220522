import './post-modal.component.scss';
import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  TemplateRef,
  Renderer2,
  OnDestroy
} from '@angular/core';
import {
  API,
  Outbox,
  AccountModel,
  Account,
  OutboxMessageType,
  UserModel,
  User
} from '@ui-resources-angular';
import {
  NotesModelService,
  Note
} from './../../../../validate-posts/validate-posts-box/validate-posts-notes/notes-model/notes-model.service';
import { SnackbarService } from '../../../../../../../common/services/snackbar/snackbar.service';
import { PopupService } from '../../../../../../../common/services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountTypeId } from '../../../../../../../common/enums';
import { ValidatePostsDissaproveComponent } from '../../../../validate-posts/validate-posts-box/validate-posts-dissaprove/validate-posts-dissaprove.component';
import { OutboxTagsService } from '../../../../../../../common/services/api/outbox-tags';

export enum PostModalTitle {
  Live = 'Live Post',
  Scheduled = 'Scheduled Post',
  RequiresApproval = 'Post in Approvals',
  Failed = 'Failed Post',
  Deleted = 'Deleted post',
  Unknown = 'Post'
}

export interface PostStat {
  label: string;
  figure: string;
  dividerColor: string;
  hidden: boolean;
}

@Component({
  selector: 'ssi-post-modal',
  templateUrl: './post-modal.component.html',
  styles: []
})
export class PostModalComponent implements OnInit, OnDestroy {
  @Output() refresh = new EventEmitter();

  post: Outbox;
  account: Account;
  authUser: User;
  title: PostModalTitle = PostModalTitle.Unknown;
  PostModalTitle = PostModalTitle;
  stats: PostStat[] = [];
  notes: Note[];
  OutboxMessageType = OutboxMessageType;
  disableNotePosting = false;
  disapprovalTags: { label: string; value: string }[];

  @ViewChild('retrySnackbarTemplate')
  public retrySnackbarTemplate: TemplateRef<any>;
  @ViewChild('approvedSnackbarTemplate')
  public approvedSnackbarTemplate: TemplateRef<any>;
  @ViewChild('disapprovedSnackbarTemplate')
  public disapprovedSnackbarTemplate: TemplateRef<any>;

  constructor(
    private renderer: Renderer2,
    public modal: NgbModal,
    private api: API,
    public activeModal: NgbActiveModal,
    private accountModel: AccountModel,
    public notesModelService: NotesModelService,
    private snackbar: SnackbarService,
    private popup: PopupService,
    private translate: TranslateService,
    private userModel: UserModel,
    protected outboxTagsService: OutboxTagsService
  ) {}

  async ngOnInit() {
    this.title = this._getModalTitle();

    // prevent background scroll when modal is open
    this.renderer.addClass(window.document.body, 'overflow-hidden');

    const [account, authUser, notes] = await Promise.all([
      this.accountModel.get(this.post.account_id),
      this.userModel.getAuthUser(),
      this.notesModelService.fetchNotes(this.post.id, 'outbox')
    ]);
    this.account = account;
    this.authUser = authUser;
    this.notes = notes;
    this.stats = await this._getPostStats();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(window.document.body, 'overflow-hidden');
  }

  private _getModalTitle(): PostModalTitle {
    if (this.post.deleted_at) {
      return PostModalTitle.Deleted;
    }
    if (!this.post.is_validated) {
      return PostModalTitle.RequiresApproval;
    }
    if (
      this.post.social_id &&
      !this.post.deleted_at &&
      this.post.is_validated &&
      !this.post.is_draft
    ) {
      return PostModalTitle.Live;
    }
    if (this.post.send_at && !this.post.sent_at) {
      return PostModalTitle.Scheduled;
    }
    if (!this.post.social_id) {
      return PostModalTitle.Failed;
    }
    return PostModalTitle.Unknown;
  }

  private async _getPostStats(): Promise<PostStat[]> {
    const { data } = await this.api.get(
      `/outbox/averageStats?account_id=${this.post.account_id}`
    );
    return [
      {
        label: 'Post Score',
        figure: this.post.score.toString(),
        dividerColor: '#9FD700',
        hidden: AccountTypeId.YouTube === +this.account.account_type_id
      },
      {
        label: 'Score Average',
        figure: this._getAverageScore(
          data.avg_comments,
          data.avg_shares,
          data.avg_likes,
          data.avg_clicks_es
        ).toString(),
        dividerColor: '#D6DFF7',
        hidden: AccountTypeId.YouTube === +this.account.account_type_id
      },
      {
        label: 'Reach',
        figure: this.post.reach,
        dividerColor: '#3463F2',
        hidden: !this.account.socialNetwork.statistics.reach.outbox
      },
      {
        label:
          this.post.type === OutboxMessageType.InstagramStory
            ? 'Story Views'
            : 'Impressions',
        figure: this.post.impressions,
        dividerColor: '#FF0091',
        hidden: false
      },
      {
        label: 'Engagement Rate',
        figure:
          this.post.type === OutboxMessageType.InstagramReel
            ? 'N/A'
            : this.post.engagement_rate_reactions.toString(),
        dividerColor: '#FFA962',
        hidden: false
      },
      {
        label: 'Clicks',
        figure: this.post.clicks.toString(),
        dividerColor: '#12ACA4',
        hidden: false
      },
      {
        label: 'Comments',
        figure: this.post.comment_count.toString(),
        dividerColor: '#8DA4E5',
        hidden: false
      },
      {
        label: 'Likes',
        figure: this.post.like_count.toString(),
        dividerColor: '#F4BF41',
        hidden: false
      },
      {
        label: 'Reactions',
        figure: this.post.reaction_count.toString(),
        dividerColor: '#FFA962',
        hidden: false
      },
      {
        label: 'Shares',
        figure:
          this.post.type === OutboxMessageType.InstagramReel
            ? 'N/A'
            : this.post.share_count.toString(),
        dividerColor: '#FEB9B4',
        hidden: false
      },
      {
        label: 'Tap Back',
        figure:
          (this.post &&
            this.post.story_metrics &&
            this.post.story_metrics.taps_back.toString()) ||
          '0',
        dividerColor: '#9FD700',
        hidden:
          this.post.account.account_type_id !==
            String(AccountTypeId.Instagram) &&
          this.post.type !== OutboxMessageType.InstagramStory
      },
      {
        label: 'Tap Forward',
        figure:
          (this.post &&
            this.post.story_metrics &&
            this.post.story_metrics.taps_forward.toString()) ||
          '0',
        dividerColor: '#D6DFF7',
        hidden:
          this.post.account.account_type_id !==
            String(AccountTypeId.Instagram) &&
          this.post.type !== OutboxMessageType.InstagramStory
      },
      {
        label: 'Exit Story',
        figure:
          (this.post &&
            this.post.story_metrics &&
            this.post.story_metrics.exits.toString()) ||
          '0',
        dividerColor: '#FF0091',
        hidden:
          this.post.account.account_type_id !==
            String(AccountTypeId.Instagram) &&
          this.post.type !== OutboxMessageType.InstagramStory
      },
      {
        label: 'Saved',
        figure:
          (this.post &&
            this.post.extra_metrics &&
            this.post.extra_metrics.saved &&
            this.post.extra_metrics.saved.toString()) ||
          '0',
        dividerColor: '#3463F2',
        hidden:
          this.post.account.account_type_id !==
            String(AccountTypeId.Instagram) &&
          !(this.post.extra_metrics && this.post.extra_metrics.saved)
      }
      // {
      //   label: 'Reaction Count',
      //   figure: this.post.reaction_count.toString(),
      //   dividerColor: '#9FD700',
      //   hidden: false
      // }
    ];
  }

  private _getAverageScore(
    comments: string | number = 0,
    shares: string | number = 0,
    likes: string | number = 0,
    clicks: string | number = 0
  ) {
    return Math.round(+comments * 4 + +shares * 3 + +clicks * 2 + +likes);
  }

  public updateNotes(updatedNote) {
    const index = this.notes.indexOf(updatedNote);

    if (index > -1) {
      this.notes.splice(index, 1);
    } else {
      this.notes.unshift(updatedNote);
    }
  }

  public retryFailedPost(postId: string) {
    this.post.retryFailed(postId);
    this.snackbar.open(this.retrySnackbarTemplate, {
      duration: 3,
      position: 'top',
      snackbarClass: 'snackbar-style-1'
    });
  }

  public approvePost() {
    this.popup
      .confirm({
        title: this.translate.instant('APPROVE_POST'),
        template: this.translate.instant(
          'ARE_YOU_SURE_YOU_WANT_TO_APPROVE_THIS_POST'
        )
      })
      .then((shouldApprove) => {
        if (shouldApprove) {
          this.post.approve();
          this.post.is_validated = true;
          this.title = this._getModalTitle();
          this.snackbar.open(this.approvedSnackbarTemplate, {
            duration: 3,
            position: 'top',
            snackbarClass: 'snackbar-style-1'
          });
        }
      });
  }

  public async disapprovePost() {
    const validationTags = await this.outboxTagsService.getValidationTags();
    const modal = this.modal.open(ValidatePostsDissaproveComponent);
    modal.componentInstance.disapprovalTags = validationTags.map((tag) => {
      return {
        label: tag.name,
        value: tag.name
      };
    });
    const result = await modal.result;

    if (result !== 'cancel') {
      this.post.disapprove(
        (result as any).reason,
        (result as any).disapprovalTags.map((t) => t.value)
      );
      this.title = PostModalTitle.Deleted;
      this.snackbar.open(this.disapprovedSnackbarTemplate, {
        duration: 3,
        position: 'top',
        snackbarClass: 'snackbar-style-1'
      });
    }
  }
}
