import { Injectable } from '@angular/core';
import {
  ApiService,
  ColleaguesService,
  MonitoringStream,
  MonitoringStreamsService
} from '../../../../../../common/services/api';
import { industries } from '../../../../../../common/constants/industries';
import { interests } from '../../../../../../common/constants/interests';
import {
  DateRanges,
  LANGUAGES,
  ageOptions,
  commonDateRanges,
  emotions,
  findSentimentConst
} from '../../../../../../common/constants';
import {
  LocationTier,
  networksWithInsightsSources
} from '../../../insights.service';
import { WorkflowManagerService } from '../../../../../../common/services/workflow-manager/workflow-manager.service';
import { Account, AccountModel } from '@ui-resources-angular';
import { format } from 'date-fns';
import { InsightsFilter } from './global-filters.component';
import { TranslateService } from '@ngx-translate/core';
import { Filter } from '../../../reports/view/view-report.component';

@Injectable({ providedIn: 'root' })
export class InsightsFiltersService {
  constructor(
    protected api: ApiService,
    protected monitoringStreamsService: MonitoringStreamsService,
    protected workflowManager: WorkflowManagerService,
    protected accountModel: AccountModel,
    protected translate: TranslateService,
    protected colleaguesService: ColleaguesService
  ) {}

  streams: MonitoringStream[];
  workflowAccounts: Account[];
  dateRanges: DateRanges = commonDateRanges;

  async generateInsightsFiltersModel(
    savedFilters: Array<any> = [],
    savedAccounts: Array<number> = [],
    savedSearchStreams: Array<string> = []
  ): Promise<InsightsFilter> {
    this.streams = await this.monitoringStreamsService
      .getAll()
      .then((streams) => {
        return streams.sort((a, b) => a.name.localeCompare(b.name));
      });
    const monitoringStreams = this.streams;
    const userAccounts = await this.accountModel.findAllAccounts(
      this.workflowManager.getCurrentId()
    );
    const allAccounts = await this.accountModel.findAllAccounts(null, true);

    // Default select all accounts and streams if no filters to match api default
    if (
      savedAccounts &&
      savedAccounts.length === 0 &&
      savedSearchStreams &&
      savedSearchStreams.length === 0
    ) {
      savedAccounts = userAccounts.map(({ id }) => parseInt(id, 10));
      savedSearchStreams = monitoringStreams.map(({ id }) => id);
    }
    return {
      keyword: {
        label: 'Keyword search',
        icon: 'ssi ssi-correct-search',
        expanded: false,
        key: 'keyword',
        filterSelections: []
      },
      listeningPeriod: {
        label: 'Listening period',
        icon: 'ssi ssi-date-preset',
        expanded: false,
        key: 'listening-period',
        dateFilterSelections:
          savedFilters &&
          this.calculateDateRange(
            savedFilters.find(({ field }) => field === 'Created At')
          )
      },
      accounts: {
        label: 'Inbox Accounts',
        icon: 'ssi ssi-inbox-accounts',
        expanded: false,
        key: 'accounts',
        filterSelections: (function () {
          if (savedAccounts) {
            return savedAccounts.map((accountId) => {
              const savedAccount = userAccounts.find(
                (account) => account.id === accountId.toString()
              );
              let permissionAccount;
              if (!savedAccount) {
                permissionAccount = allAccounts.find(
                  (account) => account.id === accountId.toString()
                );
              }
              return {
                value: true,
                label: savedAccount
                  ? savedAccount.name
                  : permissionAccount
                  ? 'Insufficient Permissions'
                  : 'Deleted Account',
                constant: savedAccount
                  ? savedAccount.id
                  : permissionAccount
                  ? 'permission'
                  : 'deleted'
              };
            });
          } else {
            return [];
          }
        })(),
        options: (function () {
          const options = {};
          // options['Exclude All'] = false;
          userAccounts.map(
            (account) =>
              (options[account.id] =
                savedAccounts && savedAccounts.includes(Number(account.id))
                  ? true
                  : false)
          );
          if (savedAccounts && savedAccounts.length === userAccounts.length) {
            options['Select All'] = true;
          }
          return options;
        })()
      },
      savedStreams: {
        label: 'Saved Streams',
        icon: 'ssi ssi-orlo-insights',
        expanded: false,
        key: 'saved-streams',
        filterSelections: (function () {
          if (savedSearchStreams) {
            return savedSearchStreams.map((streamId) => {
              const savedStream = monitoringStreams.find(
                (stream) => stream.id === streamId.toString()
              );
              return {
                value: true,
                label: savedStream ? savedStream.name : 'Deleted Stream',
                constant: savedStream ? savedStream.id : 'deleted'
              };
            });
          } else {
            return [];
          }
        })(),
        options: (function () {
          const options = {};
          // options['Exclude All'] = false;
          monitoringStreams.map(
            (stream) =>
              (options[stream.name] =
                savedSearchStreams && savedSearchStreams.includes(stream.id)
                  ? true
                  : false)
          );
          if (
            savedSearchStreams &&
            savedSearchStreams.length === monitoringStreams.length
          ) {
            options['Select All'] = true;
          }
          return options;
        })()
      },
      channel: {
        label: 'Channel',
        name: 'Network & Sources',
        icon: 'ssi ssi-utm',
        expanded: false,
        key: 'network-and-sources',
        filterSelections:
          savedFilters && savedFilters.find(({ field }) => field === 'Channel')
            ? savedFilters
                .find(({ field }) => field === 'Channel')
                .in.map((filter) => ({
                  value: true,
                  label: filter
                }))
            : [],
        options: (function () {
          const channelFilter =
            savedFilters &&
            savedFilters.find(({ field }) => field === 'Channel');
          const options = {
            facebook: channelFilter
              ? channelFilter.in.includes('facebook')
                ? true
                : false
              : false,
            instagram: channelFilter
              ? channelFilter.in.includes('instagram_graph')
                ? true
                : false
              : false,
            instagram_graph_hashtag: channelFilter
              ? channelFilter.in.includes('instagram_graph_hashtag')
                ? true
                : false
              : false,
            linkedin: channelFilter
              ? channelFilter.in.includes('linkedin')
                ? true
                : false
              : false,
            twitter: channelFilter
              ? channelFilter.in.includes('twitter')
                ? true
                : false
              : false,
            youtube: channelFilter
              ? channelFilter.in.includes('youtube')
                ? true
                : false
              : false,
            livechat: channelFilter
              ? channelFilter.in.includes('livechat')
                ? true
                : false
              : false,
            moreover: channelFilter
              ? channelFilter.in.includes('moreover')
                ? true
                : false
              : false,
            nextdoor: channelFilter
              ? channelFilter.in.includes('nextdoor')
                ? true
                : false
              : false
          };
          return options;
        })()
      },
      messageType: {
        label: 'Message Type',
        icon: 'ssi ssi-comment',
        expanded: false,
        key: 'message-type',
        filterSelections: [],
        options: {
          public: false,
          private: false,
          short: false,
          long: false,
          verified: false
        }
      },
      industries: {
        title: 'X Industries',
        label: 'Industries',
        icon: 'ssi ssi-x-logo',
        expanded: false,
        key: 'industries',
        filterSelections:
          savedFilters &&
          savedFilters.find(({ field }) => field === 'Industries')
            ? savedFilters
                .find(({ field }) => field === 'Industries')
                .in.map((filter) => ({
                  value: true,
                  label: industries[filter].key,
                  constant: industries[filter].label
                }))
            : [],
        options: (function () {
          const options = {};
          if (savedFilters.find(({ field }) => field === 'Industries')) {
            savedFilters
              .find(({ field }) => field === 'Industries')
              .in.map((filter) => (options[filter] = true));
          }
          return options;
        })()
      },
      interests: {
        title: 'X Interests',
        label: 'Interests',
        icon: 'ssi ssi-x-logo',
        expanded: false,
        key: 'interests',
        filterSelections: savedFilters.find(
          ({ field }) => field === 'Interests'
        )
          ? savedFilters
              .find(({ field }) => field === 'Interests')
              .in.map((filter) => ({
                value: true,
                label: interests[filter].key,
                constant: interests[filter].label
              }))
          : [],
        options: (function () {
          const options = {};
          if (savedFilters.find(({ field }) => field === 'Interests')) {
            savedFilters
              .find(({ field }) => field === 'Interests')
              .in.map((filter) => (options[filter] = true));
          }
          return options;
        })()
      },
      isPrivate: {
        label: 'Visibility',
        icon: 'ssi ssi-visibility-filter',
        expanded: false,
        key: 'is-private',
        filterSelections: savedFilters
          .filter(({ field }) => field === 'Is Private')
          .map((filter) => ({
            value: true,
            label: filter.eq ? 'Private results' : 'Public results'
          })),
        options: {
          private: savedFilters
            .filter(({ field }) => field === 'Is Private')
            .some((filter) => (filter.eq ? true : false)),
          public: savedFilters
            .filter(({ field }) => field === 'Is Private')
            .some((filter) => (filter.eq ? false : true))
        }
      },
      gender: {
        label: 'Gender',
        icon: 'ssi ssi-genders',
        expanded: false,
        key: 'gender',
        filterSelections: savedFilters.find(({ field }) => field === 'Gender')
          ? savedFilters
              .find(({ field }) => field === 'Gender')
              .in.map((filter) => ({
                value: true,
                label: filter,
                constant: filter.charAt(0).toUpperCase() + filter.slice(1)
              }))
          : [],
        options: {
          male: savedFilters
            .filter(({ field }) => field === 'Gender')
            .some((filter) => (filter.in.includes('male') ? true : false)),
          female: savedFilters
            .filter(({ field }) => field === 'Gender')
            .some((filter) => (filter.in.includes('female') ? true : false))
        }
      },
      contentType: {
        label: 'Content Type',
        icon: 'ssi ssi-image-small',
        expanded: false,
        key: 'content-type',
        filterSelections: [],
        options: {
          image: false,
          video: false,
          link: false,
          text: false
        }
      },
      sentiment: {
        label: 'Sentiment',
        icon: 'ssi ssi-amend-sentiment-new',
        expanded: false,
        key: 'sentiment',
        filterSelections: savedFilters.find(
          ({ field }) => field === 'Sentiment'
        )
          ? savedFilters
              .find(({ field }) => field === 'Sentiment')
              .in.map((filter) => ({
                value: true,
                label: findSentimentConst('numericKey', filter).key2,
                constant: {
                  labelNew: findSentimentConst('numericKey', filter).labelNew
                }
              }))
          : [],
        options: (function () {
          const options = {};
          if (savedFilters.find(({ field }) => field === 'Sentiment')) {
            savedFilters
              .find(({ field }) => field === 'Sentiment')
              .in.map(
                (filter) =>
                  (options[
                    findSentimentConst('numericKey', filter).key2
                  ] = true)
              );
            // constant Iterable will fill in the gaps
          }
          return options;
        })()
      },
      emotion: {
        label: 'Emotion',
        icon: 'ssi ssi-emotion',
        expanded: false,
        key: 'emotion',
        filterSelections: savedFilters.find(({ field }) => field === 'Emotion')
          ? savedFilters
              .find(({ field }) => field === 'Emotion')
              .in.map((filter) => {
                return {
                  value: true,
                  label: filter,
                  constant: emotions[filter.toLowerCase()].label
                };
              })
          : [],
        options: (function () {
          const options = {};
          if (savedFilters.find(({ field }) => field === 'Emotion')) {
            savedFilters
              .find(({ field }) => field === 'Emotion')
              .in.map((filter) => (options[filter] = true));
          }
          return options;
        })()
      },
      location: {
        label: 'Location',
        icon: 'ssi ssi-location-filter',
        expanded: false,
        key: 'location',
        filterSelections: savedFilters
          .filter(
            ({ field }) =>
              field === LocationTier.Country ||
              field === LocationTier.State ||
              field === LocationTier.City ||
              field === LocationTier.Locality
          )
          .map((filter) => ({ value: filter.eq, label: filter.field })),
        options: {
          Country: savedFilters.find((f) => f.field === LocationTier.Country)
            ? {
                id: savedFilters.find((f) => f.field === LocationTier.Country)
                  .eq,
                label: savedFilters.find(
                  (f) => f.field === LocationTier.Country
                ).eq
              }
            : undefined,
          State: savedFilters.find((f) => f.field === LocationTier.State)
            ? {
                id: savedFilters.find((f) => f.field === LocationTier.State).eq,
                label: savedFilters.find((f) => f.field === LocationTier.State)
                  .eq
              }
            : undefined,
          City: savedFilters.find((f) => f.field === LocationTier.City)
            ? {
                id: savedFilters.find((f) => f.field === LocationTier.City).eq,
                label: savedFilters.find((f) => f.field === LocationTier.City)
                  .eq
              }
            : undefined,
          Locality: savedFilters.find((f) => f.field === LocationTier.Locality)
            ? {
                id: savedFilters.find((f) => f.field === LocationTier.Locality)
                  .eq,
                label: savedFilters.find(
                  (f) => f.field === LocationTier.Locality
                ).eq
              }
            : undefined
          // will be populated via `insightsFiltersTopLocations` API
        }
      },
      influencers: {
        title: 'X Influencers',
        label: 'Author Followers',
        icon: 'ssi ssi-x-logo',
        expanded: false,
        key: 'influencers',
        filterSelections:
          savedFilters &&
          savedFilters.find(({ field }) => field === 'Author Followers')
            ? [
                savedFilters &&
                  savedFilters.find(({ field }) => field === 'Author Followers')
              ].map((filter) => ({
                label: filter.range.gte
                  ? filter.range.lte
                    ? `Author followers more than ${filter.range.gte} and less than ${filter.range.lte}`
                    : `Author followers more than ${filter.range.gte}`
                  : `Author followers less than ${filter.range.lte}`,
                start: filter.range.gte,
                end: filter.range.lte
              }))[0]
            : {}
      },
      language: {
        label: 'Language',
        icon: 'ssi ssi-language-test',
        expanded: false,
        key: 'language',
        filterSelections: savedFilters.find(({ field }) => field === 'Language')
          ? savedFilters
              .find(({ field }) => field === 'Language')
              .in.map((filter) => ({
                value: true,
                label: LANGUAGES[filter],
                id: filter
              }))
          : []
      },
      age: {
        label: 'Age',
        icon: 'ssi ssi-age-range',
        expanded: false,
        key: 'age',
        filterSelections: savedFilters.find(({ field }) => field === 'Age')
          ? savedFilters
              .find(({ field }) => field === 'Age')
              .in.map((filter) => ({
                value: true,
                id: filter,
                label: ageOptions[filter].label,
                constant: filter
              }))
          : [],
        options: (function () {
          const options = {};
          // constant Iterable will fill in the gaps
          return options;
        })()
      },
      tags: {
        label: 'Tags',
        icon: 'ssi ssi-tag-test',
        expanded: false,
        key: 'tags',
        filterSelections: savedFilters.find(({ field }) => field === 'Tags')
          ? savedFilters
              .find(({ field }) => field === 'Tags')
              .in.map((filter) => ({
                value: true,
                label: filter
              }))
          : []
      },
      assignedTo: {
        label: 'Assigned User',
        icon: 'ssi ssi-assigned-small',
        expanded: false,
        key: 'assigned',
        filterSelections: savedFilters.find(
          ({ field }) => field === 'Assigned User'
        )
          ? savedFilters
              .find(({ field }) => field === 'Assigned User')
              .in.map((filter) => ({
                value: true,
                label: filter.toString()
              }))
          : [],
        options: (function () {
          const options = {};
          if (savedFilters.find(({ field }) => field === 'Assigned User')) {
            savedFilters
              .find(({ field }) => field === 'Assigned User')
              .in.map((filter) => (options[filter] = true));
          }
          return options;
        })()
      },
      author: {
        label: 'Author',
        icon: 'ssi ssi-compose-post',
        expanded: false,
        key: 'author',
        filterSelections: savedFilters.find(
          ({ field }) => field === 'Author Name'
        )
          ? savedFilters
              .find(({ field }) => field === 'Author Name')
              .in.map((filter) => ({
                value: filter,
                label: filter
              }))
          : []
      },
      bioKeywords: {
        label: 'Bio Keywords',
        icon: 'ssi ssi-field-of-study',
        expanded: false,
        key: 'biokeywords',
        filterSelections: []
      },
      country: {
        label: 'Country',
        icon: 'ssi ssi-industry',
        expanded: false,
        key: 'country',
        filterSelections: []
      },
      city: {
        label: 'City',
        icon: 'ssi ssi-industry',
        expanded: false,
        key: 'city',
        filterSelections: []
      }
    };
  }

  calculateDateRange(dateFilter) {
    const filterSelection = {
      label: 'Last 30 Days',
      start: this.dateRanges.month.startString,
      end: this.dateRanges.month.endString
    };
    if (!dateFilter) {
      return filterSelection;
    }
    switch (dateFilter.range.gte) {
      case 'midnight':
        filterSelection.label = 'Today';
        break;
      case '-1 day midnight':
        filterSelection.label = 'Yesterday';
        break;
      case '-7 days':
        filterSelection.label = 'Last 7 Days';
        break;
      case '-30 days':
        filterSelection.label = 'Last 30 Days';
        break;
      case '-3 months':
        filterSelection.label = 'Last quarter';
        break;
      case '-1 year':
        filterSelection.label = 'Last year';
        break;
      default:
        filterSelection.label = `Listening period: ${format(
          dateFilter.range.gte,
          'DD/MM/YY HH:mm'
        )} - ${format(dateFilter.range.lte, 'DD/MM/YY HH:mm')}`;
        break;
    }
    filterSelection.start = dateFilter.range.gte;
    filterSelection.end = dateFilter.range.lte;
    return filterSelection;
  }
  /**
   * Iterate through filters object values and only extract the active filters to be passed to the API for the actual search
   */
  async getOnlyActiveFilters(filters: InsightsFilter) {
    console.log('getOnlyActiveFilters filters:', filters);
    const colleagues = await this.colleaguesService.getAll();
    const activeFilters: Array<any> = [];

    for (const filter in filters) {
      if (filter === 'listeningPeriod') {
        if (filters[filter].dateFilterSelections.label === null) {
          continue;
        }
        activeFilters.push({
          key: filter,
          label: filters[filter].dateFilterSelections.label,
          field: 'Created At',
          range: {
            gte: filters[filter].dateFilterSelections.start,
            lte: filters[filter].dateFilterSelections.end
          }
        });
      } else if (filter === 'influencers') {
        if (
          Object.keys(filters[filter].filterSelections).length > 0 &&
          Object.values(filters[filter].filterSelections).some((val) => !!val)
        ) {
          activeFilters.push({
            key: filter,
            field: filters[filter].label,
            label: filters[filter].filterSelections.start
              ? filters[filter].filterSelections.end
                ? `Author followers more than ${filters[filter].filterSelections.start} and less than ${filters[filter].filterSelections.end}`
                : `Author followers more than ${filters[filter].filterSelections.start}`
              : `Author followers less than ${filters[filter].filterSelections.end}`,
            range: {
              gte: filters[filter].filterSelections.start,
              lte: filters[filter].filterSelections.end
            }
          });
        }
      } else if (filter === 'sentiment') {
        filters[filter].filterSelections.map((filterSelection) => {
          activeFilters.push({
            key: filter,
            field: filters[filter].label,
            label: `${filters[filter].label}: ${this.translate.instant(
              filterSelection.constant.labelNew
            )}`,
            all: filterSelection.label
          });
        });
      } else if (filter === 'accounts') {
        filters[filter].filterSelections.map((filterSelection) => {
          activeFilters.push({
            key: filter,
            field: filters[filter].label,
            label: `Inbox Account: ${filterSelection.label}`,
            all: isNaN(parseInt(filterSelection.constant, 10))
              ? filterSelection.constant
              : parseInt(filterSelection.constant, 10)
          });
        });
      } else if (filter === 'savedStreams') {
        filters[filter].filterSelections.map((filterSelection) => {
          activeFilters.push({
            key: filter,
            field: filters[filter].label,
            label: `Saved Streams: ${filterSelection.label}`,
            all: filterSelection.constant
          });
        });
      } else if (filter === 'assignedTo') {
        filters[filter].filterSelections.map((filterSelection) => {
          const colleague = colleagues.find(
            (c) => c.id === filterSelection.label
          );
          activeFilters.push({
            key: filter,
            field: filters[filter].label,
            label: `${filters[filter].label}: ${colleague.fullName}`,
            all: Number(colleague.id)
          });
        });
      } else if (filter === 'location') {
        filters[filter].filterSelections.map((filterSelection) => {
          activeFilters.push({
            key: filter,
            field: filterSelection.label,
            label: `Location (${filterSelection.label}): ${filterSelection.value}`,
            eq: filterSelection.value
          });
        });
      } else if (filter === 'age') {
        filters[filter].filterSelections.map((filterSelection) => {
          activeFilters.push({
            key: filter,
            field: 'Age',
            label: `Age: ${filterSelection.label}`,
            in: parseInt(filterSelection.id, 10)
          });
        });
      } else if (filter === 'language') {
        filters[filter].filterSelections.map((filterSelection) => {
          activeFilters.push({
            key: filter,
            field: 'Language',
            label: `Language: ${filterSelection.label}`,
            in: filterSelection.id
          });
        });
      } else if (filter === 'channel') {
        filters[filter].filterSelections.map((filterSelection) => {
          const foundFilter = networksWithInsightsSources.find(
            (channel) => filterSelection.label === channel.key
          );
          activeFilters.push({
            key: filter,
            field: 'Channel',
            label: `Channel: ${foundFilter.sourceLabel}`,
            in: foundFilter.key
          });
        });
      } else if (filter === 'author') {
        filters[filter].filterSelections.map((filterSelection) => {
          activeFilters.push({
            key: filter,
            field: 'Author',
            label: `Author: ${filterSelection.label}`,
            in: filterSelection.value
          });
        });
      } else {
        filters[filter].filterSelections.map((filterSelection) => {
          activeFilters.push({
            key: filter,
            field: filters[filter].label,
            label: `${filters[filter].label}: ${
              filterSelection.constant
                ? filterSelection.constant
                : filterSelection.label
            }`,
            all: filterSelection.label
          });
        });
      }
    }
    return activeFilters;
  }
}
