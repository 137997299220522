import './dropdown-select-2.component.scss';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  AfterContentInit,
  ContentChildren,
  QueryList,
  forwardRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  DoCheck,
  ViewChild
} from '@angular/core';
import { animate, style, trigger, transition } from '@angular/animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TemplateSelectorDirective } from '../../directives/template-selector/template-selector.directive';
import { KeyValueObject, groupBy } from '../../utils';

export class DDTemplateSelectors {
  static readonly headButton = 'headButton';
  static readonly headButtonLabel = 'headButtonLabel';
  static readonly expandedContent = 'expandedContent';
  static readonly optionLabel = 'optionLabel';
  static readonly optionIcon = 'optionIcon';
  static readonly emptyState = 'emptyState';
  static readonly bodyHeader = 'bodyHeader';
  static readonly bodyFooter = 'bodyFooter';
}

export interface Option {
  id?: string;
  key?: string | any; // any because enum can be used

  name?: string;
  label?: string;
  displayName?: string;
  icon?: string; // e.g. ssi-author
  [key: string]: any;
}

export interface GroupBy {
  key: string;
  getGroupLabel?: (key: number | string) => string;
  toggleAllEnabled?: boolean;
}

export interface Group {
  label: string;
  options: Option[];
  toggleAllChecked: boolean;
}

@Component({
  selector: 'ssi-dropdown-select-2',
  templateUrl: './dropdown-select-2.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelect2Component), //tslint:disable-line
      multi: true
    }
  ],
  animations: [
    trigger('slideFadeIn', [
      transition('void => *', [
        style({ opacity: 0, width: '0', height: '0' }),
        animate(
          '120ms ease-out',
          style({ opacity: 1, width: '*', height: '*' })
        )
      ])
    ])
  ]
})
export class DropdownSelect2Component
  implements
    DoCheck,
    OnChanges,
    OnInit,
    AfterContentInit,
    AfterViewInit,
    ControlValueAccessor {
  @Input() options: Option[] = [];
  @Input() filteredOptions: Option[] = [];
  @Input() size: 'sm' | 'md' | 'lg' | 'fit' = 'md';
  @Input() headButtonIcon = 'ssi-arrow-down-new';
  @Input() headButtonStyleVariant: 'default' | 'analytics' = 'default';
  @Input() yPlacement: 'top' | 'bottom' | '' = 'top'; // if '' the body will be placed below the head button
  @Input() yOffset = '0px';
  @Input() xPlacement: 'left' | 'right' = 'left';
  @Input() xOffset = '0px';
  @Input() templateRefs: any = {};
  @Input() headless = false;
  @Input() multiple = false;
  @Input() toggleAllEnabled = true;
  @Input() filterable = false;
  @Input() filterFn: (term: string) => Option[];
  @Input() disabled = false;
  @Input() canDeselect = false; // single select mode
  @Input() closeOnSelect = true; // single select mode
  @Input() placeholdersKeyword = ''; // if the keyword passed is accounts then the placeholder will be 'Select/Search accounts...', '8 (selected) accounts', etc.
  @Input() filterPlaceholder = ''; // 'Search...'
  @Input() public filterTerm = ''; // accessed outside of this component
  @Input() selectedMin = 0; // minimum number of items selected (multiple select mode)
  @Input() selectedMax; // maximum number of items selected (multiple select mode)
  @Input() toggleAllLabel = ''; // 'Select all'
  @Input() bodyStyle: KeyValueObject = {};
  @Input() getOptionLabel: (option: any) => string;
  @Input() groupBy?: GroupBy;
  @Input() getTooltipContent?: (option: Option) => string;

  @Output() toggled = new EventEmitter<boolean>();
  @Output() optionsFiltered = new EventEmitter<{
    filterTerm: string;
    filteredOptions: Option[];
  }>();

  @ViewChild('ulRef') ulRef: ElementRef;
  @ViewChild('filterInputRef') filterInputRef: ElementRef;
  @ContentChildren(TemplateSelectorDirective)
  templateList: QueryList<TemplateSelectorDirective>;

  DDTemplateSelectors = DDTemplateSelectors;
  modelValue: Option | Option[] | any; // 'any' so TS is happy
  expanded = false;
  toggleAllChecked = false;
  groups: Group[] = [];
  isAppleDevice =
    /^iP/.test(navigator.platform) || /^Mac/.test(navigator.platform);

  constructor(
    protected elementRef: ElementRef,
    protected changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hide();
    }
  }

  ngDoCheck() {
    this.toggleAllChecked = this.allOptionsSelected();
    if (this.groupBy) {
      this.groups.forEach((group) => {
        group.toggleAllChecked = group.options.every((o) =>
          this.optionSelected(o)
        );
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options'] && Array.isArray(this.options)) {
      this.filter(this.filterTerm);

      if (this.multiple) {
        this.modelValue = this.modelValue || [];
        if (!Array.isArray(this.modelValue)) {
          throw new Error(
            'Multi select variant requires ngModel to be an array.'
          );
        }
      }
    }
  }

  ngOnInit() {}

  ngAfterContentInit() {
    this.collectTemplateRefs();
  }

  ngAfterViewInit(): void {}

  show(event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }

    if (this.expanded) {
      return;
    }

    if (this.disabled) {
      return;
    }

    this.expanded = true;
    this.changeDetectorRef.detectChanges();
    if (this.filterInputRef) {
      setTimeout(() => {
        // timeout needed cuz when using a key to open the dropdown then the filter input gets populated with the key used to open the dropdown
        // timeout fixes that - otherwise wouldn't be needed
        this.filterInputRef.nativeElement.focus();
      });
    }

    this.toggled.emit(true);
  }

  hide(): void {
    if (!this.expanded) {
      return;
    }

    this.expanded = false;
    this.toggled.emit(false);
  }

  toggle = (): void => {
    this.expanded ? this.hide() : this.show();
  };

  findOptionLabel(option: Option): string {
    return (
      (option && (option.name || option.label || option.displayName)) || ''
    );
  }

  optionSelected(option: Option): boolean {
    return this.multiple
      ? Array.isArray(this.modelValue) && this.modelValue.includes(option)
      : this.modelValue === option;
  }

  allOptionsSelected(): boolean {
    return (
      Array.isArray(this.options) &&
      this.options.length &&
      !this.options.some((o) => !this.optionSelected(o))
    );
  }

  noOptionSelected(): boolean {
    return !this.options.some((o) => this.optionSelected(o));
  }

  selectOption(option: Option): void {
    if (this.multiple) {
      if (this.optionSelected(option)) {
        if (this.selectedMin && this.modelValue.length === this.selectedMin) {
          console.log(`At least ${this.selectedMin} needs to be selected!`);
          return;
        }
        this.modelValue = this.modelValue.filter(
          (modelOption) => modelOption !== option
        ); // this.toggleAllChecked = false;
      } else {
        if (this.selectedMax && this.modelValue.length === this.selectedMax) {
          console.log(`No more than ${this.selectedMax} to be selected!`);
          return;
        }
        // this.modelValue.push(option);
        this.modelValue = [...this.modelValue, option];
        // if (this.allOptionsSelected()) {
        //   this.toggleAllChecked = true;
        // }
      }
    } else {
      if (this.optionSelected(option)) {
        if (this.canDeselect) {
          this.modelValue = undefined;
          if (this.closeOnSelect) {
            this.hide();
          }
        }
      } else {
        this.modelValue = option;
        if (this.closeOnSelect) {
          this.hide();
        }
      }
    }

    this.onChangeCallback(this.modelValue);
  }

  toggleAllOptions(checked: boolean): void {
    if (!this.multiple) {
      return;
    }

    if (this.toggleAllChecked) {
      this.options.forEach((o) => {
        if (this.modelValue.indexOf(o) === -1) {
          this.modelValue.push(o);
        }
      });
    } else {
      this.options.forEach((o) => {
        const index = this.modelValue.indexOf(o);
        if (index > -1) {
          this.modelValue.splice(index, 1);
        }
      });
    }

    this.onChangeCallback(this.modelValue);
  }

  toggleAllGroupOptions(checked: boolean, group: Group): void {
    if (group.toggleAllChecked) {
      group.options.forEach((o) => {
        if (this.modelValue.indexOf(o) === -1) {
          this.modelValue.push(o);
        }
      });
    } else {
      group.options.forEach((o) => {
        const index = this.modelValue.indexOf(o);
        if (index > -1) {
          this.modelValue.splice(index, 1);
        }
      });
    }

    this.onChangeCallback(this.modelValue);
  }

  writeValue(value: any): void {
    this.modelValue = value;
  }

  private onChangeCallback: (_: any) => void = () => {};

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  private onTouchCallback: () => void = () => {};
  registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  filter(filterTerm: string): void {
    if (typeof this.filterFn === 'function') {
      this.filteredOptions = this.filterFn(filterTerm);
    } //
    else {
      if (!Array.isArray(this.options)) {
        this.filteredOptions = [];
        this.groups = [];
        return;
      }

      this.filteredOptions = this.options.filter((option: any) => {
        if (typeof option === 'object') {
          return Object.values(option).some(
            (value) =>
              typeof value === 'string' &&
              value.toLowerCase().includes(filterTerm.toLowerCase())
          );
        } else {
          return option.toString().startsWith(filterTerm.toString());
        }
      });
    }

    if (this.groupBy) {
      const groupsMap = groupBy(
        this.filteredOptions,
        (account) => account[this.groupBy.key]
      );

      this.groups = [];
      for (const [key, value] of groupsMap.entries()) {
        this.groups.push({
          label: this.groupBy.getGroupLabel
            ? this.groupBy.getGroupLabel(key)
            : key,
          options: value,
          toggleAllChecked: false
        });
      }
    }

    this.optionsFiltered.emit({
      filterTerm,
      filteredOptions: this.filteredOptions
    });
  }

  optionsExpandable(): boolean {
    return !!this.templateRefs[this.DDTemplateSelectors.expandedContent];
  }

  collectTemplateRefs(): void {
    this.templateList.toArray().forEach((t: TemplateSelectorDirective) => {
      if (!this.DDTemplateSelectors[t.selector]) {
        console.error(
          `Unknown template selector: ${
            t.selector
          }. Possible value/s: ${Object.values(this.DDTemplateSelectors).join(
            ', '
          )}.`
        );
        return;
      }

      this.templateRefs[t.selector] = t.templateRef;
    });
  }
}
