import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef
} from '@angular/core';
import { API } from '@ui-resources-angular';
import './competitor-report.component.scss';
import { StateService } from '@uirouter/angular';
import moment from 'moment';
import Highcharts from 'highcharts';
import { isElementWithinViewport } from '../../../../../common/utils';
import {
  Report,
  reportsFn,
  competitorsFn,
  ProfileGroup,
  CompetitorProfile
} from '../competitor-analysis.component';
import { CompanyService } from '../../../../../common/services/company/company.service';
import { Debounce } from '../../../../../common/decorators';

enum PostActivityHeaders {
  avgInteractions = 'Average Engagement',
  postFreq = 'Average Daily Posts',
  interactionRate = 'Engagement Rate'
}

@Component({
  selector: 'ssi-competitor-report',
  templateUrl: './competitor-report.component.html',
  styles: []
})
export class CompetitorReportComponent implements OnInit {
  static resolve = [
    {
      token: 'reports',
      resolveFn: reportsFn,
      deps: [API, CompanyService]
    },
    {
      token: 'competitors',
      resolveFn: competitorsFn,
      deps: [API]
    }
  ];
  reportActions = [
    {
      icon: 'ssi-print',
      label: 'Export PDF',
      func: this.openBrowserPrint
    },
    {
      icon: 'ssi-edit',
      label: 'Edit report',
      func: () => {
        this.state.go('^.reportEdit', { reportId: this.reportId });
      }
    },
    {
      icon: 'ssi-line-arrow-small-down',
      label: 'Back to reports',
      func: () => {
        this.state.go('^.index');
      }
    }
  ];
  navItems = [];
  activeNavItemTag = 'overview';
  competitorStats: ProfileGroup[] = [];
  reportName: string;
  reportId: string;
  now = new Date();
  reportStartDate: string;
  reportEndDate: string;
  focusId: number;
  loading = true;
  statsModel = {
    total: {
      overview: {
        topMediaType: { text: 0, image: 0, video: 0 },
        avgInteractions: 0,
        postFreq: 0,
        interactionRate: 0
      },
      mediaTypeGraphs: {},
      overviewGraphs: {
        avgInteractions: {},
        postFreq: {},
        interactionRate: {}
      },
      audienceCountGraphs: {},
      audienceGrowthGraphs: {},
      topPosts: {},
      topTags: {},
      topWords: {},
      audienceCount: {},
      audienceGrowth: {},
      audienceGrowthRate: {},
      audienceGrowthCount: {}
    },
    networks: []
  };
  overviewKey = 'avgInteractions';
  overviewNetwork = 'total';
  mediaTypeNetwork = 'total';
  topPostNetwork = '3';
  topTagNetwork = 'total';
  topWordNetwork = 'total';
  audienceCountNetwork = '3';
  audienceGrowthNetwork = '3';
  postBreakdownNetwork = 'total';

  totalAverages = {
    avgInteractions: 0,
    postFreq: 0,
    interactionRate: 0
  };

  @Input() reports: Report[];
  @Input() competitors: any;

  constructor(
    private api: API,
    private state: StateService,
    protected elementRef: ElementRef
  ) {}

  async ngOnInit() {
    this.reportName = this.state.params.reportName;
    this.reportId = this.state.params.reportId;
    this.reportStartDate = moment(new Date())
      .subtract(1, 'month')
      .format('YYYY-MM-DD');
    this.reportEndDate = moment(this.now)
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
    const currentReport = this.reports.find(
      (report) => report.id === this.reportId
    );
    this.focusId = currentReport.focus_id;

    await this.runReport(this.reportStartDate, this.reportEndDate);
    this.navItems = this.getNavItems();
    this.assignTotalAverages();
    this.populateNavItemElements();
  }

  private async runReport(start, end, term?) {
    this.loading = true;

    const params = {
      report_id: this.state.params.reportId,
      start,
      end
    };
    if (term) {
      params['q'] = term;
    }
    await this.api
      .post('competitiveAnalysis/runReport', params)
      .then(({ data }) => {
        this.competitorStats = data.profile_groups;
        this.competitorStats = this.competitorStats.map((competitor) => {
          competitor.colour =
            competitor.id === this.focusId ? '#425DEC' : competitor.colour;
          // competitor.socialScore = this.generateSocialScore(competitor.id);
          return competitor;
        });
        this.formatStats(start, end, data.profile_groups);
        this.sortStatsBy('socialScore');
      });
  }

  reportKeywordInput(term: string) {
    this.runReport(this.reportStartDate, this.reportEndDate, term);
  }

  reportDateSelected({ from, to }) {
    this.runReport(
      moment(from).format('YYYY-MM-DD'),
      moment(to).format('YYYY-MM-DD')
    );
  }

  formatStats(start, end, competitorStats) {
    competitorStats.forEach(({ profiles, id, name, colour }) => {
      this.statsModel[id] = {
        overview: {
          total: {
            topMediaType: { text: 0, image: 0, video: 0 },
            avgInteractions: 0,
            postFreq: 0,
            interactionRate: 0,
            countMessages: 0,
            sumInteractions: 0,
            engagementRate: 0
          }
        },
        mediaTypeGraphs: {},
        overviewGraphs: {},
        audienceCountGraphs: {},
        audienceGrowthGraphs: {},
        topPosts: {},
        topTags: {
          total: []
        },
        topWords: {
          total: []
        },
        topWordsGraphs: {},
        audienceCount: {
          total: {}
        },
        audienceGrowth: {},
        audienceGrowthRate: {
          total: 0
        },
        audienceGrowthCount: {},
        totalFollowerCount: {}
      };

      profiles.forEach((stats: CompetitorProfile) => {
        this.statsModel.networks.push(stats.account_type_id);

        // Overview //
        const statOverview = {
          topMediaType: stats.top_media_type,
          avgInteractions: isNaN(stats.sum_interactions / stats.count_messages)
            ? 0
            : stats.sum_interactions / stats.count_messages,
          postFreq: stats.posts_per_day,
          interactionRate: isNaN(
            stats.sum_interactions /
              stats.count_messages /
              stats.audience_count[end]
          )
            ? 0
            : (stats.sum_interactions /
                stats.count_messages /
                stats.audience_count[end]) *
              100,
          countMessages: stats.count_messages,
          sumInteractions: stats.sum_interactions,
          engagementRate:
            (stats.sum_likes + stats.sum_shares) / stats.audience_count[end]
        };
        this.statsModel[id]['overview'][stats.account_type_id] = statOverview;
        this.statsModel[id].overview.total = deepMergeSum(
          this.statsModel[id]['overview'].total,
          statOverview
        );

        // Graphs //
        this.statsModel[id]['mediaTypeGraphs'][
          stats.account_type_id
        ] = this.formatDataForMediaTypes(statOverview.topMediaType);
        this.statsModel[id][
          'mediaTypeGraphs'
        ].total = this.formatDataForMediaTypes(
          this.statsModel[id]['overview'].total.topMediaType
        );

        // Audience //
        this.statsModel[id]['totalFollowerCount'][stats.account_type_id] =
          stats.total_follower_count;
        this.statsModel[id]['audienceCountGraphs'][
          stats.account_type_id
        ] = this.formatDataForFollowerCount(stats.audience_count, name, colour);

        this.statsModel[id]['audienceCount'][stats.account_type_id] =
          stats.audience_count;
        this.statsModel[id]['audienceGrowth'][stats.account_type_id] =
          stats.audience_change;
        this.statsModel[id].audienceCount.total = this.statsModel[
          id
        ].audienceCount.total.hasOwnProperty(end)
          ? deepMergeSum(
              this.statsModel[id].audienceCount.total,
              stats.audience_count
            )
          : stats.audience_count;

        // Get startDate of filter selection if available in data otherwise get first instance of data
        const startDate = this.statsModel[id].audienceCount[
          stats.account_type_id
        ][start]
          ? this.statsModel[id].audienceCount[stats.account_type_id][start]
          : this.statsModel[id].audienceCount[stats.account_type_id][
              Object.keys(
                this.statsModel[id].audienceCount[stats.account_type_id]
              )[0]
            ];
        this.statsModel[id]['audienceGrowthRate'][stats.account_type_id] =
          ((this.statsModel[id].audienceCount[stats.account_type_id][end] -
            startDate) /
            startDate) *
          100;
        this.statsModel[id]['audienceGrowthRate'].total += this.statsModel[id][
          'audienceGrowthRate'
        ][stats.account_type_id];

        this.statsModel[id]['audienceGrowthCount'][stats.account_type_id] =
          this.statsModel[id].audienceCount[stats.account_type_id][end] -
          this.statsModel[id].audienceCount[stats.account_type_id][start];

        // Top Posts //
        const postArray = stats.top_posts.map((post) => {
          post.avatar = stats.avatar_url;
          post.type = stats.account_type_id;
          post.username = stats.name;
          return post;
        });
        this.statsModel[id]['topPosts'][stats.account_type_id] = postArray;
        if (
          !Array.isArray(this.statsModel.total.topPosts[stats.account_type_id])
        ) {
          this.statsModel.total.topPosts[stats.account_type_id] = [];
        }
        this.statsModel.total.topPosts[
          stats.account_type_id
        ] = this.statsModel.total.topPosts[stats.account_type_id].concat(
          postArray
        );

        // Top Tags & Top Words //
        if (
          !Array.isArray(
            this.statsModel.total.topTags[stats.account_type_id]
          ) &&
          !Array.isArray(this.statsModel.total.topWords[stats.account_type_id])
        ) {
          this.statsModel.total.topTags[stats.account_type_id] = [];
          this.statsModel.total.topWords[stats.account_type_id] = [];
        }
        this.statsModel[id]['topTags'][stats.account_type_id] = [];
        this.statsModel[id]['topWords'][stats.account_type_id] = [];

        Object.keys(stats.top_tags).forEach((key) => {
          this.statsModel[id]['topTags'][stats.account_type_id].push({
            word: key,
            count: stats.top_tags[key]
          });
        });
        Object.keys(stats.top_words).forEach((key) => {
          this.statsModel[id]['topWords'][stats.account_type_id].push({
            name: key,
            weight: stats.top_words[key]
          });
        });
        this.statsModel[id]['topTags'][stats.account_type_id].sort((a, b) =>
          a.count < b.count ? 1 : -1
        );
        this.statsModel[id]['topWords'][stats.account_type_id].sort((a, b) =>
          a.weight < b.weight ? 1 : -1
        );

        this.statsModel[id].topWords.total = this.statsModel[
          id
        ].topWords.total.concat(
          this.statsModel[id]['topWords'][stats.account_type_id]
        );
        // this.statsModel[id].topWords.total = dedupeArray(
        //   this.statsModel[id].topWords.total,
        //   'name'
        // );
        this.statsModel[id].topWords.total.sort((a, b) =>
          a.weight < b.weight ? 1 : -1
        );

        this.statsModel[id].topTags.total = this.statsModel[
          id
        ].topTags.total.concat(
          this.statsModel[id]['topTags'][stats.account_type_id]
        );
        this.statsModel[id].topTags.total = dedupeArray(
          this.statsModel[id].topTags.total,
          'word'
        );
        this.statsModel[id].topTags.total.sort((a, b) =>
          a.count < b.count ? 1 : -1
        );

        this.statsModel[id]['topWordsGraphs'][
          stats.account_type_id
        ] = this.formatDataForTopWords(
          this.statsModel[id]['topWords'][stats.account_type_id],
          name,
          colour
        );
        this.statsModel[id][
          'topWordsGraphs'
        ].total = this.formatDataForTopWords(
          this.statsModel[id]['topWords'].total,
          name,
          colour
        );
      });

      // Works out correct calculations for all networks //
      const totalOverview = this.statsModel[id].overview.total;
      totalOverview.avgInteractions = isNaN(
        totalOverview.sumInteractions / totalOverview.countMessages
      )
        ? 0
        : totalOverview.sumInteractions / totalOverview.countMessages;
      totalOverview.interactionRate = isNaN(
        totalOverview.avgInteractions /
          this.statsModel[id].audienceCount.total[end]
      )
        ? 0
        : (totalOverview.avgInteractions /
            this.statsModel[id].audienceCount.total[end]) *
          100;

      this.generateSocialScore(id);
    });

    this.statsModel.networks = [...new Set(this.statsModel.networks)];

    // Puts all competitors into single graph //
    this.statsModel.networks.forEach((networkId) => {
      // this.statsModel.total.audienceCountGraphs[
      //   networkId
      // ] = this.formatDataForAudienceCount(networkId);

      this.statsModel.total.audienceGrowthGraphs[
        networkId
      ] = this.formatDataForAudienceGrowth(networkId);

      this.statsModel.total.overviewGraphs['avgInteractions'][
        networkId
      ] = this.formatDataForOverview(
        'avgInteractions',
        networkId,
        'Avg. Interactions'
      );
      this.statsModel.total.overviewGraphs['postFreq'][
        networkId
      ] = this.formatDataForOverview('postFreq', networkId, 'Avg. Posts');
      this.statsModel.total.overviewGraphs['interactionRate'][
        networkId
      ] = this.formatDataForOverview(
        'interactionRate',
        networkId,
        'Interaction Rate'
      );
    });
    this.statsModel.total.overviewGraphs['avgInteractions'][
      'total'
    ] = this.formatDataForOverview(
      'avgInteractions',
      'total',
      'Avg. Interactions'
    );
    this.statsModel.total.overviewGraphs['postFreq'][
      'total'
    ] = this.formatDataForOverview('postFreq', 'total', 'Avg. Posts');
    this.statsModel.total.overviewGraphs['interactionRate'][
      'total'
    ] = this.formatDataForOverview(
      'interactionRate',
      'total',
      'Interaction Rate'
    );

    function dedupeArray(arr, key) {
      const duplicateIds = arr
        .map((e) => e[key])
        .map((e, i, final) => final.indexOf(e) !== i && i)
        .filter((obj) => arr[obj])
        .map((e) => arr[e][key]);

      duplicateIds.map((id) => {
        const dupes = arr.filter((obj) => id === obj.word);
        const total = { [key]: id, count: 0 };
        for (const dupe of dupes) {
          total.count += dupe.count;
        }
        arr = arr.filter((obj) => obj[key] !== total[key]);
        arr.push(total);
      });
      return arr;
    }

    function deepMergeSum(obj1, obj2) {
      return Object.keys(obj1).reduce((acc, key) => {
        if (typeof obj2[key] === 'object') {
          acc[key] = deepMergeSum(obj1[key], obj2[key]);
        } else if (obj2.hasOwnProperty(key) && !isNaN(parseFloat(obj2[key]))) {
          acc[key] = obj1[key] + obj2[key];
        }
        return acc;
      }, {});
    }
    this.loading = false;
  }

  isTopPlace(prop, competitor, network, overview = false) {
    const compare = [];
    this.competitorStats.map(({ id }) => {
      return overview
        ? this.statsModel[id]['overview'][network]
          ? compare.push(this.statsModel[id]['overview'][network][prop])
          : compare.push(0)
        : compare.push(this.statsModel[id][prop][network]);
    });
    if (!this.statsModel[competitor]['overview'][network]) {
      return false;
    }
    return overview
      ? this.statsModel[competitor]['overview'][network][prop] ===
        Math.max(...compare)
        ? true
        : false
      : this.statsModel[competitor][prop][network] === Math.max(...compare)
      ? true
      : false;
  }

  sortStatsBy(prop: string) {
    return this.competitorStats.sort((a, b) =>
      a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }

  generateSocialScore(id) {
    const index = this.competitorStats.findIndex(
      (competitor) => competitor.id === id
    );
    const profilesCount = this.competitorStats[index].profiles.length;

    const MIN_VALUE = 0.0001;

    const audienceGrowthRate =
      this.statsModel[id].audienceGrowthRate.total > 0
        ? this.statsModel[id].audienceGrowthRate.total
        : MIN_VALUE;

    const engagementRate =
      this.statsModel[id].overview.total.engagementRate > 0
        ? this.statsModel[id].overview.total.engagementRate
        : MIN_VALUE;

    const postFreq =
      this.statsModel[id].overview.total.postFreq > 0
        ? this.statsModel[id].overview.total.postFreq
        : MIN_VALUE;

    const fcs = 234 + 31.6 * Math.log(audienceGrowthRate);
    const es = 251 + 26.3 * Math.log(engagementRate);
    const ppds = 60 + 45 * Math.log(postFreq);

    const socialScore =
      (0.25 * Math.max(0, Math.min(300, fcs))) / profilesCount +
      (0.5 * Math.max(0, Math.min(300, es))) / profilesCount +
      (0.25 * Math.max(0, Math.min(300, ppds))) / profilesCount;

    this.competitorStats[index].socialScore = Math.floor(socialScore);
  }

  changeOverviewNetwork(overviewNetwork: string) {
    this.overviewNetwork = overviewNetwork;
    this.assignTotalAverages();
  }

  assignTotalAverages() {
    this.totalAverages = {
      avgInteractions: this.calculateAvg(
        this.overviewNetwork,
        'avgInteractions'
      ),
      postFreq: this.calculateAvg(this.overviewNetwork, 'postFreq'),
      interactionRate: this.calculateAvg(
        this.overviewNetwork,
        'interactionRate'
      )
    };
  }

  calculateAvg(networkId: string, prop: string) {
    let sum = 0;
    this.competitorStats.map(({ id }) => {
      sum =
        sum +
        (this.statsModel[id]['overview'][networkId]
          ? this.statsModel[id]['overview'][networkId][prop]
          : 0);
    });
    return sum / this.competitorStats.length;
  }

  private formatDataForMediaTypes(mediaTypes) {
    Highcharts.setOptions({
      colors: ['#A7ACDA', '#E27F4F', '#33B6D6']
    });
    Highcharts.setOptions({
      colors: Highcharts.map(Highcharts.getOptions().colors, (color) => {
        return {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7
          },
          stops: [
            [0, color],
            [1, (Highcharts.Color as any)(color).brighten(0.2).get('rgb')]
          ]
        };
      })
    });
    return {
      chart: {
        type: 'pie',
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        width: 175,
        height: 175,
        backgroundColor: null
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },
      accessibility: {
        description: ''
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [
        {
          minPointSize: 10,
          innerSize: '20%',
          zMin: 0,
          name: 'total',
          data: Object.entries(mediaTypes).map((entry) => {
            return {
              name: entry[0],
              y: entry[1]
            };
          })
        }
      ],
      loading: false
    };
  }

  formatDataForOverview(key, network, legend) {
    const data = [];
    const categories = [];
    let colors = [];
    const isPercentage = ['interactionRate'].includes(key);

    this.competitorStats.forEach(({ id, name, colour }) => {
      data.push(
        this.statsModel[id]['overview'][network]
          ? this.statsModel[id]['overview'][network][key]
          : []
      );
      categories.push(name);
      colors.push(colour);
    });

    colors = colors.map((color) => {
      return {
        linearGradient: { x1: 0, x2: 0, y1: 1, y2: 0 },
        stops: [
          [0, color],
          [0.5, color],
          [1, (Highcharts as any).Color(color).brighten(0.35).get('rgb')]
        ]
      };
    });

    return {
      chart: {
        type: 'column',
        width: 300,
        height: 340
      },
      title: {
        text: null
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      xAxis: {
        categories,
        labels: {
          style: {
            color: '#a6b7c0',
            fontSize: '10px'
          }
        },
        lineColor: 'transparent',
        tickColor: 'transparent'
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null
        },
        labels: {
          format: isPercentage ? '{value}%' : '{value}',
          style: {
            color: '#a6b7c0',
            fontSize: '10px'
          }
        },
        alternateGridColor: '#F8F9FD'
      },
      series: [
        {
          name: isPercentage ? legend + ' (%)' : legend,
          colorByPoint: true,
          colors,
          data
        }
      ],
      loading: false
    };
  }

  checkNetwork(networkFilter) {
    return this.statsModel.networks.find(
      (network) => parseInt(networkFilter, 10) === network
    ) || networkFilter === 'total'
      ? true
      : false;
  }

  private formatDataForTopWords(data, name, colour) {
    return {
      chart: {
        height: '300'
      },
      series: [
        {
          type: 'wordcloud',
          data,
          name: 'Occurrences',
          colors: [colour]
        }
      ],
      title: {
        text: null
      }
    };
  }

  private formatDataForFollowerCount(followerCount, name, colour) {
    return {
      chart: {
        type: 'spline',
        height: '300'
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false
          },
          lineWidth: 2,
          pointStart: Date.parse(this.reportStartDate),
          pointInterval: 24 * 3600 * 1000
        }
      },
      title: {
        text: null
      },
      exporting: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        labels: {
          style: {
            color: '#838EAB',
            fontSize: '10px',
            fontWeight: '700'
          }
        },
        lineColor: 'transparent',
        tickColor: 'transparent'
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: null
        },
        labels: {
          style: {
            color: '#838EAB',
            fontSize: '10px',
            fontWeight: '700'
          }
        },
        alternateGridColor: '#F8F9FD',
        formatter() {
          return this.value / 1000 + 'k';
        }
      },
      series: [
        {
          name,
          color: colour,
          data: Object.values(followerCount),
          pointStart: Date.parse(Object.keys(followerCount)[0]),
          pointInterval: 24 * 3600 * 1000,
          marker: {
            enabled: false
          }
        }
      ]
    };
  }

  private formatDataForAudienceGrowth(networkId) {
    return {
      chart: {
        type: 'spline',
        spacing: [60, 40, 20, 40]
      },
      plotOptions: {
        spline: {
          marker: {
            enabled: false
          },
          lineWidth: 2,
          pointStart: Date.parse(this.reportStartDate),
          pointInterval: 24 * 3600 * 1000
        }
      },
      title: {
        text: null
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbol: 'menuball',
            symbolSize: 15,
            symbolStroke: '#838EAB',
            x: 25,
            y: -35
          }
        }
      },
      legend: {
        useHTML: true,
        itemStyle: {
          color: '#43537F',
          textTransform: 'uppercase',
          fontSize: '10px',
          letterSpacing: '1px'
        },
        margin: 40
      },
      xAxis: {
        type: 'datetime',
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: 900
          }
        },
        lineColor: 'transparent',
        tickColor: 'transparent'
      },
      yAxis: {
        gridLineWidth: 0,
        title: {
          text: 'Increase In Followers',
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: 900
          },
          margin: 30
        },
        labels: {
          useHTML: true,
          style: {
            color: '#838EAB',
            fontSize: '10px',
            textTransform: 'lowercase',
            fontFamily: 'Lato, Arial, sans-serif',
            fontWeight: 900
          }
        },
        alternateGridColor: '#F8F9FD',
        formatter() {
          return this.value / 1000 + 'k';
        }
      },
      series: this.competitorStats.map(({ id, name, colour }) => {
        return {
          name,
          color: colour,
          data: this.statsModel[id]['audienceGrowth'][networkId]
            ? Object.values(this.statsModel[id]['audienceGrowth'][networkId])
            : [],
          pointStart: this.statsModel[id]['audienceGrowth'][networkId]
            ? Date.parse(
                Object.keys(this.statsModel[id]['audienceGrowth'][networkId])[0]
              )
            : Date.parse(this.reportStartDate),
          pointInterval: 24 * 3600 * 1000,
          marker: {
            enabled: false
          }
        };
      }),
      loading: false
    };
  }

  postActivityTitle(key) {
    return PostActivityHeaders[key];
  }

  scrollTo(sectionId: string): void {
    const element = document.getElementById(sectionId);
    const yOffset = -160;
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });

    this.activeNavItemTag = sectionId;
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(40, false)
  onscroll(event) {
    this.setActiveNavItem();
  }

  setActiveNavItem(): void {
    this.navItems.forEach((item) => {
      if (item.element) {
        const visible = isElementWithinViewport(item.element);
        if (visible) {
          this.activeNavItemTag = item.tag;
        }
      }
    });
  }

  populateNavItemElements(): void {
    setTimeout(() => {
      this.navItems.forEach((item) => {
        item.element = this.elementRef.nativeElement.querySelector(
          `#${item.tag}`
        );
      });
    });
  }

  getNavItems(): Array<{ label: string; tag: string; element: Element }> {
    return this.competitorStats.length > 1
      ? [
          {
            label: 'Overview',
            tag: 'overview',
            element: undefined
          },
          {
            label: 'Post Activity',
            tag: 'post-activity',
            element: undefined
          },
          {
            label: 'Media Types',
            tag: 'media-types',
            element: undefined
          },
          {
            label: 'Top Posts',
            tag: 'top-posts',
            element: undefined
          },
          {
            label: 'Top Hashtags',
            tag: 'top-tags',
            element: undefined
          },
          {
            label: 'Top Words',
            tag: 'top-words',
            element: undefined
          },
          {
            label: 'Audience',
            tag: 'audience',
            element: undefined
          }
        ]
      : [
          {
            label: 'Overview',
            tag: 'overview',
            element: undefined
          },
          {
            label: 'Post Activity',
            tag: 'post-activity',
            element: undefined
          },
          {
            label: 'Top Posts',
            tag: 'top-posts',
            element: undefined
          },
          {
            label: 'Post Breakdown',
            tag: 'post-breakdown',
            element: undefined
          },
          {
            label: 'Audience',
            tag: 'audience',
            element: undefined
          }
        ];
  }

  openBrowserPrint() {
    window.print();
  }
}
