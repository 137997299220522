<div>
  <ssi-report-header
    report="Campaign Report"
    name="{{campaign?.name}}"
    [reportStartDate]="report.filter.period.start"
    [reportEndDate]="report.filter.period.end"
    (dateSelected)="reportDateSelected($event)"
  >
  </ssi-report-header>
  <ssi-report-sidebar
    icon="ssi-campaign"
    color="#F0B427"
    [navList]="navItems"
    [actionList]="reportActions"
    [active]="activeNavItemTag"
    (navClicked)="scrollTo($event)"
  >
  </ssi-report-sidebar>
  <div
    class="report-body"
    [class.loading]="loading"
  >
    <ng-container *ngIf="loading">
      <div class="loading-container">
        <!-- <i class="ssi ssi-confetti"></i> -->
        <img src="https://www.orlo.app/app/web/production/f44ceeab6551db9be89255d952d86c0d.gif">
        <h3 translate="WERE_BUILDING_YOUR_REPORT"></h3>
        <p translate="THIS_MAY_TAKE_A_MINUTE_SO_SIT_TIGHT_ITS_NOT_EASY_GATHERING_ALL_THAT_INSIGHT"></p>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading">
      <div class="summaries-per-account-container">
        <ssi-account-summary-card
          *ngFor="let network of report.postsData.networksData"
          [accountTitle]="network.label"
          [accountIcon]="network.icon"
          [accountAmount]="'' + report.postsData.perNetwork[network.account_type_id].count_accounts"
          [postAmount]="report.postsData.perNetwork[network.account_type_id].count_posts"
          [messageAmount]="report.postsData.perNetwork[network.account_type_id].sum_comments"
        ></ssi-account-summary-card>
      </div>
      <hr>
      <section
        class="section-container"
        id="overview"
      >
        <div class="section-heading">
          <h3>Overview</h3>
          <ul class="network-filter">
            <li
              (click)="activeNetworkFilter.overview = 'all'"
              [class.active]="activeNetworkFilter.overview === 'all'"
            >All Networks</li>
            <li
              *ngFor="let network of report.postsData.networksData"
              (click)="activeNetworkFilter.overview = network.account_type_id"
              [class.active]="activeNetworkFilter.overview === network.account_type_id"
            >{{network.label}}</li>
          </ul>
        </div>
        <div class="section-content">
          <div class="facts-container">
            <ssi-fact-overview
              [label]="'DAYS_OLD' | translate"
              [width]="'auto'"
              [number]="campaign?.age_days"
              iconClass="ssi-ad-schedule"
              iconColor="#D41D68"
              circleColor="#FFECF4"
              [ssiTooltip]="'THIS_TELLS_YOU_THE_AGE_OF_THIS_CAMPAIGN_IN_DAYS__HAVE_YOU_PASSED_ANY_MAJOR_MILESTONES' | translate"
              tooltipWindowClass="tooltip-chunky-big"
            >
            </ssi-fact-overview>
            <ssi-fact-overview
              [label]="'TOTAL_POSTS' | translate"
              [width]="'auto'"
              [number]="report?.postsData?.perNetwork[activeNetworkFilter.overview].count_posts"
              svgIcon="send"
              iconColor="#89A4EA"
              circleColor="#EDF1FC"
              [ssiTooltip]="'JUST_TO_MAKE_IT_CRYSTAL_CLEAR_THIS_IS_THE_TOTAL_NUMBER_OF_POSTS_THAT_HAVE_BEEN_ASSOCIATED_TO_THIS_CAMPAIGN' | translate"
              tooltipWindowClass="tooltip-chunky-big"
            >
            </ssi-fact-overview>
            <ssi-fact-overview
              [label]="'TOTAL_CLICKS' | translate"
              [width]="'auto'"
              [number]="report?.postsData?.perNetwork[activeNetworkFilter.overview].sum_clicks"
              iconClass="ssi-select-microcopy"
              iconColor="#F88C68"
              circleColor="#FFF1ED"
              [ssiTooltip]="'THE_TOTAL_NUMBER_OF_TIMES_LINKS_IN_ALL_POSTS_IN_THIS_CAMPAIGN_HAVE_BEEN_CLICKED__SIMPLE' | translate"
              tooltipWindowClass="tooltip-chunky-big"
            >
            </ssi-fact-overview>
            <ssi-fact-overview
              [label]="'REACH' | translate"
              [width]="'auto'"
              [number]="report?.postsData?.perNetwork[activeNetworkFilter.overview].sum_reach"
              iconClass="ssi-reach"
              iconColor="#12ACA4"
              circleColor="#EAF9F8"
              ssiTooltip="This shows you the total number of times the posts in this campaign have been seen by different people. Please note, X doesn't provide us with this data so stats from this network are not included in this total."
              tooltipWindowClass="tooltip-chunky-big"
            >
            </ssi-fact-overview>
            <ssi-fact-overview
              [label]="'IMPRESSIONS' | translate"
              [width]="'auto'"
              [number]="report?.postsData?.perNetwork[activeNetworkFilter.overview].sum_impressions"
              iconClass="ssi-impressions"
              iconColor="#F0B427"
              circleColor="#FBF3E2"
              [ssiTooltip]="'THIS_GIVES_YOU_THE_TOTAL_NUMBER_OF_TIMES_THE_POSTS_IN_THIS_CAMPAIGN_HAVE_BEEN_SHOWN_ON_A_PERSONS_SCREEN' | translate"
              tooltipWindowClass="tooltip-chunky-big"
            >
            </ssi-fact-overview>
            <ssi-fact-overview
              [label]="'SAVED' | translate"
              [width]="'auto'"
              [number]="report?.postsData?.perNetwork[activeNetworkFilter.overview].sum_saved"
              iconClass="ssi-ig-saves"
              iconColor="#b2c612"
              circleColor="#b2c6121a"
            >
            </ssi-fact-overview>
            <ssi-fact-overview
              [label]="'ENGAGEMENT_RATE' | translate"
              [width]="'auto'"
              [number]="report?.postsData?.perNetwork[activeNetworkFilter.overview].engagementRate"
              [showString]="true"
              iconClass="ssi-rate"
              iconColor="#425DEC"
              circleColor="#F3F6FD"
              [ssiTooltip]="'THIS_SHOWS_THE_LEVEL_OF_ENGAGEMENT_THE_CONTENT_YOUVE_SHARED_HAS_GENERATED_DURING_THE_CAMPAIGN' | translate"
              tooltipWindowClass="tooltip-chunky-big"
            >
            </ssi-fact-overview>
            <!-- <ssi-fact-overview
              label="Reactions"
              width="auto"
              [number]="report?.postsData?.perNetwork[activeNetworkFilter.overview].sum_reactions"
              iconClass="ssi-reactions-correct"
              iconColor="#FD87B8"
              circleColor="#FFF3F8"
            ></ssi-fact-overview> -->
          </div>
        </div>
      </section>
      <hr>

      <section
        class="section-container"
        id="video-metrics"
      >
        <ssi-video-metrics
          [campaignIds]="campaignIds"
          [dateRanges]="{current: report.filter.period}"
        ></ssi-video-metrics>
      </section>
      <hr>

      <ssi-instagram-story-stats
        id="instagram-stories"
        [campaignIds]="campaignIds"
        [dateRanges]="{current: report.filter.period}"
      ></ssi-instagram-story-stats>

      <hr>
      <section
        class="section-container"
        id="link-clicks"
      >
        <ssi-link-clicks-section
          [clicksData]="report.clicksData"
          [filterPeriod]="report.filter.period"
          [campaignIds]="campaignIds"
          [mapMarkers]="report.mapData.mapMarkers"
          [tagsToInclude]="report.filter.include_tags"
          [tagsToExclude]="report.filter.exclude_tags"
        ></ssi-link-clicks-section>
      </section>
      <hr>
      <section
        class="section-container"
        id="posts-breakdown"
      >
        <ssi-posts-breakdown [report]="report">
        </ssi-posts-breakdown>
      </section>
      <hr>
      <section
        class="section-container"
        id="top-posts"
      >
        <ssi-top-posts-section
          [report]="report"
          [campaignIds]="campaignIds"
          [includeDeletedInTopPosts]="state.params.includeDeleted"
          (postsLoaded)="onTopPostsLoaded($event)"
          (onSortTypeChanged)="topPostsSortType = $event"
          [tagsToInclude]="report.filter.include_tags"
          [tagsToExclude]="report.filter.exclude_tags"
        >
        </ssi-top-posts-section>
      </section>
    </ng-container>
  </div>
</div>