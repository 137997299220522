<div class="account-summary-container">
  <div class="section-head">
    <h2>
      <span>
        Account Summary
      </span>
    </h2>
    <ssi-social-network-select [selectableNetworks]="selectableNetworks"
                               [selectedNetwork]="selectedNetwork"
                               (networkSelect)="onNetworkSelect($event)"></ssi-social-network-select>
  </div>

  <div class="section-body table-container">
    <div class="options-row">
      <div class="dropdown-select">
        <ssi-dropdown-select-2 headButtonStyleVariant="analytics"
                               [options]="showTypes"
                               [(ngModel)]="selectedShowType"
                               (ngModelChange)="onShowTypeChange($event)"></ssi-dropdown-select-2>
      </div>

      <div class="exact-totals-switch"
           [ssiTooltip]="'Turning this on shows you the exact number for your metrics, rather than your decimal totals'"
           tooltipWindowClass="tooltip-chunky-big">
        <ssi-toggle-switch-2 [label]="'VIEW EXACT TOTALS'"
                             [(ngModel)]="viewExactTotalsChecked"
                             (ngModelChange)="onViewExactTotalsToggled($event)"></ssi-toggle-switch-2>
      </div>
    </div>

    <ssi-treetable [data]="dataToDisplay"
                   [bodyStyle]="{'max-height': ''}"
                   [rowStyle]="{'height': '60px'}"
                   [virtualScroll]="false"
                   [resizeMode]="'fit'"
                   [reorderable]="true"
                   [saveSettings]="true"
                   [settingsStorageKey]="'treetable-marketing-analytics-account-summary'"
                   [globalFilterInputRef]=""
                   id="treetable-marketing-analytics-account-summary">

      <ssi-column field="account.name"
                  [header]="selectedShowType.id === 'accounts' ? 'ACCOUNT' : 'NETWORK'"
                  width="15%"
                  [templateRefs]="{bodyCell: accountBodyCellTemplate}"></ssi-column>

      <ssi-column field="current.connections"
                  header="FOLLOWERS"
                  [tooltip]="'YOU_ALREADY_KNOW_THE_BASICS__THIS_TELLS_YOU_THE_NUMBER_OF_PEOPLE_WHO_FOLLOW_YOUR_ACCOUNT' | translate"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

      <ssi-column
        field="current.profile_likes"
        header="FANS"
        [tooltip]="'THIS_TELLS_YOU_THE_NUMBER_OF_PEOPLE_WHO_HAVE_LIKED_YOUR_PAGE' | translate"
        [templateRefs]="{bodyCell: statBodyCellTemplate}"
      ></ssi-column>

      <ssi-column field="current.account_impressions"
                  header="ACCOUNT IMPRESSIONS"
                  [tooltip]="'ACCOUNT_IMPRESSIONS_MARKETING_COLUMN_TOOLTIP' | translate"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

      <ssi-column field="current.outbox_impressions"
                  header="POST IMPRESSIONS"
                  [tooltip]="'POST_IMPRESSIONS_MARKETING_COLUMN_TOOLTIP' | translate"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

      <ssi-column field="current.account_reach"
                  header="ACCOUNT REACH"
                  [tooltip]="'THIS_GIVES_YOU_THE_AVERAGE_NUMBER_OF_TIMES_PER_DAY_YOUR_CONTENT_HAS_BEEN_SEEN_BY_DIFFERENT_PEOPLE_FOR_CONTENT_ADDED_ACROSS_THE_WHOLE_ACCOUNT' | translate"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

      <ssi-column field="current.outbox_reach"
                  header="POST REACH"
                  [tooltip]="'THIS_GIVES_YOU_THE_TOTAL_NUMBER_OF_TIMES_YOUR_CONTENT_HAS_BEEN_SEEN_BY_DIFFERENT_PEOPLE_FOR_CONTENT_ADDED_ACROSS_THE_WHOLE_ACCOUNT' | translate"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

      <ssi-column field="current.total_clicks_es"
                  header="LINK CLICKS"
                  tooltip="These figures shows the number of link clicks on posts which were published during the reporting period"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

      <ssi-column
        field="current.outbox_saved"
        header="SAVED"
        [templateRefs]="{bodyCell: statBodyCellTemplate}"
      ></ssi-column>

      <ssi-column field="current.messages_out"
                  header="PUBLISHED POSTS"
                  [tooltip]="'THIS_IS_THE_NUMBER_OF_PUBLIC_POSTS_YOU_HAVE_PUBLISHED_FROM_THE_SELECTED_ACCOUNTS' | translate"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

      <ssi-column field="current.outbox_engagement_reactions_rate"
                  header="POST ENGAGEMENT RATE"
                  tooltip="This shows the level of engagement the content you've shared has generated. This percentage is the total engagements on a post divided by the total number of impressions multiplied by 100. Engagements may include Reactions, likes, clicks, comments and shares, depending on the network"
                  [templateRefs]="{bodyCell: statBodyCellTemplate}"></ssi-column>

      <!-- template for treetable options (global filter and columns selector) -->
      <!-- <ng-template ssiTemplateSelector="options"
                   let-columns="columns"
                   let-columnsMap="columnsMap">
        <div class="">
          <span class="global-filter">
            <input type="text"
                   id="account-summary-stats-table-global-filter"
                   placeholder="Filter..."
                   value="">
          </span>
        </div>
      </ng-template> -->
    </ssi-treetable>


    <ng-template #accountBodyCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData"
                 let-fieldValue="fieldValue">
      <div class="body-cell account"
           [class.row-even]="rowIndex % 2 !== 0">
        <ssi-social-network-icon [accountTypeId]="rowData.account.account_type_id"
                                 [size]="20"></ssi-social-network-icon>
        <span title="{{fieldValue}}">{{fieldValue}}</span>
      </div>
    </ng-template>

    <ng-template #statBodyCellTemplate
                 ssiTemplateSelector="bodyCell"
                 let-rowIndex="rowIndex"
                 let-rowData="rowData"
                 let-column="column"
                 let-columnIndex="columnIndex"
                 let-columns="columns"
                 let-columnsMap="columnsMap"
                 let-fieldValue="fieldValue">
      <div class="body-cell stat"
           [class.row-even]="rowIndex % 2 !== 0">
        <span
          *ngIf="!isNumber(fieldValue)"
          [ssiTooltip]="rowData.account.account_type_id === AccountTypeIdString.Twitter ? 'X doesn\'t provide this figure on the native platform and therefore we\'re unable to provide this insight.' : ('NOTHING_TO_SEE_HERE_NO_REALLY_THERE_ISNT_BECAUSE_THIS_NETWORK_DOESNT_PROVIDE_THIS_DATA' | translate)"
          [tooltipDisabled]="[
            AccountTypeIdString.TwitterAds,
            AccountTypeIdString.Twitter,
            AccountTypeIdString.Facebook,
            AccountTypeIdString.FacebookAds,
            AccountTypeIdString.LinkedIn,
            AccountTypeIdString.LinkedInAds,
            AccountTypeIdString.Nextdoor
          ].includes(rowData.account.account_type_id)"
          tooltipWindowClass="tooltip-chunky-big"
        >N/A</span>
        <ng-container *ngIf="isNumber(fieldValue)">
          <ng-container *ngIf="viewExactTotalsChecked">
            <span>{{fieldValue}}</span>
            <span *ngIf="column.field === 'current.outbox_engagement_reactions_rate'">%</span>
            <ssi-stat-change *ngIf="rowData.previous"
                             [current]="fieldValue"
                             [previous]="rowData.previous[splitColumnFieldValue(column.field)]"
                             [suffix]="column.field === 'current.outbox_engagement_reactions_rate' ? '%' : ''"
                             [bigNumber]="false"></ssi-stat-change>
          </ng-container>
          <ng-container *ngIf="!viewExactTotalsChecked">
            <span *ngIf="column.field === 'current.outbox_engagement_reactions_rate'">{{formatEngagementRate(fieldValue)}}</span>
            <ssi-big-number *ngIf="column.field !== 'current.outbox_engagement_reactions_rate'"
                            [ssiTooltip]="rowData.account.account_type_id === '3' && column.field === 'current.connections' ? ('THIS_TELLS_YOU_THE_NUMBER_OF_PEOPLE_WHO_FOLLOW_YOUR_FACEBOOK_PAGE_NOT_THE_NUMBER_OF_PEOPLE_WHO_HAVE_LIKED_YOUR_PAGE' | translate) : ''"
                            tooltipWindowClass="tooltip-chunky-big"
                            [number]="fieldValue"></ssi-big-number>
            <span *ngIf="column.field === 'current.outbox_engagement_reactions_rate'">%</span>
            <ssi-stat-change *ngIf="rowData.previous"
                             [current]="fieldValue"
                             [previous]="rowData.previous[splitColumnFieldValue(column.field)]"
                             [suffix]="column.field === 'current.outbox_engagement_reactions_rate' ? '%' : ''"></ssi-stat-change>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>