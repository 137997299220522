import './widget-table.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { InsightsService, Table } from '../../../insights.service';
import { Filter } from '../../../reports/view/view-report.component';

export interface TableWidgetResponse {
  data: Array<any[]>; // rows
  metadata: { columns: Array<{ field: string; function: string }> };
}

@Component({
  selector: 'ssi-widget-table',
  templateUrl: './widget-table.component.html',
  styles: []
})
export class WidgetTableComponent implements OnInit, OnChanges {
  @Input() widget: Table;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;

  @Output() loaded = new EventEmitter<void>();

  tableBodyMaxHeight = '';
  response: TableWidgetResponse;

  constructor(private insightsService: InsightsService) {}

  ngOnInit() {
    if (this.initRender) {
      this.requestData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        !changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    this.response = await this.insightsService.aggregateWidgetData(
      this.widget,
      this.globalFilters,
      this.streamIds
    );
    this.loaded.emit();
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  onTableHeightChange(height: string): void {
    const tableHeadHeight = 40;
    const paginatorHeight = 50;

    setTimeout(() => {
      this.tableBodyMaxHeight =
        parseFloat(height) - tableHeadHeight - paginatorHeight + 'px';
    });
  }
}
