import './tags-select-create.component.scss';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ActivityTags } from '@ui-resources-angular';
import { Tag, TagType, OutboxTagsService } from '../../../common/services/api';

export const tagsControlOptions = [
  {
    translateKey: 'MESSAGES_WITH_ANY_OF_THESE_TAGS',
    value: 'or'
  },
  {
    translateKey: 'MESSAGES_WITH_ALL_OF_THESE_TAGS',
    value: 'and'
  }
];

@Component({
  selector: 'ssi-tags-select-create',
  templateUrl: './tags-select-create.component.html',
  styles: []
})
export class TagsSelectCreateComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() tagType: TagType;
  @Input() headButtonVariant: 'chips' | 'icon' = 'chips';
  @Input() canCreateTags = true;
  @Input() placeholder = '';
  @Input() ddSize = 'lg';

  tags: Tag[];
  @Input() selectedTags: Tag[] = [];

  @Output() selectedTagsChange = new EventEmitter<Tag[]>();

  @ViewChild('chipsContainer') chipsContainer: ElementRef;

  TagType = TagType;

  maxVisibleChipsCount = 999;
  chipsContainerWidth = 0;
  chipsContainerWidthOffset = 140;
  createTagButtonVisible = false;

  destroyed$ = new Subject<void>();

  constructor(
    protected elementRef: ElementRef,
    protected activityTags: ActivityTags,
    protected outboxTagsService: OutboxTagsService
  ) {}

  async ngOnInit() {
    if (!this.tagType) {
      throw new Error('Please specify TagType..');
    }

    if (this.tagType === TagType.Inbox) {
      this.outboxTagsService.inboxTagsStore.value$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((tags) => {
          this.tags = tags;
        });
    } else if (this.tagType === TagType.Post) {
      this.outboxTagsService.postTagsStore.value$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((tags) => {
          this.tags = tags;
        });
    } else {
      this.outboxTagsService.validationTagsStore.value$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((tags) => {
          this.tags = tags;
        });
    }
  }

  ngAfterViewInit(): void {
    this.chipsContainerWidth =
      this.elementRef.nativeElement.offsetWidth -
      this.chipsContainerWidthOffset;

    setTimeout(() => {
      this.setMaxVisibleChipsCount();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  selectedTagsChanged(selectedTags: Tag[]): void {
    this.selectedTags = selectedTags;
    this.selectedTagsChange.emit(selectedTags);
  }

  setMaxVisibleChipsCount(): number {
    if (!this.chipsContainer) {
      // no selected items
      return;
    }

    const chips = this.chipsContainer.nativeElement.children;

    let i = 0;
    let takenWidth = 0;
    while (!!chips[i] && takenWidth <= this.chipsContainerWidth) {
      const chipWidth = parseFloat(getComputedStyle(chips[i]).width);
      takenWidth += chipWidth;
      i++;
    }

    if (takenWidth > this.chipsContainerWidth) {
      this.maxVisibleChipsCount = i - 1;
    }
  }

  onDropdownToggled(visible: boolean): void {
    if (visible) {
      setTimeout(() => {
        // timeout to avoid flickering
        this.maxVisibleChipsCount = 999;
      }, 200);
    } else {
      this.setMaxVisibleChipsCount();
    }
  }

  deselectAll(): void {
    this.selectedTags = [];
    this.selectedTagsChanged(this.selectedTags);
  }

  deselectTag(tag: Tag): void {
    this.selectedTags = this.selectedTags.filter((t) => t !== tag);
    this.selectedTagsChanged(this.selectedTags);
  }

  createTag(name: string): void {
    this.outboxTagsService.createTag(name, this.tagType).then((createdTag) => {
      this.selectedTags.push(this.tags.find((t) => t.id === createdTag.id));
      this.selectedTagsChanged(this.selectedTags);
    });
  }

  onOptionsFiltered(params: {
    filterTerm: string;
    filteredOptions: Tag[];
  }): void {
    const optionExists = params.filteredOptions.some(
      (o) => o.name === params.filterTerm
    );
    this.createTagButtonVisible = !optionExists;
  }
}
