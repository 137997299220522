import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Activity, User, UserModel } from '@ui-resources-angular';
import PromiseQueue from 'promise-queue';
import { Colleague, ColleaguesService } from '../../services/api/colleagues';
import { Team, TeamsService } from '../../services/api/teams';
import {
  InsightsPost,
  InsightsPostType
} from '../../../modules/auth/insights/insights.service';
import { MAX_PARALLEL_BULK_REQUESTS } from '../../../modules/auth/inbox/inbox.component';

export enum AssignmentType {
  Message = 'message',
  Conversation = 'conversation',
  InsightsResults = 'insights_results'
}

@Component({
  selector: 'ssi-assign-message',
  templateUrl: './assign-message.component.html'
})
export class AssignMessageComponent implements OnInit {
  @Input() activity: Activity;
  @Input() conversation: any;
  @Input() showAssignMe = true;
  @Input() isMonitoringActivity = false;
  @Input() isPushMode = false;
  @Input() selectedInsightsResults: InsightsPost[];

  @Output() onAssign = new EventEmitter<Colleague | Team | User>();
  @Output() onAssigned = new EventEmitter<Colleague | Team | User>();

  @ViewChild('conversation') conversationInput: ElementRef;

  searchTerm: string;
  teams: Team[] = [];
  colleagues: Colleague[] = [];
  filteredTeams: Team[] = [];
  filteredColleagues: Colleague[] = [];
  selectedAssignmentType = AssignmentType.Message;
  assignedUserOrTeam: Colleague | Team | User;
  selectedUserOrTeam: Colleague | Team | User;
  conversationAssignedUserOrTeam: Colleague | Team | User;
  conversationSelectedUserOrTeam: Colleague | Team | User;
  authUser: User;
  AssignmentType = AssignmentType;

  assignmentTypes = [
    {
      label: 'ASSIGN_SELECTED_MESSAGE',
      value: AssignmentType.Message
    },
    {
      label: 'ASSIGN_WHOLE_CONVERSATION',
      value: AssignmentType.Conversation
    },
    {
      label: 'ASSIGN_SELECTED_RESULTS',
      value: AssignmentType.InsightsResults
    }
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private userModel: UserModel,
    private teamsService: TeamsService,
    private colleaguesService: ColleaguesService
  ) {}

  async ngOnInit() {
    const [colleagues, teams] = await Promise.all([
      this.colleaguesService.getAllActive(),
      this.teamsService.getAllActive()
    ]);

    this.colleagues = colleagues;
    this.filteredColleagues = this.colleagues.sort(this.sortByFullName);

    if (!this.isMonitoringActivity && !this.isPushMode) {
      this.teams = teams;
      this.filteredTeams = this.teams.sort(this.sortByTeamName);
    }

    this.authUser = await this.userModel.getAuthUser();

    this.assignedUserOrTeam =
      this.activity &&
      (this.activity.assignedToUser || this.activity.assignedToTeam);
    this.selectedUserOrTeam = this.assignedUserOrTeam;

    this.conversationAssignedUserOrTeam =
      this.conversation &&
      (this.conversation.assignedToUser || this.conversation.assignedToTeam);

    if (this.conversation) {
      this.selectedAssignmentType = AssignmentType.Conversation;
    }

    if (this.selectedInsightsResults) {
      this.selectedAssignmentType = AssignmentType.InsightsResults;
    }
  }

  sortByFullName(itemA: Colleague, itemB: Colleague) {
    return itemA.fullName.localeCompare(itemB.fullName);
  }

  sortByTeamName(itemA: Team, itemB: Team) {
    return itemA.name.localeCompare(itemB.name);
  }

  selectValue(value: Colleague | Team) {
    if (this.selectedAssignmentType === AssignmentType.Conversation) {
      this.conversationSelectedUserOrTeam =
        this.conversationSelectedUserOrTeam === value ? null : value;
      this.selectedUserOrTeam = null;
    }
    if (
      this.selectedAssignmentType === AssignmentType.Message ||
      this.selectedAssignmentType === AssignmentType.InsightsResults
    ) {
      this.selectedUserOrTeam =
        this.selectedUserOrTeam === value ? null : value;
      this.conversationSelectedUserOrTeam = null;
    }
  }

  changeAssignmentType(type: AssignmentType) {
    this.selectedAssignmentType = type;
    this.selectedUserOrTeam = null;
    this.conversationSelectedUserOrTeam = null;
    if (
      this.selectedAssignmentType === AssignmentType.Conversation &&
      this.conversationAssignedUserOrTeam
    ) {
      this.conversationSelectedUserOrTeam = this.conversationAssignedUserOrTeam;
    }
    if (
      this.selectedAssignmentType === AssignmentType.Message &&
      this.assignedUserOrTeam
    ) {
      this.selectedUserOrTeam = this.assignedUserOrTeam;
    }
  }

  assign(value: Colleague | Team | User) {
    this.selectedUserOrTeam = value;
    this.conversationSelectedUserOrTeam = value;
    if (
      this.selectedAssignmentType === AssignmentType.Conversation &&
      this.conversationAssignedUserOrTeam &&
      this.conversationSelectedUserOrTeam &&
      this.conversationSelectedUserOrTeam.id ===
        this.conversationAssignedUserOrTeam.id
    ) {
      this.activeModal.close();
      value = null;
    }

    if (
      this.selectedAssignmentType === AssignmentType.Message &&
      this.assignedUserOrTeam &&
      this.selectedUserOrTeam &&
      this.assignedUserOrTeam.id === this.selectedUserOrTeam.id
    ) {
      this.activeModal.close();
      value = null;
    }

    let promise;
    if (
      this.conversation &&
      this.selectedAssignmentType === AssignmentType.Conversation
    ) {
      promise = this.conversation.assignTo(value);
    } else if (
      this.selectedInsightsResults &&
      this.selectedAssignmentType === AssignmentType.InsightsResults
    ) {
      promise = this.bulkAssignSelectedResults((item: InsightsPost) => {
        return item.data.assignTo(
          value,
          item.type === InsightsPostType.Monitoring
        );
      });
    } else {
      promise = this.activity.assignTo(value, this.isMonitoringActivity);
    }

    this.onAssign.emit(value);
    if (promise) {
      promise.then(() => {
        this.onAssigned.emit(value);
      });
    } else if (this.selectedAssignmentType === AssignmentType.InsightsResults) {
      this.onAssigned.emit(value);
    }
    this.activeModal.close();
  }

  filterValues(searchTerm: string) {
    this.filteredColleagues = this.colleagues.filter((colleague: Colleague) =>
      colleague.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (!this.isMonitoringActivity && !this.isPushMode) {
      this.filteredTeams = this.teams.filter((team: Team) =>
        team.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  private bulkAssignSelectedResults(
    bulkAssignResult: (result: InsightsPost) => Promise<any>,
    results: InsightsPost[] = this.selectedInsightsResults
  ) {
    const queue = new PromiseQueue(MAX_PARALLEL_BULK_REQUESTS);
    const progress = {
      total: results.length,
      completed: 0
    };

    const promises = results.map((result) => {
      return queue.add(() => {
        return bulkAssignResult(result).finally(() => {
          progress.completed++;
        });
      });
    });

    Promise.all(promises).finally(() => {
      console.log('finished bulk action');
    });
  }
}
