import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  FilestackFile,
  FilestackService
} from '../../services/filestack/filestack.service';
import {
  OutboxFileType,
  AspectRatioRestrictions,
  VideoRestrictions,
  Video,
  ImageRestrictions,
  getMimetypeFromUrl,
  MediaRestrictions,
  MediaCategory
} from '@ui-resources-angular';
import { PesdkModalComponent } from '../../components/publisher/publisher-album/pesdk-modal/pesdk-modal.component';
import { ReduceImageSizePromptComponent } from '../../components/reduce-image-size-prompt/reduce-image-size-prompt.component';
import { VideoInfoService } from '../../services/video-info/video-info.service';
import { PopupService } from '../../services/popup/popup.service';
import { KeyValueObject, toSeconds, transformImage } from '../../util';

export interface FsFile {
  url: string;
  handle?: string;
  filename?: string;
  size?: number;
  type?: string;
  mimetype?: string;
  mediaCategory?: MediaCategory;
  originalFile?: File;
  status?: string;
  filestackFile?: FsFile;
  fsVideoMetadata?: any; // filestack metadata attached only after video is resized
  accountIdsToLinkTo?: Array<string>;
}

export interface ValidateFileError {
  message: string;
}

export interface ValidateFileErrors {
  aspectRatio?: ValidateFileError;
  dimensions?: ValidateFileError;
  duration?: ValidateFileError;
  size?: ValidateFileError;
}

export interface ValidateAndConvertFileResult {
  file: FsFile;
  errors?: ValidateFileErrors;
}

@Injectable({ providedIn: 'root' })
export class FileUploaderService {
  videoResizingInProgress$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected translate: TranslateService,
    protected filestackService: FilestackService,
    protected videoInfo: VideoInfoService,
    protected video: Video,
    protected modal: NgbModal,
    protected popup: PopupService
  ) {}

  editImage(
    file: FsFile,
    splitPost = false
  ): Promise<FilestackFile | undefined> {
    return new Promise(async (resolve, reject) => {
      const modal = this.modal.open(PesdkModalComponent, {
        windowClass: 'modal-vertical'
      });
      modal.componentInstance.imageUrl = file.url;
      modal.componentInstance.isPngImage = await isPngImage(file);
      modal.componentInstance.exportedImage.subscribe((editedImg) => {
        if (!editedImg) {
          // editor closed
          resolve(undefined);
          return;
        }

        console.log('Image after editing: ', editedImg);

        console.error(
          `Image (after edit) upload (filestack): Size: ${bytesToMb(
            editedImg.size
          )}MB.`
        ); // for tackjs only

        // delete old image (no need to await)
        if (!splitPost) {
          this.filestackService.deleteFile(file, editedImg);
        }

        resolve(editedImg);
      });
    });
  }

  showCursorLoader(): void {
    document.body.style.cursor = 'wait';
  }

  hideCursorLoader(): void {
    document.body.style.cursor = 'default';
  }

  async validateAndConvertImage(
    file: FsFile,
    mediaRestrictions: MediaRestrictions,
    alreadyResized = false
  ): Promise<ValidateAndConvertFileResult> {
    console.log('image file: ', file);
    const restrictions = mediaRestrictions
      ? (await isGifImage(file))
        ? mediaRestrictions.gif
        : file.mediaCategory === MediaCategory.Story
        ? mediaRestrictions.imageStory
        : mediaRestrictions.image
      : undefined;

    if (!restrictions) {
      return { file };
    }

    this.showCursorLoader();
    const dimensions = await getImgFileDimensions(file);
    // console.log('dimensions: ', dimensions);
    this.hideCursorLoader();

    if (restrictions.aspectRatio && dimensions) {
      const aspectRatioValid = isAspectRatioValid(
        dimensions.width,
        dimensions.height,
        restrictions.aspectRatio
      );

      if (!aspectRatioValid) {
        const errorMessage = this.translate.instant(
          'IG_IMAGES_RATIO_GUIDELINES'
        );

        const ok = await this.popup.confirm({
          title: '',
          message: errorMessage
        });

        if (!ok) {
          return {
            file,
            errors: { aspectRatio: { message: errorMessage } }
          };
        } else {
          const editedImg = await this.editImage(file);
          // re-validate / check if image is correctly resized
          return this.validateAndConvertImage(
            editedImg,
            mediaRestrictions,
            alreadyResized
          );
        }
      }
    }

    if (restrictions.dimensions && dimensions) {
      if (
        (restrictions.dimensions.width.max &&
          dimensions.width > restrictions.dimensions.width.max) ||
        (restrictions.dimensions.height.max &&
          dimensions.height > restrictions.dimensions.height.max)
      ) {
        try {
          if (await isGifImage(file)) {
            // go to the catch block below
            throw new Error(
              `Auto-resizing GIFs doesn't work quite well (size gets increased).`
            );
          }

          if (alreadyResized) {
            console.error(
              `Image already resized, image file: ${JSON.stringify(
                file
              )} - Image dimensions: ${JSON.stringify(
                dimensions
              )} - Max width: ${
                restrictions.dimensions.width.max
              } - Max height: ${restrictions.dimensions.height.max}`
            ); // only for trackjs

            // go to the catch block below
            throw new Error(`Could not auto-resize correctly, cancelling...`);
          }

          const resizedImg = await this.filestackService.resizeImage(
            file.url,
            restrictions.dimensions.width.max,
            restrictions.dimensions.height.max
          );

          const resizedImageDimensions = await getImgFileDimensions(resizedImg);
          console.error(
            `Width before&after: ${dimensions.width} -> ${resizedImageDimensions.width}`
          ); // only for trackjs

          console.error(
            `Image dimensions changed, old image file: ${JSON.stringify(
              file
            )}, resized image file: ${JSON.stringify(
              resizedImg
            )}, old dimensions: ${JSON.stringify(
              dimensions
            )}, new (resized) dimensions: ${JSON.stringify(
              resizedImageDimensions
            )}, max width: ${restrictions.dimensions.width.max} - max height: ${
              restrictions.dimensions.height.max
            }, old size: ${bytesToMb(file.size)}, new size: ${bytesToMb(
              resizedImg.size
            )}.`
          ); // only for trackjs

          // delete old file (no need to await)
          this.filestackService.deleteFile(file, resizedImg);

          // re-validate - check the rest of the validation procedure (e.g. image size)
          // note: the size of an image after resizing can INCREASE in some instances
          return this.validateAndConvertImage(
            resizedImg,
            mediaRestrictions,
            true
          );
        } catch (e) {
          console.error(e);
          const errorMessage = this.translate.instant(
            'MAX_IMAGE_DIMENSIONS_WARNING',
            {
              maxDimensions: `${restrictions.dimensions.width.max}x${restrictions.dimensions.height.max}`,
              dimensions: `${dimensions.width}x${dimensions.height}`
            }
          );

          await this.popup.alert({
            title: this.translate.instant('FILE_UPLOAD_FAILED'),
            message: errorMessage
          });

          return {
            file,
            errors: { dimensions: { message: errorMessage } }
          };
        }
      }
    }

    if (file.size && restrictions.size && file.size >= restrictions.size.max) {
      // file size is not available if post is being edited or drafted
      const result = await this.promptImageSizeReduceOptions(
        file,
        restrictions
      );

      if (result === 'open-editor') {
        const editedImg = await this.editImage(file);
        // re-validate / check if the image size is reduced enough
        return this.validateAndConvertImage(
          editedImg,
          mediaRestrictions,
          alreadyResized
        );
      }
      // auto reduce
      else if (result === 'auto-reduce-size') {
        const reducedImg = await this.reduceImageSize(file, restrictions);
        // re-validate / check if the image size is reduced enough
        return this.validateAndConvertImage(
          reducedImg,
          mediaRestrictions,
          alreadyResized
        );
      }
      // cancel
      else {
        return {
          file,
          errors: {
            size: { message: `Image size too big, cancelling upload...` }
          }
        };
      }
    }

    return {
      file
    };
  }

  async validateAndConvertVideo(
    file: FsFile,
    mediaRestrictions: MediaRestrictions,
    alreadyResized = false
  ): Promise<ValidateAndConvertFileResult> {
    console.log('video file: ', file);
    const restrictions = mediaRestrictions
      ? file.mediaCategory === MediaCategory.Story
        ? mediaRestrictions.videoStory
        : file.mediaCategory === MediaCategory.Reel
        ? mediaRestrictions.reel
        : mediaRestrictions.video
      : undefined;

    // console.log('restrictions: ', restrictions);

    if (!restrictions) {
      return { file };
    }

    let metadata;

    if (restrictions.aspectRatio) {
      metadata = metadata || (await this.videoInfo.getMetadata(file.url));
      const { width, height } = metadata.dimensions;

      if (!isAspectRatioValid(width, height, restrictions.aspectRatio)) {
        const errorMessage =
          restrictions.aspectRatio.max === 0.5625
            ? this.translate.instant('TIKTOK_VIDEOS_AND_REELS_RATIO_GUIDELINES')
            : this.translate.instant('IG_VIDEOS_RATIO_GUIDELINES');

        await this.popup.alert({
          title: this.translate.instant('FILE_UPLOAD_FAILED'),
          message: errorMessage
        });

        return {
          file,
          errors: { aspectRatio: { message: errorMessage } }
        };
      }
    }

    if (
      restrictions.duration.min ||
      restrictions.duration.max ||
      restrictions.dimensions.width.min ||
      restrictions.dimensions.width.max ||
      restrictions.dimensions.height.min ||
      restrictions.dimensions.height.max
    ) {
      metadata = metadata || (await this.videoInfo.getMetadata(file.url));

      console.log('metadata: ', metadata);
      console.log('restrictions: ', restrictions);

      if (file.fsVideoMetadata) {
        // In some edgy cases after filestack resizes a video to e.g. 500x500, the logic (native browser check) on our side that checks the dimensions might say 501x500 (some rounding error?).
        // In that case override the dimensions with the ones that came from filestack (after video is resized), and let the user pass the validation.
        metadata.dimensions.width = file.fsVideoMetadata.result.width;
        metadata.dimensions.height = file.fsVideoMetadata.result.height;
      }

      const validated = this.video.validate(metadata, restrictions);

      if (!validated.isValid) {
        if (validated.errors.duration) {
          if (validated.errors.duration.max) {
            const errorMessage = this.translate.instant(
              'PUBLISHER_VIDEO_TOO_LONG_ERROR',
              {
                maxDuration: toSeconds(restrictions.duration.max, 0),
                videoDuration: toSeconds(metadata.duration, 1)
              }
            );
            await this.popup.confirm({
              title: this.translate.instant('FILE_UPLOAD_FAILED'),
              message: errorMessage
            });

            return {
              file,
              errors: { duration: { message: errorMessage } }
            };
          }
          if (validated.errors.duration.min) {
            const errorMessage = this.translate.instant(
              'PUBLISHER_VIDEO_TOO_SHORT_ERROR',
              {
                minDuration: toSeconds(restrictions.duration.min, 0),
                videoDuration: toSeconds(metadata.duration, 1)
              }
            );
            await this.popup.confirm({
              title: this.translate.instant('FILE_UPLOAD_FAILED'),
              message: errorMessage
            });

            return {
              file,
              errors: { duration: { message: errorMessage } }
            };
          }
        }

        if (validated.errors.dimensions) {
          let maxWidth;
          let maxHeight;

          // if (
          //   restrictions.aspectRatio &&
          //   ((validated.errors.dimensions.width &&
          //     validated.errors.dimensions.width.max) ||
          //     (validated.errors.dimensions.height &&
          //       validated.errors.dimensions.height.max))
          // ) {
          //   console.log('restrictions.aspectRatio: ', restrictions.aspectRatio);
          //   let ratio;
          //   if (
          //     restrictions.dimensions.height.max &&
          //     restrictions.dimensions.height.max <
          //       restrictions.dimensions.width.max
          //   ) {
          //     ratio = metadata.dimensions.height / metadata.dimensions.width;
          //     maxHeight = restrictions.dimensions.height.max;
          //     maxWidth = Math.ceil(restrictions.dimensions.height.max / ratio);
          //   } else {
          //     ratio = metadata.dimensions.width / metadata.dimensions.height;
          //     maxWidth = restrictions.dimensions.width.max;
          //     maxHeight = Math.ceil(restrictions.dimensions.width.max / ratio);
          //   }
          // } else {
          maxWidth = restrictions.dimensions.width.max;
          maxHeight = restrictions.dimensions.height.max;
          // }

          console.log(
            'current: ',
            `${metadata.dimensions.width}x${metadata.dimensions.height}`
          );
          console.log('max: ', `${maxWidth}x${maxHeight}`);

          let titleText;
          let confirmText;
          let errorMessage;

          if (alreadyResized) {
            // only for trackjs
            console.error(
              `Video already resized, video file url: ${
                file.url
              }, metadata: ${JSON.stringify(
                metadata
              )}, max width: ${maxWidth} - max height: ${maxHeight}.`
            );
            titleText = `Convert error`;
            confirmText = `Try again`;
            errorMessage = `
            ${this.translate.instant('MAX_VIDEO_DIMENSIONS_WARNING', {
              maxDimensions: `${maxWidth}x${maxHeight}`,
              videoDimensions: `${metadata.dimensions.width}x${metadata.dimensions.height}`
            })}
            <br><br>
            Would you like to try resizing it again?`;
          } else {
            titleText = this.translate.instant('CONVERT_VIDEO');
            confirmText = this.translate.instant('RESIZE_VIDEO');
            errorMessage = `
              ${this.translate.instant('MAX_VIDEO_DIMENSIONS_WARNING', {
                maxDimensions: `${maxWidth}x${maxHeight}`,
                videoDimensions: `${metadata.dimensions.width}x${metadata.dimensions.height}`
              })}
              <br><br>
              ${this.translate.instant(
                'WOULD_YOU_LIKE_TO_AUTOMATICALLY_RESIZE_IT'
              )}`;
          }

          const shouldConvertVideo = await this.popup.confirm({
            title: titleText,
            message: errorMessage,
            okText: confirmText
          });

          if (!shouldConvertVideo) {
            return {
              file,
              errors: { dimensions: { message: errorMessage } }
            };
          } else {
            try {
              this.videoResizingInProgress$.next(true);
              const {
                url,
                size,
                mimetype,
                fsVideoMetadata
              } = await this.videoInfo.resizeVideo(file.url, {
                width: maxWidth,
                height: maxHeight
              });
              this.videoResizingInProgress$.next(false);

              const resizedVideo = {
                ...file,
                ...{
                  url,
                  size,
                  mimetype,
                  fsVideoMetadata,
                  handle: this.filestackService.getHandleFromUrl(url)
                }
              };

              console.error(
                `Video dimensions changed, old video file: ${JSON.stringify(
                  file
                )}, new (resized) video file: ${JSON.stringify(
                  resizedVideo
                )}, old metadata: ${JSON.stringify(
                  metadata
                )}, max width: ${maxWidth} - max height: ${maxHeight}.`
              ); // only for trackjs

              // delete old file (no need to await)
              this.filestackService.deleteFile(file, resizedVideo);

              // re-validate after resizing - check the rest of the validation procedure (e.g. size)
              return this.validateAndConvertVideo(
                resizedVideo,
                mediaRestrictions,
                true
              );
            } catch (e) {
              console.error(`Error resizing video: ${e}`);
              this.videoResizingInProgress$.next(false);
              return {
                file,
                errors: { dimensions: { message: errorMessage } }
              };
            }
          }
        }
      }
    }

    if (file.size && restrictions.size && file.size >= restrictions.size.max) {
      // file size is not available if post is being edited or drafted
      const sizeMb = bytesToMb(file.size);
      const maxSizeMb = bytesToMb(restrictions.size.max);
      const errorMessage = `File ${file.filename} is ${sizeMb}MB. The accepted file size for chosen social network/s is less than ${maxSizeMb}MB.`;

      await this.popup.confirm({
        title: `Video size limit`,
        message: errorMessage
      });

      return {
        file,
        errors: { size: { message: errorMessage } }
      };
    }

    return { file };
  }

  async promptImageSizeReduceOptions(
    file: FsFile,
    restrictions: ImageRestrictions
  ): Promise<any> {
    const modal = this.modal.open(ReduceImageSizePromptComponent, {
      windowClass: 'orlo-modal'
    });

    const size = bytesToMb(file.size);
    const maxSize = bytesToMb(restrictions.size.max);

    modal.componentInstance.isPngImage = await isPngImage(file);
    modal.componentInstance.isGifImage = await isGifImage(file);
    modal.componentInstance.description = `The image file size limit is ${maxSize}MB and the image you are trying to upload is ${size}MB.`;

    const result = await modal.result;

    return result;
  }

  async reduceImageSize(
    file: FsFile,
    restrictions: ImageRestrictions,
    quality = 80,
    convertPngToJpeg = true
  ): Promise<FsFile> {
    if (await isPngImage(file)) {
      if (convertPngToJpeg) {
        const convertedImg = await this.filestackService.convertImageToJpg(
          file.url
        );

        // delete old file (no need to await)
        this.filestackService.deleteFile(file, convertedImg);

        // converting to JPEG might reduce the size enough so no need to quality reducing further
        return convertedImg.size && convertedImg.size >= restrictions.size.max
          ? this.reduceImageSize(convertedImg, restrictions)
          : convertedImg;
      } else {
        // reducing quality doesn't work with PNG images
        return file;
      }
    }

    const reducedImg = await this.filestackService.reduceImageQuality(
      file.url,
      quality
    );

    if (!reducedImg.size) {
      console.error('No file size after quality reducing!?'); // check trackjs (remove this if block if no trackjs errors)
      return reducedImg;
    }

    console.error(
      `Image size reduced, quality: ${quality}. Old size: ${file.size}, new size: ${reducedImg.size}.`
    ); // only for trackjs

    if (reducedImg.size && reducedImg.size >= restrictions.size.max) {
      if (quality - 20 <= 0) {
        // should never happen
        return reducedImg;
      }

      // delete reducing attempt - not enough reduced
      this.filestackService.deleteFile(reducedImg);

      // reduce the size progressively - by increasing the quality loss in each iteration until valid
      return this.reduceImageSize(file, restrictions, quality - 20);
    }

    // delete old file (no need to await)
    this.filestackService.deleteFile(file, reducedImg);

    return reducedImg;
  }
}

export function bytesToMb(bytes: number, decimals = 2): number {
  return parseFloat((bytes / 1024 / 1024).toFixed(decimals));
}

export async function getMimetype(file: FsFile): Promise<string> {
  const mimetype = file.mimetype || file.type || '';

  if (typeof mimetype === 'string' && mimetype.indexOf('/') > -1) {
    return mimetype;
  }

  try {
    const mimetypeFromUrl = await getMimetypeFromUrl(file.url);
    console.log('mimetype from url: ', mimetypeFromUrl);
    file.mimetype = mimetypeFromUrl;
    return mimetypeFromUrl;
  } catch (e) {
    console.error('Cannot determine file mimetype: ', file);
    return mimetype;
  }
}

export async function isVideo(file: FsFile): Promise<boolean> {
  const mimetype = await getMimetype(file);
  return mimetype.startsWith(OutboxFileType.Video);
}

export async function isImage(file: FsFile): Promise<boolean> {
  const mimetype = await getMimetype(file);
  return mimetype.startsWith(OutboxFileType.Image);
}

export async function isPngImage(file: FsFile): Promise<boolean> {
  const mimetype = await getMimetype(file);
  return mimetype.endsWith('png');
}

export async function isGifImage(file: FsFile): Promise<boolean> {
  const mimetype = await getMimetype(file);
  return mimetype.endsWith('gif');
}

export function getImgFileDimensions(
  fsFile: FsFile,
  useOriginalFile = false
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    if (fsFile.originalFile && useOriginalFile) {
      // Deprecated by Chrome....
      const blob = fsFile.originalFile;
      const url = URL.createObjectURL(blob);
      const img = new Image();
      img.src = url;

      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve({ width: img.width, height: img.height });
      };
    } else if (fsFile.url) {
      const img = new Image();
      img.src = fsFile.url;

      img.addEventListener('load', function () {
        resolve({ width: this.naturalWidth, height: this.naturalHeight });
      });
      img.addEventListener('error', function (e: ErrorEvent) {
        console.log('Image loading error: ', e);
        reject({ message: 'Image loading error....' });
      });
    } else {
      resolve(undefined);
    }
  });
}

export function isAspectRatioValid(
  width: number,
  height: number,
  restrictions: AspectRatioRestrictions
): boolean {
  const minRatio = restrictions.min;
  const maxRatio = restrictions.max;

  const ratio = width / height;

  if (ratio < minRatio || ratio > maxRatio) {
    return false;
  }

  return true;
}

export function convertImage(file: FsFile): string {
  const mimetype = file.mimetype || file.type;
  if (!mimetype) {
    console.error('convertImage: no mimetype!'); // check trackjs
    return file.url;
  }

  const convert: KeyValueObject<KeyValueObject<string>> = {};
  if (mimetype.endsWith('jpeg')) {
    convert.rotate = { deg: 'exif' };
  }

  if (Object.keys(convert).length > 0) {
    return transformImage(file.url, convert);
  }

  return file.url;
}
