import 'font-awesome/css/font-awesome.css';
import './sass/socialsignin.scss';
import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import uiRouter from '@uirouter/angularjs';
import '@uirouter/angularjs/release/resolveService';
import angularTranslate from 'angular-translate';
import 'angular-translate-handler-log';
import 'angular-translate-loader-static-files';
import dynamicLocale from 'exports-loader?"tmh.dynamicLocale"!angular-dynamic-locale';
import webFont from 'webfontloader';
import uiResources from './ui-resources';
import config from './common.config';
import run from './common.run';
import angularShims from './angular-shims';
import { EnvironmentType, ENVIRONMENT } from '../../angular/environment';

if (ENVIRONMENT !== EnvironmentType.Test) {
  webFont.load({
    google: {
      families: [
        'Lato:100,100i,300,300i,400,400i,700,700i,900,900i',
        'Roboto:300,400,500,700,900'
      ]
    }
  });
}

export default angular.module('common', [
  ngSanitize,
  uiRouter,
  angularTranslate,
  dynamicLocale,
  uiResources,
  config.name,
  run.name,
  angularShims.name
]);
