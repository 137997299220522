import { parseQueryString, getFullUrlPath } from './utils';

export interface PopupInterface {
  open(
    url: string,
    name: string,
    popupOptions: { width: number; height: number },
    redirectUri: string
  ): void;
  stringifyOptions(options: any): string;
  polling(redirectUri: string): angular.IPromise<any>;
  eventListener(redirectUri: string): angular.IPromise<any>;
}

export default class Popup implements PopupInterface {
  public popup: any;
  private url: string;
  private defaults: { redirectUri: string };

  constructor() {
    this.popup = null;
    this.defaults = {
      redirectUri: null
    };
  }

  stringifyOptions(options: any): string {
    const parts = [];
    Object.keys(options).forEach(function (key) {
      const value = options[key];
      parts.push(key + '=' + value);
    });
    return parts.join(',');
  }

  open(
    url: string,
    name: string,
    popupOptions: { width: number; height: number },
    redirectUri: string,
    dontPoll?: boolean
  ): Promise<any> {
    // console.log('popup open: ', url);
    const width = popupOptions.width || 500;
    const height = popupOptions.height || 500;

    const options = this.stringifyOptions({
      width,
      height,
      top: window.screenY + (window.outerHeight - height) / 2.5,
      left: window.screenX + (window.outerWidth - width) / 2
    });

    const popupName =
      window['cordova'] || window.navigator.userAgent.indexOf('CriOS') > -1
        ? '_blank'
        : name;

    this.popup = window.open(url, popupName, options);

    if (this.popup && this.popup.focus) {
      this.popup.focus();
    }

    if (dontPoll) {
      return;
    }

    if (window['cordova']) {
      return this.eventListener(redirectUri);
    } else {
      if (url === 'about:blank') {
        this.popup.location = url;
      }
      return this.polling(redirectUri);
    }
  }

  polling(redirectUri: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const redirectUriParser = document.createElement('a');
      redirectUriParser.href = redirectUri;
      const redirectUriPath = getFullUrlPath(redirectUriParser);

      const polling = setInterval(() => {
        if (
          !this.popup ||
          this.popup.closed ||
          this.popup.closed === undefined
        ) {
          clearInterval(polling);
          reject(new Error('The popup window was closed!'));
        }

        try {
          const popupWindowPath = getFullUrlPath(this.popup.location);

          if (popupWindowPath === redirectUriPath) {
            if (this.popup.location.search || this.popup.location.hash) {
              const query = parseQueryString(
                this.popup.location.search.substring(1).replace(/\/$/, '')
              );
              const hash = parseQueryString(
                this.popup.location.hash.substring(1).replace(/[\/$]/, '')
              );
              const params: any = { ...query, ...hash };

              if (params.error) {
                reject(new Error(params.error));
              } else {
                resolve(params);
              }
            } else {
              reject(
                new Error(
                  'OAuth redirect has occurred but no query or hash parameters were found. ' +
                    'They were either not set during the redirect, or were removed—typically by a ' +
                    'routing library—before AuthService could read it.'
                )
              );
            }

            clearInterval(polling);
            this.popup.close();
          } else {
            // console.log('uris dont match: ');
            // console.log('popupWindowPath: ', popupWindowPath);
            // console.log('redirectUriPath: ', redirectUriPath);
          }
        } catch (error) {
          //   console.error('Catch block...: ', error);
          // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
          // A hack to get around same-origin security policy errors in IE.
        }
      }, 500);
    });
  }

  eventListener(redirectUri): Promise<any> {
    return new Promise((resolve, reject) => {
      this.popup.addEventListener('loadstart', (event) => {
        if (event.url.indexOf(redirectUri) !== 0) {
          return;
        }

        const parser = document.createElement('a');
        parser.href = event.url;

        if (parser.search || parser.hash) {
          const query = parseQueryString(
            parser.search.substring(1).replace(/\/$/, '')
          );
          const hash = parseQueryString(
            parser.hash.substring(1).replace(/[\/$]/, '')
          );
          const params: any = { ...query, ...hash };

          if (params.error) {
            reject(new Error(params.error));
          } else {
            resolve(params);
          }

          this.popup.close();
        }
      });

      this.popup.addEventListener('loaderror', () => {
        reject(new Error('Authorization failed'));
      });

      this.popup.addEventListener('exit', () => {
        reject(new Error('The popup window was closed'));
      });
    });
  }
}
