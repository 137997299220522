import './context-menu.component.scss';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  TemplateRef,
  ContentChild,
  ViewChild,
  Renderer2,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import { animate, style, trigger, transition } from '@angular/animations';

@Component({
  selector: 'ssi-context-menu',
  templateUrl: './context-menu.component.html',
  animations: [
    trigger('slideFadeIn', [
      transition('void => *', [
        style({ opacity: 0, width: '0', height: '0' }),
        animate(
          '120ms ease-out',
          style({ opacity: 1, width: '*', height: '*' })
        )
      ])
    ])
  ]
})
export class ContextMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() id: any;
  @Input() offsetTop = 0;
  @Input() offsetLeft = 0;
  @Input() attachToBody = false;
  @Input() skipViewPortCheck = false;
  @Input() onlyHideMenuBody = false;
  @Input() preventBackgroundScroll = false;

  @Output() toggled = new EventEmitter<boolean>();

  @ViewChild('containerRef') containerRef: ElementRef<any>;
  @ContentChild(TemplateRef) content: TemplateRef<any>;

  context = {};
  rendered = false;
  containerStyle = {};
  attachedToBodyElem: any;

  constructor(
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
    protected changeDetectorRef: ChangeDetectorRef
  ) {}

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const clickedOutside = !this.elementRef.nativeElement.contains(
      event.target
    );
    if (clickedOutside) {
      this.hide();
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.attachToBody) {
      // this.attachedToBodyElem = this.containerRef.nativeElement.cloneNode(true);
      this.attachedToBodyElem = document.body.appendChild(
        this.containerRef.nativeElement
      );
    }
  }

  ngOnDestroy(): void {
    if (
      this.attachedToBodyElem &&
      window.document.body.contains(this.attachedToBodyElem)
    ) {
      window.document.body.removeChild(this.attachedToBodyElem);
    }
  }

  show(event: MouseEvent, context = {}): void {
    event.stopPropagation();

    if (this.rendered) {
      return;
    }

    this.context = { $implicit: context };

    this.containerStyle['top'] = `${event.clientY + this.offsetTop}px`;
    this.containerStyle['left'] = `${event.clientX + this.offsetLeft}px`;

    // render it but hide it in order to check if it can fit in the screen before actually showing it
    this.rendered = true;

    if (this.preventBackgroundScroll) {
      this.renderer.addClass(window.document.body, 'g-overflow-hidden');
    }

    if (this.skipViewPortCheck) {
      this.containerStyle['visibility'] = 'visible';
      this.changeDetectorRef.detectChanges();
      this.toggled.emit(true);
      return;
    }

    this.containerStyle['visibility'] = 'hidden';

    setTimeout(() => {
      if (!this.rendered) {
        // closed meanwhile?
        return;
      }

      const EDGE_OFFSET = 10;

      // determine X open direction..........
      const containerWidth = parseInt(
        getComputedStyle(this.containerRef.nativeElement).width,
        10
      );

      const widthFitsRight =
        event.clientX + containerWidth + this.offsetLeft + EDGE_OFFSET <=
        document.documentElement.clientWidth;

      const widthFitsLeft =
        event.clientX + this.offsetLeft - containerWidth - EDGE_OFFSET >= 0;

      if (!widthFitsRight) {
        if (widthFitsLeft) {
          this.containerStyle['left'] = `${
            event.clientX + this.offsetLeft - containerWidth
          }px`;
        } else {
          // cannot fit in either direction (e.g. mobile device) - stuck it to the end
          const OFFSET_LEFT = 10;
          this.containerStyle['left'] = `${OFFSET_LEFT}px`;
        }
      }

      // determine Y open direction..........
      const containerHeight = parseInt(
        getComputedStyle(this.containerRef.nativeElement).height,
        10
      );

      const heightFitsDown =
        event.clientY + containerHeight + this.offsetTop + EDGE_OFFSET <=
        document.documentElement.clientHeight;

      const heightFitsUp =
        event.clientY + this.offsetTop - containerHeight - EDGE_OFFSET >= 0;

      if (!heightFitsDown) {
        if (heightFitsUp) {
          this.containerStyle['top'] = `${
            event.clientY + this.offsetTop - containerHeight
          }px`;
        } else {
          // cannot fit in either direction - show it over whole viewport
          const OFFSET_TOP = 80;
          const OFSET_BOTTOM = 10;

          this.containerStyle['top'] = `${OFFSET_TOP}px`;

          if (
            containerHeight >=
            document.documentElement.clientHeight - OFFSET_TOP - OFSET_BOTTOM
          ) {
            // make the first child scrollable (assume it's some sort of a container)
            const firstChild = this.containerRef.nativeElement
              .firstElementChild;

            firstChild.style['height'] = `${
              document.documentElement.clientHeight - OFFSET_TOP - OFSET_BOTTOM
            }px`;

            firstChild.style['overflow-y'] = 'auto';
            firstChild.style['overflow-x'] = 'hidden';
          }
        }
      }

      // this.attachedToBodyElem.style['visibility'] = 'visible';
      this.containerStyle['visibility'] = 'visible';
      this.toggled.emit(true);
    }, 300);
  }

  hide(): void {
    this.rendered = false;
    this.containerStyle['visibility'] = 'hidden';
    this.changeDetectorRef.detectChanges();

    this.renderer.removeClass(window.document.body, 'g-overflow-hidden');

    this.toggled.emit(false);
  }

  toggle(event: MouseEvent): void {
    this.rendered ? this.hide() : this.show(event);
  }
}
