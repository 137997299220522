import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(
    items: Array<any>,
    filter: { [key: string]: any } | any, // Function, tslint didn't accept it
    active = true,
    reverse = false
  ): Array<any> {
    if (active) {
      if (typeof filter === 'function') {
        return items && items.filter((item) => filter(item));
      } else if (Object.values(filter)[0] == null) {
        return items;
      } else {
        return (
          items &&
          items.filter(
            (item) =>
              !Object.keys(filter).find((key) =>
                reverse ? item[key] === filter[key] : item[key] !== filter[key]
              )
          )
        );
      }
    } else {
      return items;
    }
  }
}
