import './sentiment-gauge.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Highcharts, { Options } from 'highcharts';

import {
  Sentiment,
  sentimentsIterable,
  sentiments,
  findSentimentConst
} from '../../../../../../common/constants';
import { mapNumberOnDifferentScale } from '../../../../../../common/utils';

import { InsightsService, SentimentGauge } from '../../../insights.service';
import { Filter } from '../../../reports/view/view-report.component';
import { DrilldownModalComponent } from '../drilldown-modal/drilldown-modal.component';
import { HighchartComponent } from '../../../../../../common/components/highchart/highchart.component';

@Component({
  selector: 'ssi-sentiment-gauge',
  templateUrl: './sentiment-gauge.component.html',
  styles: []
})
export class SentimentGaugeComponent implements OnInit, OnChanges {
  @Input() widget: SentimentGauge;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;

  @Output() loaded = new EventEmitter<void>();

  @ViewChild(HighchartComponent) highchart: HighchartComponent;

  sentiment: Sentiment;
  updateFlag: boolean = false;
  sentimentsIterable: Sentiment[] = sentimentsIterable;
  sentiments = sentiments;
  sentimentChartConfig: Options | any;
  averageSentimentValue: number = 0;

  constructor(
    private translate: TranslateService,
    private insightsService: InsightsService,
    private modal: NgbModal
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        !changes.globalFilters.firstChange) ||
      (changes.globalFilters &&
        !changes.globalFilters.previousValue &&
        changes.globalFilters.currentValue.length !== 0 &&
        changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data }) => {
        this.sentiment = findSentimentConst('numericKey', Math.round(data));

        this.averageSentimentValue = parseFloat(
          mapNumberOnDifferentScale(data, [-2, 2], [0, 5]).toFixed(1)
        );

        this.createSentimentGauge();
        this.highchart.rerender();
        this.loaded.emit();
      });
  }

  createSentimentGauge(): any {
    this.sentimentChartConfig = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%'
        // spacingTop: 20,
        // spacingBottom: 40,
        // marginTop: 20,
        // marginBottom: 100
      },
      title: {
        text: ''
      },
      exporting: {
        enabled: false
      },

      tooltip: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: [
          '50%',
          this.widget.display_properties && this.widget.display_properties.h > 1
            ? 256
            : 128
        ],
        size: 240
      },
      yAxis: {
        min: 0,
        max: 5,
        tickPixelInterval: 100,
        tickPosition: 'inside',
        tickColor: '#FFFFFF',
        tickLength: 60,
        tickWidth: 5,
        minorTickInterval: null,
        labels: {
          distance: 10,
          style: {
            fontSize: '14px',
            fontWeight: '900',
            color: '#101525'
          }
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 0,
            to: 1,
            color: sentiments.negative.color2,
            thickness: 40
          },
          {
            from: 1,
            to: 2,
            color: sentiments.semi_negative.color2,
            thickness: 40
          },
          {
            from: 2,
            to: 3,
            color: sentiments.neutral.color2,
            thickness: 40
          },
          {
            from: 3,
            to: 4,
            color: sentiments.semi_positive.color2,
            thickness: 40
          },
          {
            from: 4,
            to: 5,
            color: sentiments.positive.color2,
            thickness: 40
          }
        ]
      },
      series: [
        {
          name: 'Overall Sentiment',
          data: [this.averageSentimentValue],
          // tooltip: {
          //   valueSuffix: 'km/h'
          // },
          dataLabels: {
            format: '', // '{y} km/h'
            borderWidth: 0,
            color: '#333333',
            style: {
              fontSize: '16px'
            }
          },
          dial: {
            radius: '80%',
            backgroundColor: '#838EAB',
            baseWidth: 12,
            baseLength: '0%',
            rearLength: '0%'
          },
          pivot: {
            backgroundColor: '#838EAB',
            radius: 6
          }
        }
      ]
    };
  }
}
