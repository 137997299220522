import { Pipe, PipeTransform } from '@angular/core';

export interface SecondsToHumanTimeLabels {
  secondLabel: string;
  minuteLabel: string;
  hourLabel: string;
  dayLabel: string;
}

const ONE_SECOND = 1;
const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

const SECOND_LABEL = 's';
const MINUTE_LABEL = 'm';
const HOUR_LABEL = 'h';
const DAY_LABEL = 'd';

@Pipe({
  name: 'secondsToHumanTime2'
})
export class SecondsToHumanTime2Pipe implements PipeTransform {
  transform(
    seconds: number = 0,
    isLong: boolean = false,
    altRound?: boolean,
    timeLabels: SecondsToHumanTimeLabels = {
      secondLabel: SECOND_LABEL,
      minuteLabel: MINUTE_LABEL,
      hourLabel: HOUR_LABEL,
      dayLabel: DAY_LABEL
    }
  ): string {
    return this.secondsToHumanTime2(seconds, timeLabels);
  }

  secondsToHumanTime2(
    seconds: number = 0,
    timeLabels: SecondsToHumanTimeLabels = {
      secondLabel: SECOND_LABEL,
      minuteLabel: MINUTE_LABEL,
      hourLabel: HOUR_LABEL,
      dayLabel: DAY_LABEL
    }
  ): string {
    seconds = Number(seconds);
    let result = '';

    const days = Math.floor(seconds / ONE_DAY);
    if (days) {
      result += days + timeLabels.dayLabel + ' ';
    }

    const labels = [
      timeLabels.hourLabel,
      timeLabels.minuteLabel,
      timeLabels.secondLabel
    ];

    const hrsMinsAndSecs = new Date(seconds * 1000).toISOString().slice(11, 19); // this will spit out hh:mm:ss format string
    hrsMinsAndSecs.split(':').forEach((part, index) => {
      const numPart = Number(part);
      if (numPart) {
        result += numPart + labels[index] + ' ';
      }
    });

    if (!result) {
      result = '0' + timeLabels.secondLabel;
    }

    return result;
  }
}
