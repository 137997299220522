import './landing.component.scss';
import { Component, OnInit } from '@angular/core';
import { AsyncTracker, AsyncTrackerFactory } from 'angular-async-tracker';
import { RedirectService } from '../../../common/services/redirect/redirect.service';
import { AuthService } from '../../../common/services/auth/auth.service';

export type LandingPageFormsViews = 'login' | 'forgotPassword';

@Component({
  selector: 'ssi-landing',
  templateUrl: './landing.component.html'
})
export class LandingComponent implements OnInit {
  constructor(
    private asyncTrackerFactory: AsyncTrackerFactory,
    private redirect: RedirectService,
    protected auth: AuthService
  ) {}

  messages = { error: null, success: null };
  loadingTracker: AsyncTracker = this.asyncTrackerFactory.create();
  layoutClass = 'layout-login';
  displayForm: LandingPageFormsViews = 'login';

  ngOnInit(): void {
    if (this.auth.isAuthenticated(true)) {
      this.redirect.login();
    }
  }

  changeForm(form: LandingPageFormsViews) {
    this.layoutClass = `layout-${form}`;
    this.displayForm = form;
  }
}
