<div
  class="composer-inner-container {{layout}}"
  [ngClass]="tempAnimationClass"
  [@slideInOutComposer]
  *ngIf="isActive"
>
  <ng-container *ngIf="layout !== ComposerLayout.Minimised">
    <div class="composer-heading">
      <button
        type="button"
        class="g-btn g-btn-md bg-light-red btn-text-red"
        (click)="confirmCancelPost()"
        [translate]="post?.isDraft ? 'CANCEL_DRAFT' : 'CANCEL_POST'"
      ></button>
      <div class="layout-control-actions">
        <button
          class="layout-action g-btn g-btn-md rounded-square-icon-only-button"
          (click)="changeLayout(ComposerLayout.Minimised)"
          ssiTooltip="Minimise"
          tooltipPlacement="top"
          tooltipWindowClass="tooltip-chunky"
          [tooltipAppendToBody]="true"
        ><i class="ssi ssi-compose-minimise"></i></button>
        <button
          *ngIf="! isMobile && layout === ComposerLayout.Full"
          class="layout-action g-btn g-btn-md rounded-square-icon-only-button"
          (click)="changeLayout(ComposerLayout.FixedRight)"
          ssiTooltip="Half screen"
          tooltipPlacement="top"
          tooltipWindowClass="tooltip-chunky"
          [tooltipAppendToBody]="true"
        ><i class="ssi ssi-compose-split-window split-icon"></i></button>
        <button
          *ngIf="! isMobile && layout === ComposerLayout.FixedLeft"
          class="layout-action g-btn g-btn-md rounded-square-icon-only-button"
          (click)="changeLayout(ComposerLayout.FixedRight)"
          ssiTooltip="Swap sides"
          tooltipPlacement="top"
          tooltipWindowClass="tooltip-chunky"
          [tooltipAppendToBody]="true"
        > <ssi-icon
            class="stat-graph-badge-wreathe"
            icon="rightAffixed"
            height="20"
            width="20"
          ></ssi-icon></button>
        <button
          *ngIf="! isMobile && layout === ComposerLayout.FixedRight"
          class="layout-action g-btn g-btn-md rounded-square-icon-only-button"
          (click)="changeLayout(ComposerLayout.FixedLeft)"
          ssiTooltip="Swap sides"
          tooltipPlacement="top"
          tooltipWindowClass="tooltip-chunky"
          [tooltipAppendToBody]="true"
        > <ssi-icon
            class="stat-graph-badge-wreathe"
            icon="leftAffixed"
            height="20"
            width="20"
          ></ssi-icon>
        </button>
        <button
          *ngIf="! isMobile && layout !== ComposerLayout.Full"
          class="layout-action g-btn g-btn-md rounded-square-icon-only-button"
          (click)="changeLayout(ComposerLayout.Full)"
          ssiTooltip="Full screen"
          tooltipPlacement="top"
          tooltipWindowClass="tooltip-chunky"
          [tooltipAppendToBody]="true"
        ><i class="ssi ssi-full-screen split-icon"></i></button>

        <!-- Remove after inline preview implementation -->
        <button
          class="layout-action g-btn g-btn-wide"
          [disabled]="! post?.validity.isValid"
          (click)="showPostPreview()"
        >
          Preview Post
        </button>
        <!-- / -->

        <!-- <ssi-toggle-switch-2
          name="preview"
          class="wrapped"
          [class.toggled]="showPreview"
          [label]="'PREVIEW' | translate"
          [(ngModel)]="showPreview"
        ></ssi-toggle-switch-2> -->
      </div>
    </div>

    <div class="composer-content">

      <div class="post-controls-container">
        <ssi-compose-area
          *ngIf="post"
          [post]="post"
          [params]="params"
          [accounts]="post.selectableAccounts"
          [postAccounts]="post.accounts"
          (selectedAccountsChanged)="onSelectedAccountsChanged($event)"
          [fileUploadActive]="composerService.fileUploadActive"
          [mediaUploadOptionsMenuBodyTemplate]="mediaUploadOptionsMenuBodyTemplate"
          (gifUploadSuccess)="gifUploadSuccess($event)"
          (changeDraftState)="changeDraftState($event)"
        ></ssi-compose-area>

        <ssi-attachments
          *ngIf="post?.accounts.length"
          [composer]="post"
          [disable]="disable"
          [composerLayout]="layout"
          [fileUploadActive]="composerService.fileUploadActive"
          [mediaUploadOptionsMenuBodyTemplate]="mediaUploadOptionsMenuBodyTemplate"
        ></ssi-attachments>

        <div
          *ngIf="post?.accounts.length"
          class="tags-campaigns accordion-content"
        >
          <ssi-accordion
            #schedulePostsAccordion
            [label]="'ORLO_TAGS__CAMPAIGNS' | translate"
            [isOpen]="false"
            [isValid]="true"
          >
            <div class="tags-campaigns-container">
              <h4
                class="accordion-content-title"
                translate="APPLY_POST_TAGS"
              ></h4>
              <p
                class="accordion-content-body"
                translate="SELECT_MULTIPLE_TAGS_FOR_POSTS_THAT_FALL_INTO_MORE_THAN_ONE_CATEGORY_PROVIDING_ANOTHER_LEVEL_OF_GRANULARITY_FOR_MANAGEMENT_AND_REPORTING"
              >
              </p>
              <div class="tags-campaigns-list">
                <ssi-tags-select-create
                  [tagType]="'post'"
                  [selectedTags]="selectedTags"
                  [headButtonVariant]="'chips'"
                  (selectedTagsChange)="onPostTagsChange($event)"
                ></ssi-tags-select-create>
              </div>
            </div>
            <div class="tags-campaigns-container">
              <h4
                class="accordion-content-title"
                translate="ASSIGN_A_CAMPAIGN"
              ></h4>
              <p
                class="accordion-content-body"
                translate="ORGANISE_YOUR_CONTENT_FOR_FUTURE_REPORTING__CONTENT_PLANNING_INTO_A_SINGULAR_CAMPAIGN_OF_YOUR_CHOICE"
              >
              </p>
              <div class="tags-campaigns-list">
                <ssi-campaigns-select-create
                  [authUser]="authUser"
                  [post]="post"
                  (onCampaignSelected)="onPostCampaignChange($event)"
                >

                </ssi-campaigns-select-create>
              </div>
            </div>
          </ssi-accordion>
        </div>

        <div
          *ngIf="post?.accounts.length"
          class="scheduling-posts accordion-content"
          [ngClass]="{'post-errors': post.schedulingErrors}"
        >
          <ssi-accordion
            #schedulePostsAccordion
            [label]="(!! scheduledPostTimes[0] ? '(' + (scheduledPostTimes.length - (!! scheduledPostTimes[scheduledPostTimes.length - 1] ? 0 : 1)) + ')' : 'No') + ' Scheduled Posts'"
            [isOpen]="false"
            [isValid]="true"
          >
            <h4
              class="accordion-content-title"
              translate="SCHEDULING_POSTS"
            ></h4>
            <p
              class="accordion-content-body"
              translate="DATES_THAT_ARE_HIGHLIGHTED_GREEN_ALREADY_HAVE_POSTS_SCHEDULED_THAT_DAY_YOU_CAN_SCHEDULE_YOUR_POST_TO_GO_OUT_UP_TO_A_MAXIMUM_OF_12_TIMES"
            >
            </p>
            <div class="scheduling-posts-list">
              <ssi-schedule-posts
                *ngFor="let scheduledPostTime of scheduledPostTimes; trackBy:trackByIndex; let index = index"
                [accounts]="accounts"
                [scheduledPostTime]="scheduledPostTime?.time"
                [error]="scheduledPostTime?.error?.message"
                [fullWidth]="! showPreview"
                (onScheduledPostTimesUpdate)="updateSchedule($event, index, true)"
                (onScheduledPostTimesDeleted)="deleteSchedule(index)"
              ></ssi-schedule-posts>
            </div>
            <button
              class="accordion-content-action"
              [ngClass]="{'accordion-content-action-active': (!! scheduledPostTimes[scheduledPostTimes.length - 1]) && scheduledPostTimes.length !== 12}"
              (click)="!! scheduledPostTimes[scheduledPostTimes.length - 1] && addSchedule()"
            >
              <i class="ssi ssi-addsmall"></i>
              <p>Add {{12 - scheduledPostTimes.length}} more schedules</p>
            </button>
          </ssi-accordion>
        </div>

        <div
          class="post-expiry accordion-content"
          *ngIf="post?.accounts.length"
        >

          <ssi-accordion
            [label]="customAccordionHeading"
            [isOpen]="post.expiryEnabled"
            [isValid]="true"
          >
            <h4
              class="accordion-content-title"
              translate="POST_EXPIRY_FOR_SOCIAL_POSTS"
            ></h4>
            <p
              class="accordion-content-body"
              translate="IF_YOU_SET_AN_EXPIRY_DATE_FOR_YOUR_POST_THE_POST_WILL_BE_DELETED_WITHIN_ORLO_AND_REMOVED_FROM_THE_SOCIAL_NETWORKS_AT_THE_SPECIFIED_DATE_AND_TIME"
            ></p>
            <ssi-date-time-picker
              [dateLabel]="'EXPIRY_DATE' | translate"
              [timeLabel]="'EXPIRY_TIME' | translate"
              [pastDateTooltipTitle]="'UNAVAILABLE_DAY_TO_SET_THE_EXPIRY' | translate"
              [pastDateTooltipBody]="'CONTENT_CANT_BE_SET_TO_EXPIRY_IN_THE_PAST' | translate"
              [placeholder]="'NO_EXPIRY_DATE_SET' | translate"
              [calHeaderTitle]="'SELECT_POST_EXPIRY_DATE' | translate"
              name="expiryTime"
              [disabled]="!post.expiryEnabled"
              [(ngModel)]="expiryTime"
              [selectedDate]="expiryTime"
              (ngModelChange)="expiryDateChanged($event)"
            >
            </ssi-date-time-picker>
          </ssi-accordion>
          <ng-template #customAccordionHeading>
            <div
              class="toggle-label"
              [class.toggled]="post.expiryEnabled"
            >
              <p translate="POST_EXPIRY"></p>
              <ssi-toggle-switch-2
                [(ngModel)]="post.expiryEnabled"
                (ngModelChange)="postExpiryToggleChange($event)"
              ></ssi-toggle-switch-2>
            </div>
          </ng-template>
        </div>

        <div
          class="accordion-content"
          *ngIf="post?.accounts.length && post?.scheduleFirstCommentToggleVisible()"
        >
          <ssi-accordion
            [label]="scheduleFirstCommentAccordionHeading"
            [isOpen]="post.scheduleFirstCommentToggled"
            [disabled]="!post.scheduleFirstCommentToggled"
            [isValid]="true"
          >
            <ssi-share-first-comment
              *ngIf="post?.scheduleFirstCommentToggled"
              [composer]="post"
            ></ssi-share-first-comment>
          </ssi-accordion>

          <ng-template #scheduleFirstCommentAccordionHeading>
            <div
              class="toggle-label"
              [class.toggled]="post.scheduleFirstCommentToggled"
            >
              <p translate="SHARE_FIRST_COMMENT"></p>
              <ssi-toggle-switch-2
                [disabled]="!post.accounts.length"
                [(ngModel)]="post.scheduleFirstCommentToggled"
              ></ssi-toggle-switch-2>
            </div>
          </ng-template>
        </div>

        <div
          *ngIf="post?.accounts.length && hasAnyTargetableAccounts() && postInitialised"
          class="targeting-options-posts accordion-content"
        >
          <ssi-accordion
            #targetingPostsAccordion
            [label]="hasMultipleTargetableAccounts() ? 'Targeting Options' : targetingAccordionHeading"
            [isOpen]="false"
            [isValid]="true"
            [class.multiple-targetable-account]="hasMultipleTargetableAccounts()"
          >
            <div class="target-options-list">
              <ssi-targeting-options
                #targetingOptionsComponent
                [post]="post"
                [selectedAccountTypeIds]="selectedAccountTypeIds"
                [targetingPostsAccordion]="targetingPostsAccordion"
                (formChange)="onTargetingFormChange($event)"
                [enabled]="targetingEnabled || hasMultipleTargetableAccounts()"
                [multipleAccounts]="hasMultipleTargetableAccounts()"
                [multipleNextdoorAccountTypes]="hasMultipleNextdoorAccountTypes()"
                [previewToggled]="showPreview"
              ></ssi-targeting-options>
            </div>
          </ssi-accordion>
          <ng-template #targetingAccordionHeading>
            <div
              class="toggle-label"
              [class.toggled]="targetingEnabled"
            >
              <p translate="TARGETING_OPTIONS"></p>
              <ssi-toggle-switch-2
                [disabled]="!hasAnyTargetableAccounts()"
                [(ngModel)]="targetingEnabled"
              ></ssi-toggle-switch-2>
            </div>
          </ng-template>
        </div>

        <div
          *ngIf="post?.accounts.length && (!post.validity.isValid || shareFirstCommentComponent?.errors.length)"
          class="post-errors accordion-content"
        >
          <ssi-accordion
            #postErrorsAccordion
            [label]="post.validity.errorCount + (shareFirstCommentComponent?.errors.length || 0) + ' Post Errors'"
            [isOpen]="true"
            [isValid]="true"
          >
            <ssi-post-errors
              [post]="post"
              [shareFirstCommentErrors]="shareFirstCommentComponent?.errors"
            ></ssi-post-errors>
          </ssi-accordion>
        </div>
      </div>

      <div
        class="post-preview-container"
        *ngIf="showPreview"
      >
        Preview goes here...
        Preview goes here...
        Preview goes here...
        Preview goes here...
      </div>
    </div>

    <div class="composer-footer">
      <div class="composer-footer-left">
        <ssi-checkbox
          *ngIf="post && post?.postsRequiringValidation !== accounts?.length && !post?.isDraft"
          [checkboxText]="'REQUEST_POST_APPROVAL' | translate"
          [(ngModel)]="post.requiresValidation"
          [leadingIcon]="'ssi ssi-total-approvals-requested'"
          [ssiTooltip]
          tooltipPlacement="top"
          tooltipWindowClass="tooltip-chunky-big"
          [tooltipTemplate]="helperTooltipTemplate"
          [tooltipAppendToBody]="true"
          [tooltipTemplateContext]="{
            title: 'POST_APPROVAL' | translate,
            body: post.postsRequiringValidation ? ('FOR_THE_ACCOUNTS_YOU_CAN_POST_DIRECTLY_ON_TICKING_THIS_WILL_SEND_THOSE_POSTS_INTO_THE_APPROVAL_PROCESS' | translate) : ('TICKING_THIS_WILL_SEND_YOUR_POST_FOR_APPROVAL_WHERE_ANOTHER_ORLO_USER_WITH_PERMISSION_CAN_APPROVE_AND_PUBLISH_OR_REJECT_AND_DELETE' | translate)
          }"
        ></ssi-checkbox>
        <div
          *ngIf="post?.postsRequiringValidation && !post?.isDraft"
          class="composer-footer-label"
          [ssiTooltip]
          tooltipPlacement="top"
          tooltipWindowClass="tooltip-chunky-big"
          [tooltipTemplate]="helperTooltipTemplate"
          [tooltipAppendToBody]="true"
          [tooltipTemplateContext]="{
            title: 'Post Approval',
            body: 'Your content requires approval for one or more of the accounts selected.'
          }"
        >
          <i class="ssi ssi-total-approvals-requested"></i>
          <p>{{post.postsRequiringValidation}} posts are automatically sent for approval</p>
          <i class="ssi ssi-information"></i>
        </div>
        <div
          *ngIf="post?.isDraft"
          class="toggle-label"
          [class.toggled]="isDraftPublic"
        >
          <p>{{ isDraftPublic ? ('PUBLIC_DRAFT' | translate) : ('PRIVATE_DRAFT' | translate) }} <i
              [ssiTooltip]="'PRIVATE_DRAFTS_ARE_ONLY_AVAILABLE_TO_YOU_AND_WILL_BE_DELETED_ONCE_SENT_PUBLIC_DRAFTS_WORK_LIKE_TEMPLATES_OTHER_USERS_CAN_SEE_THEM_AND_THEY_DO_NOT_GET_DELETED_ONCE_USED' | translate"
              class="ssi ssi-information"
              tooltipPlacement="top"
              tooltipWindowClass="tooltip-chunky-big"
            ></i></p>
          <i></i>
          <ssi-toggle-switch-2
            class="wrapped"
            [(ngModel)]="isDraftPublic"
          ></ssi-toggle-switch-2>
        </div>
      </div>

      <div class="composer-footer-right">
        <button
          *ngIf="!post?.isDraft"
          type="button"
          [disabled]="!post?.validity.isValid || composerService.publishDisabled"
          class="g-btn g-btn-md"
          (click)="publish()"
        >
          <i class="ssi ssi-send-icon"></i>
          <span
            *ngIf="requiresApproval"
            translate="SEND_FOR_APPROVAL"
          ></span>
          <span
            *ngIf="!requiresApproval && post?.schedules.length === 1"
            translate="SCHEDULE_POST"
          ></span>
          <span
            *ngIf="!requiresApproval && post?.schedules.length > 1"
            translate="SCHEDULE_POSTS"
          ></span>
          <span
            *ngIf="!requiresApproval && post?.schedules.length === 0"
            translate="PUBLISH_NOW"
          ></span>
        </button>
        <button
          *ngIf="post?.isDraft"
          type="button"
          [disabled]="!post.validity.isValid || composerService.publishDisabled"
          class="g-btn g-btn-md"
          (click)="saveDraft()"
        ><i class="ssi ssi-drafts"></i><span translate="SAVE_DRAFT"></span></button>
      </div>
    </div>
  </ng-container>
</div>


<div
  *ngIf="layout === ComposerLayout.Minimised"
  class="minimised-view"
  (click)="changeLayout(ComposerLayout.Full)"
>
  <span translate="NEW_POST"></span> <i class="ssi ssi-expand-correct"></i>
</div>

<ng-template
  #helperTooltipTemplate
  let-title="title"
  let-body="body"
>
  <div class="tooltip-body">
    <div class="tooltip-body-title">{{title}}</div>
    <div class="tooltip-body-contents">{{body}}</div>
  </div>

  <div
    data-popper-arrow
    class="tooltip-arrow"
  ></div>
</ng-template>

<ng-template
  ssiTemplateSelector="menuBody"
  #mediaUploadOptionsMenuBodyTemplate
>
  <ul class="g-list attachment-type-list">
    <li
      class="g-list-item"
      *ngIf="post.mediaRestrictions.image.remaining > 0 && post.mediaCategory !== MediaCategory.Reel && (!post.mediaRestrictions.image.linkRequired || post.links.length > 0)"
      [ssiTooltip]="'UPLOAD_A_JPEG_OR_GIF' | translate"
      [tooltipWindowClass]="'tooltip-chunky'"
      ssiFileUploader
      [mediaCategory]="post.mediaCategory"
      [accept]="getImageCategoryRestrictions().mimetypes"
      [maxFiles]="getImageCategoryRestrictions().remaining"
      [fromSources]="fileUploadSources"
      [restrictions]="post.mediaRestrictions"
      (uploadStart)="imageUploadStarted()"
      (uploadFinally)="fileUploadComplete()"
      (uploadSuccess)="onUploadSuccess($event)"
      (uploadError)="onUploadError($event)"
      (onClose)="onPickerClosed()"
    >
      <i class="ssi ssi-image-correct image-icon"></i>
      <span>{{post.mediaCategory === MediaCategory.Story ? ('UPLOAD_IMAGE_STORY' | translate) : ('UPLOAD_IMAGE__GIF' | translate)}}</span>
    </li>
    <li
      class="g-list-item"
      *ngIf="post.mediaRestrictions.video.remaining > 0"
      [ssiTooltip]="'CHECK_YOUR_VIDEO_SIZE_TO_MAKE_SURE_YOUR_VIDEO_LOOKS_GREAT_ON_EACH_SOCIAL_NETWORK' | translate"
      [tooltipWindowClass]="'tooltip-chunky-big'"
      ssiFileUploader
      [mediaCategory]="post.mediaCategory"
      [accept]="getVideoCategoryRestrictions().mimetypes"
      [maxFiles]="getVideoCategoryRestrictions().remaining"
      [restrictions]="post.mediaRestrictions"
      [fromSources]="fileUploadSources"
      (uploadStart)="videoUploadStarted()"
      (uploadFinally)="fileUploadComplete()"
      (uploadSuccess)="onUploadSuccess($event)"
      (uploadError)="onUploadError($event)"
      (onClose)="onPickerClosed()"
    >
      <i class="ssi ssi-video-correct video-icon"></i>
      <span>{{post.mediaCategory === MediaCategory.Story ? ('UPLOAD_VIDEO_STORY' | translate) : post.mediaCategory === MediaCategory.Reel ? ('UPLOAD_VIDEO_REEL' | translate) : ('UPLOAD_VIDEO' | translate) }}</span>
    </li>
  </ul>
</ng-template>

<ng-template #draftSavedMessageTemplate>
  <p>
    {{'YOUR_DRAFT_HAS_BEEN_SAVED_YOU_CAN_FIND_IT_IN_YOUR' | translate}}
    <a
      uiSref="auth.marketing.draftsLibrary"
      [uiOptions]="{reload: true}"
      translate="DRAFTS_LIBRARY"
    >
    </a>
  </p>
</ng-template>