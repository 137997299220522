<div class="global-filters-sidebar" *ngIf="!!showGlobalFilters">
  <ssi-global-filters
                      [colleagues]="filteredColleagues"
                      [teams]="filteredTeams"
                      [accounts]="workflowAccounts"
                      [filters]="globalFiltersModel"
                      [monitoringStreams]="streams"
                      (onClearFiltersClick)="onRemoveGlobalFilters(activeFilters); onActiveFiltersChange([])"
                      (onFiltersClick)="showGlobalFilters = !showGlobalFilters"
                      (onApplyFilters)="onApplyGlobalFilters($event)"></ssi-global-filters>
</div>
<div class="view-report-container">
  <ssi-report-action-bar
    [report]="report"
    [templateMode]="templateMode"
    [showSaveButton]="activeFilters.length"
    [showPinButton]="activeFilters.length"
    [reportView]="reportView"
    [activeFilters]="activeFilters"
    [userOwnsReport]="authUser?.id === report?.created_by"
    [pdfExportDisabled]="loading$ | async"
    [widgetsLoaded]="areWidgetsLoaded()"
    [selectedPosts]="selectedPosts"
    [disableBulkEmotions]="disableBulkEmotions"
    [disableBulkDelete]="disableBulkDelete"
    (onPinClick)="filtersBarPinned = $event"
    (onDeleteClick)="openDeleteReportModal()"
    (onShareClick)="copyShareLinkToClipboard()"
    (onAddWidgetClick)="goToAddWidget()"
    (onEditReportClick)="goToEditReport()"
    (onCopyReportClick)="goToCreateReport()"
    (onReportViewChange)="onReportViewChange($event)"
    (onFiltersClick)="showGlobalFilters = !showGlobalFilters"
    (onKeywordFilter)="onKeywordFilter($event)"
    (onDateChange)="onDateChange($event)"
    (onSaveClick)="onSaveReport()"
    (onExportClick)="onExportReport()"
    (onClearSelectedPosts)="clearSelectedPosts()"
    (onBulkSentimentUpdated)="bulkSentimentUpdated($event)"
  ></ssi-report-action-bar>

  <ssi-report-filter-bar *ngIf="filtersBarPinned && activeFilters.length"
                         [activeFilters]="activeFilters"
                         [accounts]="workflowAccounts"
                         [monitoringStreams]="streams"
                         (onFiltersChange)="onActiveFiltersChange($event)"
                         (onFilterRemove)="onRemoveGlobalFilters($event)"></ssi-report-filter-bar>

  <div class="content-wrapper">
    <ssi-widgets-grid
                      #widgetsGridRef
                      *ngIf="streamIds && reportView === 'graph'"
                      [viewMode]="true"
                      [filtersBarVisible]="filtersBarPinned && activeFilters.length"
                      [apiWidgets]="report?.widgets"
                      [activeFilters]="activeFilters"
                      [streamIds]="streamIds"
                      (onApplyFilters)="onApplyGlobalFiltersPartial($event)"
                      (totalGridDimensions)="totalGridDimensions($event)"></ssi-widgets-grid>

    <ssi-insights-posts *ngIf="streamIds && reportView === 'comment'"
                        [filtersBarVisible]="filtersBarPinned && activeFilters.length"
                        [activeFilters]="activeFilters"
                        [streamIds]="streamIds"
                        [selectedPosts]="selectedPosts"
                        [bulkSentimentUpdate]="bulkSentimentUpdate"
                        (postSelectToggled)="onPostSelectToggled($event)"></ssi-insights-posts>
  </div>
</div>