import './edit.component.scss';

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  AccessOptions,
  AccessOption,
  accessOptions,
  accessOptionsIterable
} from '../../../../../common/constants';
import { PopupService } from '../../../../../common/services/popup/popup.service';
import { NotificationService } from '../../../../../common/services/notification/notification.service';

import { AddWidgetComponent } from '../../widgets/add-widget/add-widget.component';
import { CustomWidgetComponent } from '../../widgets/custom-widget/custom-widget.component';
import {
  InsightsReport,
  InsightsService,
  InsightsWidget
} from '../../insights.service';
import {
  GridLayoutItem,
  WidgetsGridComponent
} from '../../common/components/widgets-grid/widgets-grid.component';
import {
  Colleague,
  Team,
  MonitoringStreamsService
} from '../../../../../common/services/api';
import {
  colleagues,
  teams,
  authUser,
  workflowAccounts
} from '../../../../common-resolves';
import { Account, User } from '@ui-resources-angular';

@Component({
  selector: 'ssi-edit',
  templateUrl: './edit.component.html',
  styles: []
})
export class EditComponent implements OnInit {
  static resolve = [authUser, colleagues, teams, workflowAccounts, authUser];

  @Input() colleagues: Colleague[];
  @Input() teams: Team[];
  @Input() workflowAccounts: Account[];
  @Input() authUser: User;

  @ViewChild('widgetsGrid') widgetsGrid: WidgetsGridComponent;

  report: InsightsReport;
  reportName: string;
  streams;
  currentWidgetData: InsightsWidget;

  accessOptions: AccessOptions = accessOptions;
  accessOptionsIterable: AccessOption[] = accessOptionsIterable;
  selectedAccessOption = this.accessOptions.private;

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected state: StateService,
    protected modal: NgbModal,
    protected popup: PopupService,
    protected insightsService: InsightsService,
    protected notificationService: NotificationService,
    protected monitoringStreamsService: MonitoringStreamsService
  ) {}

  async ngOnInit() {
    const reportId = this.state.params.id;
    this.report = await this.insightsService.getReport(reportId);
    console.log('report:', this.report);

    this.selectedAccessOption = this.report.is_shared
      ? this.accessOptions.public
      : this.accessOptions.private;

    if (
      this.state.current &&
      this.state.current.data &&
      this.state.current.data.shouldOpenAddWidget
    ) {
      this.openAddWidgetModal();
      this.state.current.data.shouldOpenAddWidget = false;
    }

    this.reportName = this.report.name;

    await this.monitoringStreamsService.getAll().then((streams) => {
      // this.streamIds = streams.map((s) => s.id);
      this.streams = streams;
    });
  }

  async openAddCustomWidgetModal() {
    const modal = this.modal.open(CustomWidgetComponent, {
      windowClass: 'xxl-modal custom-widget-modal'
    });
    // all the data needed for filters
    modal.componentInstance.colleagues = this.colleagues.sort(
      this.sortByFullName
    );
    modal.componentInstance.teams = this.teams.sort(this.sortByTeamName);
    modal.componentInstance.workflowAccounts = this.workflowAccounts;
    modal.componentInstance.authUser = this.authUser;
    modal.componentInstance.streams = this.streams;

    modal.componentInstance.widgetAdded.subscribe((widget: InsightsWidget) => {
      this.widgetsGrid.addNewWidget(widget);
    });
  }

  async editWidget(widget: GridLayoutItem) {
    console.log('Edit widget:', widget);

    const modal = this.modal.open(CustomWidgetComponent, {
      windowClass: 'xxl-modal custom-widget-modal'
    });
    modal.componentInstance.colleagues = this.colleagues.sort(
      this.sortByFullName
    );
    modal.componentInstance.teams = this.teams.sort(this.sortByTeamName);
    modal.componentInstance.workflowAccounts = this.workflowAccounts;
    modal.componentInstance.authUser = this.authUser;
    modal.componentInstance.streams = this.streams;
    modal.componentInstance.currentWidgetData = widget.apiData;

    modal.componentInstance.edit = true;
    modal.componentInstance.widget = widget;
    const modalResData = await modal.result;
    if (modalResData) {
      this.widgetsGrid.updateWidget(widget, modalResData);
      this.widgetsGrid.reRenderWidgets(); // so new config and filters are applied
    }
  }

  async openAddWidgetModal() {
    const modal = this.modal.open(AddWidgetComponent, {
      windowClass: 'xxl-modal'
    });

    modal.componentInstance.widgetAdded.subscribe((widget: InsightsWidget) => {
      this.widgetsGrid.addNewWidget(widget);
    });

    // const result = await modal.result;
    // console.log('result: ', result);
  }

  onAccessChange(selectedOption): void {
    // console.log('selectedAccessOption: ', selectedOption);
  }

  cancel(): void {
    // this.state.go('auth.insights.reports.home');
    window.history.back();
  }

  async save(): Promise<void> {
    console.log('Saving...');

    this.report.name = this.reportName;

    this.report.is_shared =
      this.selectedAccessOption.key === this.accessOptions.public.key;

    const widgetsToSave = this.widgetsGrid.toApiWidgets(
      this.widgetsGrid.widgets
    );
    this.report.widgets = widgetsToSave;

    const updatedReport = await this.insightsService.updateReport(
      this.report.id,
      this.report as any,
      this.workflowAccounts
    );
    console.log('updatedReport: ', updatedReport);

    this.notificationService.open(
      `Your report '${this.report.name}' has been updated, success!`,
      {
        class: 'ssi ssi-completed-notification',
        color: '#B2C614'
      },
      1000
    );

    // this.state.go('auth.insights.reports.home');
    window.history.back();
  }

  async openDeleteReportModal() {
    const shouldDelete = await this.popup.confirm({
      title: 'Delete custom report?',
      message: `You cannot undo this action`,
      okText: 'Delete report',
      windowClass: 'modal-rounded-corners',
      hideClose: true,
      iconClass: 'ssi ssi-delete-microcopy',
      backdrop: true
    });

    if (shouldDelete) {
      await this.insightsService.deleteReport(this.report.id);
      this.notificationService.open(
        `Your report ${this.report.name} has been deleted`,
        {
          class: 'ssi ssi-small-delete',
          color: '#F88C68'
        },
        2000
      );
      this.state.go('^.home');
    }
  }

  sortByFullName(itemA: Colleague, itemB: Colleague) {
    return itemA.fullName.localeCompare(itemB.fullName);
  }

  sortByTeamName(itemA: Team, itemB: Team) {
    return itemA.name.localeCompare(itemB.name);
  }
}
