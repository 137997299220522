// import Popup from './popup';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { joinUrl } from './utils';
import Popup from './popup';

export interface OAuth2Options {
  name: string;
  url: string;
  clientId: string;
  authorizationEndpoint: string;
  redirectUri: string;
  scope: string[];
  scopePrefix: string;
  scopeDelimiter: string;
  state?: string | (() => string);
  requiredUrlParams: string[];
  defaultUrlParams: string[];
  responseType: string;
  responseParams: {
    code: string;
    clientId: string;
    redirectUri: string;
  };
  oauthType: string;
  popupOptions: { width: number; height: number };
}

export default class OAuth2 {
  static camelCase(name): string {
    return name.replace(/([\:\-\_]+(.))/g, (_, separator, letter, offset) => {
      return offset ? letter.toUpperCase() : letter;
    });
  }

  public defaults: OAuth2Options;

  constructor(
    private http: HttpClient,
    private config: any,
    private storageGet: (key: string) => string,
    private storageSet: (key: string, value: string) => void,
    private oauthPopup: Popup
  ) {
    this.defaults = {
      name: null,
      url: null,
      clientId: null,
      authorizationEndpoint: null,
      redirectUri: null,
      scope: null,
      scopePrefix: null,
      scopeDelimiter: null,
      state: null,
      requiredUrlParams: null,
      defaultUrlParams: ['response_type', 'client_id', 'redirect_uri'],
      responseType: 'code',
      responseParams: {
        code: 'code',
        clientId: 'clientId',
        redirectUri: 'redirectUri'
      },
      oauthType: '2.0',
      popupOptions: { width: null, height: null }
    };
  }

  init(options: OAuth2Options, userData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Object.assign(this.defaults, options);

      const stateName = this.defaults.name + '_state';
      const {
        name,
        state,
        popupOptions,
        redirectUri,
        responseType
      } = this.defaults;

      if (typeof state === 'function') {
        this.storageSet(stateName, state());
      } else if (typeof state === 'string') {
        this.storageSet(stateName, state);
      }

      const url = [
        this.defaults.authorizationEndpoint,
        this.buildQueryString()
      ].join('?');

      this.oauthPopup
        .open(url, name, popupOptions, redirectUri)
        .then((oauth: any): void | Promise<any> => {
          if (responseType === 'token' || !this.defaults.url) {
            return resolve(oauth);
          }

          if (oauth.state && oauth.state !== this.storageGet(stateName)) {
            return reject(
              new Error(
                'The value returned in the state parameter does not match the state value from your original ' +
                  'authorization code request.'
              )
            );
          }

          resolve(this.exchangeForToken(oauth, userData));
        })
        .catch((error) => reject(error));
    });
  }

  exchangeForToken(oauthData: { code?; state? }, userData: any): Promise<any> {
    const payload = { ...userData };

    Object.keys(this.defaults.responseParams).forEach((key) => {
      const value = this.defaults.responseParams[key];
      switch (key) {
        case 'code':
          payload[value] = oauthData.code;
          break;
        case 'clientId':
          payload[value] = this.defaults.clientId;
          break;
        case 'redirectUri':
          payload[value] = this.defaults.redirectUri;
          break;
        default:
          payload[value] = oauthData[key];
      }
    });

    if (oauthData.state) {
      payload.state = oauthData.state;
    }

    const exchangeForTokenUrl = this.config.baseUrl
      ? joinUrl(this.config.baseUrl, this.defaults.url)
      : this.defaults.url;

    // TODO: test this......
    return this.http
      .post(exchangeForTokenUrl, payload, {
        withCredentials: this.config.withCredentials
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      )
      .toPromise();
  }

  buildQueryString(): string {
    const keyValuePairs = [];
    const urlParamsCategories = [
      'defaultUrlParams',
      'requiredUrlParams',
      'optionalUrlParams'
    ];

    urlParamsCategories.forEach((paramsCategory) => {
      if (Array.isArray(this.defaults[paramsCategory])) {
        this.defaults[paramsCategory].forEach((paramName) => {
          const camelizedName = OAuth2.camelCase(paramName);
          let paramValue =
            typeof this.defaults[paramName] === 'function'
              ? this.defaults[paramName]()
              : this.defaults[camelizedName];

          if (paramName === 'redirect_uri' && !paramValue) {
            return;
          }
          if (paramName === 'state') {
            const stateName = this.defaults.name + '_state';
            paramValue = encodeURIComponent(this.storageGet(stateName));
          }
          if (paramName === 'scope' && Array.isArray(paramValue)) {
            paramValue = paramValue.join(this.defaults.scopeDelimiter);
            if (this.defaults.scopePrefix) {
              paramValue = [this.defaults.scopePrefix, paramValue].join(
                this.defaults.scopeDelimiter
              );
            }
          }

          keyValuePairs.push([paramName, paramValue]);
        });
      }
    });

    return keyValuePairs.map((pair) => pair.join('=')).join('&');
  }
}
