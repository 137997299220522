import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SecondsToHumanTimePipe } from './seconds-to-human-time.pipe';
import { SecondsToHumanTime2Pipe } from './seconds-to-human-time.pipe-2';

@NgModule({
  imports: [CommonModule],
  declarations: [SecondsToHumanTimePipe, SecondsToHumanTime2Pipe],
  exports: [SecondsToHumanTimePipe, SecondsToHumanTime2Pipe]
})
export class SecondsToHumanTimeModule {}
