import './widget-big.component.scss';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  Sentiment,
  sentimentsIterable,
  sentiments
} from '../../../../../../common/constants';
import { WidgetSchema } from '../../../../../../common/services/insights/insights';
import {
  InsightsService,
  Big,
  Schema,
  networksWithInsightsSources
} from '../../../insights.service';
import { Filter } from '../../../reports/view/view-report.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DrilldownModalComponent } from '../drilldown-modal/drilldown-modal.component';
import {
  FieldName,
  filtersFieldDefinitions,
  formatFilterTypeData
} from '../../constants/filters-field-definitions';
import { viewClassName } from '@angular/compiler';

@Component({
  selector: 'ssi-widget-big',
  templateUrl: './widget-big.component.html',
  styles: []
})
export class WidgetBigComponent implements OnInit, OnChanges {
  @Input() schema: Schema;
  @Input() widget: Big;
  @Input() filters: Filter[];
  @Input() globalFilters: Filter[];
  @Input() viewMode = false;
  @Input() streamIds: string[];
  @Input() initRender: boolean = false;
  themeLoaded = true;

  @Output() loaded = new EventEmitter<void>();

  showThemeAdding = false;

  thematicSearch: string;
  selectedBreakdownField: WidgetSchema;
  breakdownFields: WidgetSchema[];

  sentimentsConstIterable: Sentiment[] = sentimentsIterable;

  headers: string[];
  rows: {
    name: string;
    columns: {
      count: number;
      name: string;
      sentiment: number;
      sentimentColor: string;
    }[];
  }[] = [];
  themes: { label: string; fullText: string }[] = [];
  allThemesData: {
    [themeCatKey: string]: {
      [breakdownByKey: string]: any[];
    };
  } = {};
  categoriesWithFilters: { label: string; filters: any[] }[] = [];

  constructor(
    private insightsService: InsightsService,
    private modal: NgbModal
  ) {}

  async ngOnInit() {
    if (this.initRender) {
      this.requestData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.schema && changes.schema.currentValue) {
      // this.breakdownFields =
      //   this.schema && this.schema.fields.filter((f) => f.can_be_dimension);
      this.breakdownFields =
        this.schema &&
        this.schema.fields.filter(
          (f) => f.name === 'Created At' && f.can_be_dimension
        );
      this.selectedBreakdownField = this.breakdownFields.find(
        (f) => f.name === FieldName.CreatedAt
      );
    }

    if (
      changes.filters &&
      changes.filters.currentValue.length === 0 &&
      this.widget.categories.length === 0
    ) {
      this.loaded.emit();
      return;
    }

    if (
      (changes.filters &&
        changes.filters.currentValue &&
        !changes.filters.firstChange) ||
      (changes.globalFilters &&
        changes.globalFilters.currentValue &&
        this.widget.categories.length &&
        !changes.globalFilters.firstChange) ||
      (changes.globalFilters &&
        !changes.globalFilters.previousValue &&
        changes.globalFilters.currentValue.length !== 0 &&
        changes.globalFilters.firstChange)
    ) {
      this.requestData();
    }
  }

  async requestData() {
    this.themeLoaded = false;
    if (this.selectedBreakdownField) {
      this.widget.break_down_by.field = this.selectedBreakdownField.name;
    }

    await this.insightsService
      .aggregateWidgetData(this.widget, this.globalFilters, this.streamIds)
      .then(({ data }) => {
        this.allThemesData = data;
        console.log('Theme mapping data:', data);
      });
    this.mapResponseToRowsandColumns();
  }

  resetWidgetData() {
    this.themes = [];
    this.allThemesData = {};
    this.thematicSearch = null;
    this.headers = [];
    this.rows = [];
    this.themeLoaded = true;
  }

  async removeTheme(themeLabel: string) {
    this.themeLoaded = false;
    this.widget.categories = this.widget.categories.filter(
      (cat) => cat.label !== themeLabel
    );
    if (this.widget.categories.length === 0) {
      this.resetWidgetData();
      return;
    }
    await this.requestData();
  }

  getSentimentColor(sentimentValue: number): string {
    const roundedValue = Math.round(sentimentValue);
    const sentiment = sentimentsIterable.find(
      (s) => s.numericKey === roundedValue
    );

    return sentiment ? sentiment.color2 : sentiments.neutral.color2;
  }

  async requestDataAndAddColumn() {
    try {
      this.themeLoaded = false;
      const filter: any = {
        field: FieldName.ContentVector,
        semantic_search: {
          min_score: 0.65,
          query: this.thematicSearch
        }
      };
      const filters = [filter];
      this.widget.break_down_by.field = this.selectedBreakdownField.name;

      // preventing duplicate labels since backend can't differentiate
      const filterLabelExist =
        this.widget.categories.findIndex(
          (l) => l.label === this.thematicSearch
        ) > -1;
      if (!filterLabelExist) {
        this.widget.categories.push({
          label: this.thematicSearch,
          filters
        });
      }

      const { data } = await this.insightsService.aggregateWidgetData(
        this.widget,
        []
      );
      const headerLabels = this.widget.categories.map(
        (category) => category.label
      );
      headerLabels.forEach((label) => {
        this.allThemesData[label] = data[label];
      });
      this.thematicSearch = null;
      this.mapResponseToRowsandColumns();
    } catch (error) {
      this.thematicSearch = null;
      this.themeLoaded = true;
    }
  }

  mapResponseToRowsandColumns(): void {
    const rows = [];
    Object.keys(this.allThemesData).forEach((keyword, index) => {
      const keywordObj = this.allThemesData[keyword];
      Object.keys(keywordObj).forEach((category) => {
        const rowExists = rows.find((row) => row.name === category);
        if (!rowExists) {
          rows.push({
            name: category,
            columns: []
          });
        }
      });
    });
    rows.forEach((row) => {
      Object.keys(this.allThemesData).forEach((keyword, index) => {
        const keywordObj = this.allThemesData[keyword];
        row.columns.push({
          name: keyword,
          count: keywordObj[row.name] && keywordObj[row.name][0],
          sentiment:
            (keywordObj[row.name] &&
              keywordObj.hasOwnProperty(row.name) &&
              keywordObj[row.name][1]) ||
            '',
          sentimentColor:
            (keywordObj[row.name] &&
              keywordObj.hasOwnProperty(row.name) &&
              this.getSentimentColor(keywordObj[row.name][1])) ||
            '#fff'
        });
      });
    });

    this.selectedBreakdownField = this.breakdownFields.find(
      (f) => f.name === this.widget.break_down_by.field
    );
    this.headers = rows[0] && rows[0].columns;
    this.rows = rows;
    console.log(rows);
    this.themeLoaded = true;
    this.loaded.emit();
  }

  openDrilldown(value, rowName: string) {
    const category =
      this.widget.break_down_by.field ===
      filtersFieldDefinitions['Is Private'].name
        ? JSON.parse(rowName)
        : rowName;
    const filterType =
      filtersFieldDefinitions[this.widget.break_down_by.field]
        .preferedFilterType;
    const selectedFilters = [];

    const modal = this.modal.open(DrilldownModalComponent, {
      windowClass: 'xxl-modal'
    });

    const categoryFilter = this.widget.categories.find(
      ({ label }) => label === value.name
    ).filters[0];

    selectedFilters.push(categoryFilter);
    selectedFilters.push({
      field: this.selectedBreakdownField.name,
      [filtersFieldDefinitions[this.selectedBreakdownField.name]
        .preferedFilterType]: formatFilterTypeData(category, filterType)
    });

    modal.componentInstance.enableThemeClusters = false;
    modal.componentInstance.streamIds = this.streamIds;
    modal.componentInstance.widgetFilters = this.widget.filters;
    modal.componentInstance.selectedFilters = selectedFilters;
  }
}
