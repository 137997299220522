import _ from 'lodash';
import { Injectable } from '@angular/core';
import { API } from '@ui-resources-angular';
import { StateService } from '@uirouter/angular';
import { PopupService } from '../../../../common/services/popup/popup.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { sourceTypeName } from '../monitoring-streams/monitoring-streams.service';

export interface StreamQuery {
  geo?: {
    enabled: boolean;
    radius: number;
  };
  id?: string;
  latest_result?: string;
  name: string;
  description?: string;
  query?: any;
  // query?: {
  //   comparison: string;
  //   type: string;
  //   value: string;
  // }[];
  lang?: string;
  sources?: sourceTypeName[];
  listening_group_ids?: string[];
  'include-retweets': boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StreamBuilderService {
  streamQuery: StreamQuery = {
    geo: {
      enabled: false,
      radius: 25
    },
    name: '',
    description: '',
    query: {},
    sources: [],
    listening_group_ids: [],
    'include-retweets': true
  };
  streamQueryCache: StreamQuery;

  private $streamQuery: BehaviorSubject<StreamQuery> = new BehaviorSubject(
    this.streamQuery
  );

  constructor(
    private api: API,
    private state: StateService,
    private popup: PopupService,
    private translate: TranslateService
  ) {}

  fetchStreamQueryObservable(): Observable<StreamQuery> {
    return this.$streamQuery.asObservable();
  }

  fetchStreamQuery(): StreamQuery {
    return this.$streamQuery.value;
  }

  updateStreamQuery(query: StreamQuery) {
    if (!this.streamQueryCache || this.streamQueryCache.id !== query.id) {
      this._updateStreamQueryCache(query);
    }
    this.$streamQuery.next(query);
  }

  refreshStreamQuery() {
    this.streamQuery = {
      geo: {
        enabled: false,
        radius: 25
      },
      name: '',
      description: '',
      query: {},
      sources: [],
      listening_group_ids: [],
      'include-retweets': true
    };
    this.updateStreamQuery(this.streamQuery);
  }

  private _updateStreamQueryCache(query: StreamQuery) {
    this.streamQueryCache = {
      geo: {
        enabled: false,
        radius: 25
      },
      name: '',
      description: '',
      query: {},
      sources: [],
      listening_group_ids: [],
      'include-retweets': true
    };
    this.streamQueryCache = _.merge(this.streamQuery, query);
  }

  saveStreamQuery(query: StreamQuery) {
    const saveStream = (streamToSave) => {
      this.api.post('monitoring/index_v2', streamToSave).then(() => {
        this.refreshStreamQuery();
        this.state.go('auth.monitoring.monitoringStreamsNew');
        this.popup.alert({
          title: this.translate.instant('STREAM_CREATED'),
          message: this.translate.instant(
            'WE_ARE_CURRENTLY_GATHERING_INFORMATION_FOR_THIS_QUERY_PLEASE_CHECK_BACK_LATER'
          )
        });
      });
    };

    if (query.id) {
      const patchSupportedKeys = ['name', 'description'];
      const queryDiffKeys = _.reduce(
        query,
        (result, value, key) => {
          return _.isEqual(value, this.streamQueryCache[key])
            ? result
            : result.concat(key);
        },
        []
      );

      // no differences, do nothing, return to streams home
      if (queryDiffKeys && queryDiffKeys.length === 0) {
        return this.state.go('auth.monitoring.monitoringStreamsNew');
      }

      if (queryDiffKeys.some((key) => patchSupportedKeys.indexOf(key) === -1)) {
        return this.api.put('monitoring/index_v2', query).then(() => {
          this.state.go('auth.monitoring.monitoringStreamsNew');
        });
      }

      const queryDiff: Partial<StreamQuery> = {};
      queryDiff.id = query.id;
      queryDiffKeys.forEach((key) => (queryDiff[key] = query[key]));

      return this.api
        .post('monitoring/index_v2', queryDiff, {
          params: { _method: 'PATCH' }
        })
        .then(() => this.state.go('auth.monitoring.monitoringStreamsNew'));
    } else {
      saveStream(query);
    }
  }

  deleteSearchStream(id, redirect) {
    return this.api.del('monitoring/index_v2', { params: { id } }).then(() => {
      this.refreshStreamQuery();
      if (redirect) {
        this.state.go('auth.monitoring.monitoringStreamsNew');
        this.popup.alert({
          title: 'Stream Deleted'
        });
      }
    });
  }
}
