import './insights-post.component.scss';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  Account,
  AccountModel,
  Activity,
  ActivityTags,
  OutboxModel
} from '@ui-resources-angular';
import {
  emotions,
  Emotions,
  emotionsIterableSortedAZ,
  findSentimentConst,
  Sentiment,
  sentimentsIterable
} from '../../../../../../../common/constants';
import { LinkifyPipe } from '../../../../../../../common/pipes/linkify/linkify.pipe';
import { Option } from '../../../../../../../common/components/dropdown-select-2/dropdown-select-2.component';
import { AssignMessageComponent } from '../../../../../../../common/components/assign-message/assign-message.component';
import { EmailToModalComponent } from '../../../../../../../common/components/email-to-modal/email-to-modal.component';
import { NotificationService } from '../../../../../../../common/services/notification/notification.service';
import { InsightsPost, InsightsPostType } from '../../../../insights.service';
import { SocialType, SocialTypeName } from '../../../../../../../common/enums';
import { Colleague, Team } from '../../../../../../../common/services/api';
import { isNumeric } from '../../../../../../../common/utils';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';

@Component({
  selector: 'ssi-insights-post',
  templateUrl: './insights-post.component.html',
  styles: []
})
export class InsightsPostComponent implements OnInit, OnChanges {
  @Input() post: InsightsPost;
  @Input() selected = false;
  @Input() modalView = false;
  @Input() bulkSentimentUpdate: number;

  @Output() toggleSelect = new EventEmitter<InsightsPost>();

  @ViewChild('sentimentMenu') sentimentMenu: CdkConnectedOverlay;
  showSentimentMenu = false;

  activity: Activity;
  InsightsPostType = InsightsPostType;
  formattedContent: string;
  contentCropped = false;
  showNativeLink = false;
  sentimentConst: Sentiment;
  emotions: Emotions = emotions;
  emotionsSorted = emotionsIterableSortedAZ.map(
    ({ icon, ...keepAttrs }) => keepAttrs
  );
  SocialType = SocialType;
  SocialTypeName = SocialTypeName;

  tags: Option[] = [];
  selectedTags: Option[] = [];
  selectedEmotions: Option[] = [];

  accounts: Account[] = [];
  selectedAccount: Account;

  stars = [1, 2, 3, 4, 5];

  constructor(
    public activeModal: NgbActiveModal,
    protected modal: NgbModal,
    protected outboxModel: OutboxModel,
    protected accountModel: AccountModel,
    protected notificationService: NotificationService,
    protected linkifyPipe: LinkifyPipe,
    protected activityTags: ActivityTags
  ) {}

  ngOnInit() {
    // monitoring results are instantiated from ActivityModel as well
    this.activity = this.post.data;

    this.accounts = JSON.parse(localStorage.getItem('workflowAccountIds'))
      .map((a) => this.accountModel.inject(a))
      .filter((a) => a.isTwitter());

    // console.log(this.post);

    this.formattedContent = this.formatContent();

    this.sentimentConst = findSentimentConst(
      'key2',
      this.activity.sentiment && this.activity.sentiment.key
    );

    this.populateTags();
    this.showNativeLink = this.canShowNativeLink();

    if (this.activity.outbox_message) {
      this.activity.outbox = this.outboxModel.inject(
        this.activity.outbox_message
      );
    }

    if (
      this.activity.insights &&
      this.activity.insights.content &&
      this.activity.insights.content.emotion &&
      Array.isArray(this.activity.insights.content.emotion)
    ) {
      this.selectedEmotions = this.activity.insights.content.emotion.map(
        (selectedEmotion) => {
          return this.emotionsSorted.find((e) => e.key === selectedEmotion);
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.activity) {
        if (changes.bulkSentimentUpdate && this.selected) {
          const newSentiment = this.bulkSentimentUpdate;
          this.changeSentiment(newSentiment);
        }

        this.sentimentConst = findSentimentConst(
          'key2',
          this.activity.sentiment && this.activity.sentiment.key
        );
      }
    }
  }

  toggleSelected(post): void {
    this.toggleSelect.emit(post);
  }

  formatContent(): string {
    const maxLength = 240;
    const content = this.activity.interaction.content || '';

    function canCrop(text: string, index: number): boolean {
      const char = text[index];
      return char === '\n' || char === ' ';
    }

    if (this.modalView || content.length <= maxLength) {
      return this.linkifyPipe.transform(content);
    }

    // crop content on white space only (avoid breaking links, mentions, hashtags, etc.)
    // find the first space before maxLength and crop content there
    for (let i = maxLength - 1; i >= 0; i--) {
      if (canCrop(content, i) && !canCrop(content, i - 1)) {
        const croppedContent = `${content.substring(0, i)}...`;
        this.contentCropped = true;
        return this.linkifyPipe.transform(croppedContent);
      }
    }

    return '';
  }

  readMore(): void {
    const modal = this.modal.open(InsightsPostComponent, {
      windowClass: 'm-modal rounded-corners-15',
      centered: true
    });
    modal.componentInstance.post = this.post;
    modal.componentInstance.modalView = true;
  }

  populateTags(): void {
    this.activityTags.getTags().then((tags: string[]) => {
      this.tags = tags.map((tag) => {
        return {
          id: tag,
          label: tag
        };
      });

      if (Array.isArray(this.activity.tags)) {
        this.selectedTags = this.tags.filter(
          (tag) => this.activity.tags.indexOf(tag.id) > -1
        );
      }
    });
  }

  updateTags(selectedTags: Option[]): void {
    this.activity.setTags(
      this.selectedTags.map((t) => t.id),
      this.post.type === InsightsPostType.Monitoring
    );
  }

  changeSentiment(sentiment: number) {
    this.activity
      .changeSentiment(
        sentiment,
        this.post.type === InsightsPostType.Monitoring
      )
      .then(() => {
        this.sentimentConst = sentimentsIterable.find(
          (s) => s.numericKey2 === sentiment
        );
      });
  }

  updateEmotions(selectedEmotions: Option[]): void {
    this.activity.updateEmotions(
      this.selectedEmotions.map((e) => e.key),
      this.post.type === InsightsPostType.Monitoring
    );
  }

  deletePost() {
    this.activity
      .remove(this.post.type === InsightsPostType.Monitoring)
      .then(() => {
        this.notificationService.open(
          `1 Post result has been deleted`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          5000
        );
      });
  }

  hasMultipleMediaItems(): boolean {
    const mediaItems =
      Array.isArray(this.activity.media) &&
      this.activity.media.filter(
        (m) =>
          m.type === 'photo' ||
          m.type === 'gif' ||
          m.type === 'animated_gif' ||
          m.type === 'video'
      );

    return mediaItems.length > 1;
  }

  assignPost(): void {
    const modal = this.modal.open(AssignMessageComponent, {
      windowClass: 'rounded-corners-40',
      centered: true
    });
    modal.componentInstance.activity = this.activity;
    modal.componentInstance.isMonitoringActivity =
      this.post.type === InsightsPostType.Monitoring;

    modal.componentInstance.onAssigned.subscribe(
      (assignedUserOrTeam: Colleague | Team) => {
        const userOrTeamName =
          assignedUserOrTeam instanceof Colleague
            ? assignedUserOrTeam.fullName
            : assignedUserOrTeam.name;

        this.notificationService.open(
          `1 Post result has been assigned to ${userOrTeamName}`,
          {
            class: 'ssi ssi-completed-notification',
            color: '#B2C614'
          },
          5000
        );
      }
    );
  }

  pushToCompose(): void {
    console.log('pushToCompose...');
  }

  emailPost(): void {
    const modal = this.modal.open(EmailToModalComponent, {
      windowClass: 'xl-modal orlo-modal'
    });
    modal.componentInstance.activity = this.activity;
    // modal.componentInstance.activityThread = this.activityThread;
  }

  viewNatively(): void {
    window.open(this.activity.interaction.link);
  }

  pushToInbox(account?: Account): void {
    let promise;
    if (this.post.type === InsightsPostType.Monitoring) {
      promise = this.activity.pushMonitoringToInbox(account);
    } else {
      promise = this.activity.pushToInbox();
    }

    promise.then(() => {
      this.notificationService.open(
        '1 Post result has been pushed to your Inbox',
        {
          class: 'ssi ssi-completed-notification',
          color: '#B2C614'
        },
        5000
      );

      this.selectedAccount = undefined;
    });
  }

  canShowNativeLink(): boolean {
    if (this.post.type === InsightsPostType.Monitoring) {
      return true;
    }

    if (
      !this.activity ||
      !this.activity.interaction ||
      !this.activity.social_actions ||
      (this.activity.interaction.social_type === 'instagram_graph' &&
        this.activity.interaction.is_private)
    ) {
      return false;
    }

    return (
      !this.activity.interaction.deleted_at &&
      !this.activity.social_actions.is_hidden &&
      !!this.activity.interaction.link
    );
  }

  isNumber(value: any): boolean {
    return isNumeric(value);
  }
}
