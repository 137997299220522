import { Subject } from 'rxjs/Subject';
import { distinctUntilChanged } from 'rxjs/operators/distinctUntilChanged';
import { Record, utils } from 'js-data';

import { api } from '../../core/services/api';
import { services } from '../../common';
import { Model } from '../../model';
// import { AuthService } from '../../../../../apps/angular/common/services/auth/auth.service';

export class User extends Record {
  id: string;
  company_id: number;
  company_name: string;
  first_name: string;
  last_name: string;
  email_address: string;
  permissions: any;
  application_rating: number;
  company_is_locked: boolean;
  company_uuid: string;
  created_at: string;
  is_password_change_required: boolean;
  is_policy_agreed_to: boolean;
  name: string;
  plan_type: number;
  total_trial_days: number;
  trial_days_left: number;
  intercom_user_hash: string;

  get fullName() {
    return `${this.first_name} ${this.last_name}`;
  }

  set fullName(val) {}

  /**
   * Checks permissions for given accounts. Accepted types are
   *
   * hasAccountPermission(2, 'post') - Checks account 2 has permission combination
   * hasAccountPermission([2,3], 'post') - Checks accounts 2 & 3 has permission combination
   * hasAccountPermission([[2,3],[4,5]], 'post') - Checks accounts 2 & 3 OR 4 & 5 has permission combination
   * hasAccountPermission(2, ['post', 'view_inbox']) - Checks account combination has both post and view_inbox permission
   * hasAccountPermission(2, [['post', 'view_inbox'], ['post_un-validated', 'post']]) - Checks the account combination has
   * either post & view_inbox OR post_un-vatlidated & post
   *
   * One can provide as many account and/or permission combinations
   *
   * @param array accountIds
   * @param array permissions
   * @returns {Boolean}
   */
  hasAccountPermission(accountGroups, permissions) {
    if (!Array.isArray(permissions)) {
      permissions = [[permissions]];
    } else if (!Array.isArray(permissions[0])) {
      permissions = [permissions];
    }

    if (!Array.isArray(accountGroups)) {
      accountGroups = [[accountGroups]];
    } else if (!Array.isArray(accountGroups[0])) {
      accountGroups = [accountGroups];
    }

    return (
      accountGroups.filter((accountGroup) => {
        const accountIdsWithPermissions = accountGroup.filter((accountId) => {
          accountId = accountId.id ? accountId.id : accountId;
          return permissions.find((permissionGroup) => {
            return (
              permissionGroup.filter((permission) => {
                return (
                  this.permissions[accountId] &&
                  this.permissions[accountId][permission]
                );
              }).length === permissionGroup.length
            );
          });
        });
        return accountGroup.length === accountIdsWithPermissions.length; // all account ids have to match
      }).length > 0
    ); // at least one account group has to match
  }

  hasCompanyPermission(permissions): boolean {
    if (typeof this.permissions.company === 'undefined') {
      return false;
    }
    return this.hasAccountPermission('company', permissions);
  }

  hasAccountAccess(account) {
    // as we load all accounts for all company users we need to check if the account is actually available to this user
    return !!this.permissions[account.id];
  }

  changePassword(password: string) {
    return api.post('settings/password', { password });
  }

  // refreshAuthToken(auth: AuthService): Promise<{ token: string }> {
  //   const originalToken = auth.getToken();
  //   return api
  //     .post<{ data: { token: string } }>(
  //       'user/authToken',
  //       {},
  //       { autoError: false }
  //     )
  //     .then(({ data: { token } }) => {
  //       auth.setToken(token);
  //       const userModel = services.models.get<UserModel>('user');
  //       userModel.events.loggedIn.next({ token });
  //       return api
  //         .get('user/logout', {
  //           autoError: false,
  //           skipAuthorization: true,
  //           headers: { [apiAuthHeader]: `Bearer ${originalToken}` }
  //         })
  //         .then(() => ({ token }));
  //     });
  // }
}

export class UserModel extends Model<User> {
  events = {
    loggedIn: new Subject<{ token: string }>(),
    loggedOut: new Subject<void>(),
    changed: new Subject().pipe(
      distinctUntilChanged(null, ({ user }) => user.id)
    ) as Subject<{ user: User }>
  };

  private permissionsCache;

  constructor() {
    super('user', {
      endpoint: 'user/index',
      deserialize: (resourceConfig, result) => [result.data],
      recordClass: User
    });
  }

  getAuthUser(bypassCache = false) {
    return utils.Promise.all([
      this.findAll({}, { bypassCache }) as any,
      this.getPermissions(bypassCache)
    ]).then(([user, permissions]) => {
      user = Array.isArray(user) ? user[0] : (user as User);
      if (!user) {
        return utils.Promise.reject('User not found');
      }
      user.permissions = permissions;
      this.events.changed.next({ user });
      return user;
    });
  }

  getPermissions(bypassCache) {
    if (!this.permissionsCache || bypassCache) {
      return api.get('user/permission').then((result) => {
        this.permissionsCache = utils.Promise.resolve(result.data);
        return result.data;
      });
    }
    return this.permissionsCache;
  }

  login(email, password, longExpire, twoFactorAuthCode) {
    return api
      .post('user/login', {
        email_address: email,
        password,
        long_expire: longExpire,
        two_factor_authentication_code: twoFactorAuthCode,
        type: 'web'
      })
      .then(({ data: result }: { data: any }) => {
        if (result.two_factor_authentication_required) {
          return utils.Promise.reject({ twoFactorAuthRequired: true });
        }
        const token = result.auth_token;
        delete result.auth_token;
        this.events.loggedIn.next({ token });
        return result;
      });
  }

  logout() {
    return api
      .get('user/logout', { autoError: false })
      .catch(() => undefined)
      .finally(() => {
        // hack to make sure the logout redirect has been triggered so nothing in the active view is trying to use model data
        setTimeout(() => {
          services.store.clear();
        }, 500);
        this.permissionsCache = null;
        this.events.loggedOut.next();
      });
  }

  resetPassword(email) {
    return api.post('user/passwordReset', { email_address: email });
  }
}

export function userModelFactory(dataStore?) {
  return services.models.get('user') || new UserModel();
}
