import './insights.component.scss';
import { Component, OnInit, Input } from '@angular/core';
import { StateService, TransitionService } from '@uirouter/angular';
import { CompanyService } from '../../../common/services/company/company.service';

@Component({
  selector: 'ssi-insights',
  templateUrl: './insights.component.html',
  styles: []
})
export class InsightsComponent implements OnInit {
  orloInsightsEnabled = false;

  constructor(
    private company: CompanyService,
    private state: StateService,
    private transition: TransitionService
  ) {}

  async ngOnInit() {
    this.orloInsightsEnabled = await this.company.hasFeatureAccess(
      'ORLO_INSIGHTS'
    );

    if (!this.orloInsightsEnabled) {
      this.redirectToPurchase();
    }
  }

  redirectToPurchase() {
    this.state.go('auth.insights.notPurchased');
    this.transition.onStart({}, (transition) => {
      if (
        (transition.to().name.startsWith('auth.insights') ||
          transition.to().name.startsWith('auth.monitoring')) &&
        transition.to().name !== 'auth.insights.notPurchased'
      ) {
        this.state.go('auth.insights.notPurchased');
      }
    });
  }
}
