import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { IconModule } from '../../../../../../common/components/icon/icon.module';
import { PaginatorModule } from '../../../../../../common/components/paginator';
import { SpinnerModule } from '../../../../../../common/components/spinner/spinner.module';

import { InsightsPostsModule } from '../insights-posts/insights-posts.module';
import { DrilldownModalComponent } from './drilldown-modal.component';
import { BigNumberModule } from '../../../../../../common/components/big-number/big-number.module';
import { Checkbox2Module } from '../../../../../../common/components/checkbox-2/checkbox-2.module';
import { DropdownSelect2Module } from '../../../../../../common/components/dropdown-select-2/dropdown-select-2.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { SentimentAmendBoxModule } from '../../../../../../common/components/sentiment-amend-box/sentiment-amend-box.module';
import { TooltipModule } from '../../../../../../common/directives/tooltip/tooltip.module';
import { ObserveWidthAndHeightModule } from '../../../../../../common/directives';

@NgModule({
  declarations: [DrilldownModalComponent],
  imports: [
    CommonModule,
    NgbModule,
    IconModule,
    FormsModule,
    InsightsPostsModule,
    TranslateModule,
    PaginatorModule,
    SpinnerModule,
    BigNumberModule,
    Checkbox2Module,
    DropdownSelect2Module,
    OverlayModule,
    SentimentAmendBoxModule,
    TooltipModule,
    ObserveWidthAndHeightModule
  ],
  exports: [DrilldownModalComponent]
})
export class DrilldownModalModule {}
